import { defineStore } from 'pinia';
import { modalSizes } from 'src/enums/modal';
import { extend, uid } from 'quasar';
import { defineAsyncComponent } from 'vue';

export const useModalStore = defineStore('modal', {
    state: () => ({
        /**
         * @type {Record<string, Modal>}
         */
        modals: {},
        closeNext: false,
    }),

    getters: {
        /**
         * @returns {Record<string, Modal>}
         */
        getModals: (state) => state.modals,
        lastModalId: (state) => Object.keys(state.modals).at(-1),
    },

    actions: {
        defaultCallbackAction() {
            // empty default action
        },

        /**
         * @param {Modal} params
         * @returns {string}
         */
        openModal(params) {
            if (this.closeNext) {
                this.closeNext = false;
                return;
            }

            /**
             * @type {Modal}
             */
            const defaultParams = {
                component: null,
                title: '',
                params: {},
                class: '',
                steps: [],
                activeStep: null,
                size: modalSizes.md,
                hasNavigationSection: false,
                persistent: false,
                closeButton: true,
                successCallback: this.defaultCallbackAction,
                closeCallback: this.defaultCallbackAction,
            };
            let id = uid();

            this.modals[id] = {
                ...defaultParams,
                ...params,
                params: {
                    ...(params.params ?? {}),
                },
            };
            return id;
        },

        openOldModal(params) {
            const newParams = {
                component: defineAsyncComponent(() => import('pages/OldHrappkaModal.vue')),
                size: modalSizes.sm,
            };

            this.openModal(extend(true, newParams, params));
        },

        changeLastModalTitle(newTitle) {
            this.modals[this.lastModalId].title = newTitle;
        },

        /**
         * @param {StepperItem[]|Record<string, StepperItem>} steps
         * @param {import('vue').MaybeRef<number|string>} [activeStep]
         */
        changeLastModalSteps(steps, activeStep) {
            this.modals[this.lastModalId].steps = steps;
            this.modals[this.lastModalId].activeStep = activeStep;
        },

        closeNextModal() {
            this.closeNext = true;
        },

        closeLastModal() {
            if (this.modals[this.lastModalId]) {
                delete this.modals[this.lastModalId];
            }
        },

        closeModal(id) {
            if (this.modals[id]) {
                delete this.modals[id];
            }
        },

        closeAllModals() {
            for (const id in this.modals) {
                this.closeModal(id);
            }
        },
    },
});
