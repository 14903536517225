import { modalSizes } from 'src/enums/modal';

export default [
    {
        path: ':contractId',
        children: [
            {
                path: 'finance',
                children: [
                    {
                        path: 'client-expenses',
                        name: 'contracts-finance-client-expenses',
                        component: () => import('pages/contracts/finance/client-expenses/ClientExpensesForm.vue'),
                        meta: {
                            openInModal: true,
                            size: modalSizes.xl,
                            title: 'Rozliczenie Klienta i partnera rekr.',
                        },
                    },
                    {
                        path: 'rates',
                        name: 'contracts-finance-rates',
                        component: () => import('pages/contracts/finance/rates/RatesForm.vue'),
                        meta: {
                            openInModal: true,
                            size: modalSizes.xl,
                            title: 'Stawki',
                        },
                    },
                ],
            },
        ],
    },
];
