const years = Object.freeze({
    FILTER_DATE_RANGE_MIN: 1990,
    FILTER_DATE_RANGE_MAX: 2100,
});

const dateFormats = Object.freeze({
    DATE_FORMAT_DATE_WITH_FULL_TIME: 'YYYY-MM-DD HH:mm:ss',
    DATE_FORMAT_DATE_WITH_BASE_TIME: 'YYYY-MM-DD HH:mm',
    DATE_FORMAT_BASE: 'YYYY-MM-DD',

    DATE_FORMAT_YEAR_WITH_MONTH: 'YYYY-MM',
    DATE_FORMAT_MONTH_WITH_DAY: 'MM-DD',
    DATE_FORMAT_YEAR: 'YYYY',
    DATE_FORMAT_MONTH: 'MM',
    DATE_FORMAT_MONTH_INT: 'M',
    DATE_FORMAT_MONTH_NAME: 'MMMM',
    DATE_FORMAT_DAY: 'DD',
    DATE_FORMAT_DAY_OF_WEEK_NUMBER: 'd',
    DATE_FORMAT_DAY_OF_MONTH_NUMBER: 'D',
    DATE_FORMAT_DAY_NAME: 'dddd',
    DATE_FORMAT_DAY_NAME_SHORT: 'ddd',

    DATE_FORMAT_BASE_TIME: 'HH:mm',
    DATE_FORMAT_FULL_TIME: 'HH:mm:ss',
});

export const dayOfWeekTranslationMap = Object.freeze({
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
});

export const defaultDayOfWeekOrder = Object.freeze([1, 2, 3, 4, 5, 6, 0]);

export { years, dateFormats };
