import { Component, h, render } from 'vue';

export function renderComponentToString(component: Component, props: Record<string, unknown>): Promise<string> {
    return new Promise((resolve) => {
        const contentRoot = document.createElement('div');

        const renderCallback = () => {
            resolve(contentRoot.innerHTML);
            contentRoot.remove();
        };

        render(h(component, { ...props, renderCallback }), contentRoot);
    });
}
