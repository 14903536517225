<template>
    <form ref="formElement" class="relative-position" @submit="onSubmit">
        <div v-if="loader && visibleLoader" class="form-loader">
            <slot name="loader">
                <loader-component />
            </slot>
        </div>

        <slot name="customLoader"></slot>

        <section class="form-fields">
            <slot name="beforeFields"></slot>

            <form-builder :id="id" v-model="fields" @update:model-value="updateModelValue"></form-builder>

            <slot name="afterFields"></slot>

            <slot name="beforeSubmitButton"></slot>

            <div v-if="submitButton" ref="submitElement" class="submitElement">
                <div ref="sentinelElement" :style="sentinelStyle"></div>
                <slot name="submitButton">
                    <div class="text-right q-mt-md">
                        <q-btn type="submit" color="brand" :disable="visibleLoader || isSubmitDisabled">
                            <hint-component v-if="submitHint">{{ $t(submitHint) }}</hint-component>

                            <template v-if="$slots.submitButtonContents">
                                <slot name="submitButtonContents" />
                            </template>
                            <template v-else>
                                <font-awesome-icon icon="far fa-save" class="fs-16" />
                                <span>{{ $t('Zapisz') }}</span>
                            </template>
                        </q-btn>
                    </div>
                </slot>
            </div>

            <slot name="afterSubmitButton"></slot>
        </section>
    </form>
</template>

<script setup>
import { setFormErrors } from '@soulab/form-builder';
import { registerChangeVisibleFunction } from '@soulab/form-builder/src/functions/register';
import LoaderComponent from 'components/Other/LoaderComponent.vue';
import { uid } from 'quasar';
import { ComponentException } from 'src/exceptions/component-exception';
import { useFormBuilder } from 'src/hooks/useFormBuilder';
import { useForm } from 'vee-validate';
import { computed, onMounted, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import HintComponent from 'components/Other/HintComponent.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    submitEvent: {
        type: Function,
        required: true,
    },
    id: {
        type: String,
        default: () => uid(),
    },
    loader: {
        type: Boolean,
        default: true,
    },
    submitButton: {
        type: Boolean,
        default: true,
    },
    scrollToError: {
        type: Boolean,
        default: true,
    },
    floatingSubmit: {
        type: Boolean,
        default: true,
    },
    isSubmitDisabled: {
        type: Boolean,
        default: false,
    },
    params: {
        type: Object,
        default: () => ({}),
    },
    submitHint: {
        type: String,
        default: '',
    },
});

const fields = ref(props.modelValue);
const visibleLoader = ref(false);
const submitFixed = ref(false);

const formElement = ref(null);
const submitElement = ref(null);
const sentinelElement = ref(null);

const { createValidationSchema } = useFormBuilder(fields.value);

const schema = ref({});

const { handleSubmit, errors, values } = useForm({ validationSchema: schema, validateOnMount: false });

schema.value = createValidationSchema(values);
provide('updateValidationSchema', () => (schema.value = createValidationSchema(values)));

/**
 * Update schema when visible field changes
 */
registerChangeVisibleFunction(() => {
    schema.value = createValidationSchema(values);
});

watch(
    () => errors.value,
    () => {
        setFormErrors(props.id, errors.value);
    }
);

const sentinelStyle = computed(() => {
    if (submitFixed.value) {
        const { height } = getSubmitButton().getBoundingClientRect();
        getSubmitButton().classList.add('floating-submit');

        return { height: `${height}px` };
    }

    if (submitElement.value instanceof HTMLElement) {
        getSubmitButton()?.classList.remove('floating-submit');
    }

    return { height: '0px' };
});

const updateModelValue = () => {
    emit('update:modelValue', fields.value);
};

const submit = async () => {
    const formBuilder = useFormBuilder(fields.value);

    if (formBuilder.findErrorInFields()) {
        if (props.scrollToError) {
            formBuilder.scrollToError();
        }

        return false;
    }

    try {
        visibleLoader.value = true;
        await props.submitEvent(formBuilder.getValuesFromFields());
    } catch (error) {
        // When rejecting promises in submit event, pass false
        // to rejection handler to ignore console error messages
        if (error !== false) {
            console.error(error);
        }
    } finally {
        visibleLoader.value = false;
    }
};

const error = () => {
    if (props.scrollToError) {
        const formBuilder = useFormBuilder(fields.value);
        formBuilder.scrollToError();
    }
};

const onSubmit = handleSubmit(submit, error);

const intersectionCallback = ([entry]) => {
    if (!formElement.value || !submitElement.value) {
        return;
    }

    const { bottom } = formElement.value.getBoundingClientRect();
    const { height } = submitElement.value.getBoundingClientRect();

    if (bottom - height > 0) {
        submitFixed.value = entry.intersectionRatio === 0;
    }
};

const getSubmitButton = () => {
    return submitElement?.value?.querySelector('button[type="submit"]');
};

const observer = new IntersectionObserver(intersectionCallback, {
    rootMargin: '0px',
    threshold: 0.0,
});

const route = useRoute();

onMounted(() => {
    if (props.submitButton && props.floatingSubmit) {
        if (!(getSubmitButton() instanceof HTMLButtonElement)) {
            throw new ComponentException(`Could not find submit button in ${props.id} form`);
        }

        observer.observe(sentinelElement.value);
    }

    if (props.params.scrollToField || route?.query?.scrollToField) {
        const formBuilder = useFormBuilder(fields.value);
        formBuilder.scrollToFormBuilderField(props.params.scrollToField || route?.query?.scrollToField);
    }
});

defineExpose({
    fields,
    errors,
    handleSubmit,
    onSubmit,
});
</script>

<style scoped lang="scss">
:global(.floating-submit) {
    position: fixed !important;
    bottom: 38px !important;
    right: 90px !important;
    filter: drop-shadow(0 6px 10px var(--gray-dark-medium)) !important;
}

.form-fields {
    color: var(--text-default);
    :deep(.q-field__control) {
        &:before {
            border-color: var(--default-border-color);
        }
    }
}

.form-loader {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 3;
}
</style>
