import { empty } from '@soulab/js-helpers';
import { requiredMessage } from 'src/rules/required';

export function dynamicFormRule(value, validator, context) {
    const dynamicForm = validator.field;

    if (empty(dynamicForm, false)) {
        return true;
    }

    /**
     * If there are no children in dynamic form set error on parent field
     */
    if (!dynamicForm.children || !Object.keys(dynamicForm.children).length) {
        return context.rule.params?.message || requiredMessage(context.rule.params?.type);
    }

    return true;
}
