<template>
    <q-item-section class="company-select-with-logo">
        <img v-if="props.option.logoMinPath" :src="props.option.logoMinPath" alt="logo" />
        <font-awesome-icon v-if="props.option.iconName" :icon="props.option.iconName" size="lg" />
        <q-item-label>{{ props.option.label }}</q-item-label>
    </q-item-section>
</template>

<script setup lang="ts">
type CompanySelectionDetails = {
    label: string;
    logoMinPath: string;
    iconName: string;
};
const props = defineProps({
    option: {
        type: Object as CompanySelectionDetails,
        default: () => {},
    },
});
</script>

<style lang="scss" scoped>
.company-select-with-logo {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    img,
    svg {
        max-height: 25px;
        width: 25px;
        margin-right: 15px;
    }
}
</style>
