<template>
    <div id="searchbar-search-results">
        <template v-if="entitiesData.length > 0 || knowledgeBaseData.length > 0">
            <template v-if="entitiesData.length > 0">
                <div class="search-header">
                    <div class="search-header-background"></div>
                    {{ $t('Obiekty w systemie') }}
                </div>
                <search-result-component :data="entitiesData" @close-search-result="hideSearchResult" />
            </template>

            <template v-if="knowledgeBaseData.length > 0">
                <div class="search-header">
                    <div class="search-header-background"></div>
                    {{ $t('Baza wiedzy') }}
                </div>
                <div v-for="(element, key) in knowledgeBaseData" :key="key" class="search-results-row">
                    <div class="result-wrapper flex items-center space-between">
                        <div class="result-knowledge-base-data flex items-center">
                            <div class="knowledge-base-data">
                                <span class="head-line">
                                    <a class="link" :href="element.url" target="_blank" @click="hideSearchResult">
                                        {{ element.title }}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>

        <template v-else-if="historyData.length > 0">
            <div class="search-header">
                <div class="search-header-background"></div>
                {{ $t('Ostatnio oglądane') }}
            </div>
            <search-result-component :data="historyData" @close-search-result="hideSearchResult" />
        </template>
    </div>
</template>

<script setup>
import SearchResultComponent from 'components/Navigation/SearchResultComponent.vue';

const emit = defineEmits(['closeSearchResult']);

const props = defineProps({
    entitiesData: {
        type: Array,
        default: () => [],
    },
    knowledgeBaseData: {
        type: Array,
        default: () => [],
    },
    historyData: {
        type: Array,
        default: () => [],
    },
});

const hideSearchResult = () => {
    emit('closeSearchResult');
};
</script>

<style lang="scss" scoped>
#searchbar-search-results {
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);

    .search-header {
        position: relative;
        font-size: 0.875rem;
        text-transform: uppercase;
        padding: 10px 20px;
        box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);

        .search-header-background {
            position: absolute;
            inset: 0;
            background: var(--brand-color);
            opacity: 0.1;
        }
    }

    .search-results-row {
        padding: 20px 30px;
    }
}
</style>
