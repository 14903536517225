import { empty } from '@soulab/js-helpers';

export function peselRule(value, validator, context) {
    if (empty(value, false)) {
        return true;
    }

    let valid = false;

    if (value.length === 11) {
        const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        const digits = Array.from(value);

        const controlNumber = parseInt(digits[value.length - 1]);
        let sum = 0;

        for (const i in weight) {
            const multiply = i <= weight.length ? weight[i] : weight[i - 1];
            sum += parseInt(value.charAt(i)) * multiply;
        }

        if ((10 - (sum % 10) === 10 ? 0 : 10) - (sum % 10) === controlNumber) {
            valid = true;
        }
    }

    if (!valid) {
        return context.rule.params?.message || 'Wprowadzono nieprawidłowy numer PESEL';
    }

    return true;
}
