<template>
    <div class="navigation-short">
        <q-expansion-item
            v-for="(section, key) in elements"
            :key="key"
            v-model="section.open"
            :to="section.path"
            :href="section.url"
            :target="section.url ? '_blank' : ''"
            active-class="text-brand"
            expand-icon-class="font-base-color"
            header-style="width: 59px; display: flex; align-items: center; padding-left: 15px; padding-right: 29px;"
            :header-class="{
                'active-navigation-section': section.isActive,
                'navigation-header-class': true,
                'q-manual-focusable--focused': section.open,
                'text-brand': section.open,
            }"
            @show="
                () => {
                    showNavElement(section);
                }
            "
        >
            <template #header>
                <div class="navigation-item-row full-width navigation-item-main">
                    <div v-if="section.icon" class="navigation-item-element">
                        <div style="width: 25px">
                            <font-awesome-icon :icon="section.icon" />
                        </div>
                    </div>
                    <div v-else class="navigation-item-element">
                        <div style="width: 25px">
                            <font-awesome-icon icon="fa fa-user" />
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="section.children" #default>
                <q-expansion-item
                    v-for="(child, childKey) in section.children"
                    :key="childKey"
                    :to="child.path"
                    :target="section.url ? '_blank' : ''"
                    :href="section.url"
                    :class="'short-menu-element-' + childKey"
                    expand-icon="none"
                    active-class="text-brand"
                    :header-class="{
                        'navigation-header-class': true,
                        'text-brand': child.isActive || isActiveShortItem(key + '_' + childKey),
                        'q-manual-focusable--focused': isActiveShortItem(key + '_' + childKey),
                    }"
                    header-style="width: 60px; display: flex; align-items: center; padding: 0;"
                >
                    <template #header>
                        <navigation-menu-short-item-component :id="key + '_' + childKey" :child="child" :parent="child.label" />
                    </template>
                </q-expansion-item>
            </template>
        </q-expansion-item>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import NavigationMenuShortItemComponent from 'components/Navigation/NavigationMenuShortItemComponent.vue';
import { useAuthStore } from 'stores/auth';

const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
    level: {
        type: Number,
        default: 0,
    },
});

const elements = ref(props.items);
const visible = ref(false);

const isActiveShortItem = (index) => {
    return useAuthStore().getOpenShortMenuItem === index;
};

const showNavElement = (section) => {
    const activeGroup = section.group || '';

    if (activeGroup !== '') {
        for (let i in elements.value) {
            if (elements.value[i].group && elements.value[i].group !== activeGroup) {
                elements.value[i].open = false;
            }
        }
    }
};

const levelMargin = computed(() => {
    return props.level * 15 + 'px';
});

const levelMarginAddElement = computed(() => {
    return (props.level + 1) * 15 + 'px';
});
</script>

<style scoped>
.item-short-menu {
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
}
</style>
