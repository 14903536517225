import MainLayout from 'layouts/MainLayout.vue';
import { modalSizes } from 'src/enums/modal';
import { permissionsMiddleware } from 'src/router/middlewares/permissions-middleware';
import { permissionAttribute } from 'src/enums/permissions';
import { entityNew } from 'src/enums/entity';
import { roleMiddleware } from 'src/router/middlewares/role-middleware';
import { role } from 'src/enums/role';

/**
 * When adding new settings routes remember to use standard components for visual cohesion between views!
 *
 * Generic settings components are located in components/Settings/*.vue
 */

export default [
    {
        path: 'positions',
        name: 'settings-positions',
        component: () => import('pages/settings/positions/SettingsPositionsPage.vue'),
        meta: {
            layout: MainLayout,
            title: 'Stanowiska',
            middleware: [permissionsMiddleware(permissionAttribute.VIEW, entityNew.POSITION)],
        },
    },
    {
        path: 'rate-names',
        name: 'settings-rate-names',
        component: () => import('pages/settings/rate-names/SettingsRateNamesPage.vue'),
        meta: {
            layout: MainLayout,
        },
    },
    {
        path: 'company-price-groups',
        children: [
            {
                path: 'list',
                name: 'settings-company-price-groups-list',
                component: () => import('pages/settings/company-price-groups/CompanyPriceGroupsList.vue'),
                meta: {
                    layout: MainLayout,
                    title: 'Koszty Klienta',
                },
            },
            {
                path: ':companyPriceGroupId?',
                name: 'settings-company-price-groups-form',
                component: () => import('pages/settings/company-price-groups/CompanyPriceGroupsForm.vue'),
                meta: {
                    parent: 'settings-company-price-groups-list',
                    openInModal: true,
                    size: modalSizes.xl,
                },
            },
        ],
    },
    {
        path: 'recruitment-partner-price-groups',
        children: [
            {
                path: 'list',
                name: 'settings-recruitment-partner-price-groups-list',
                component: () => import('pages/settings/recruitment-partner-price-groups/RecruitmentPartnerPriceGroupsList.vue'),
                meta: {
                    layout: MainLayout,
                    title: 'Wynagrodzenie Partnera rekr.',
                },
            },
            {
                path: ':recruitmentPartnerPriceGroupId?',
                name: 'settings-recruitment-partner-price-groups-form',
                component: () => import('pages/settings/recruitment-partner-price-groups/RecruitmentPartnerPriceGroupsForm.vue'),
                meta: {
                    parent: 'settings-recruitment-partner-price-groups-list',
                    openInModal: true,
                    size: modalSizes.xl,
                },
            },
        ],
    },
    {
        path: 'employees/:setting?',
        name: 'settings-employees',
        component: () => import('pages/settings/employees/SettingsEmployeesPage.vue'),
        meta: {
            layout: MainLayout,
        },
    },
    {
        path: 'trainings',
        name: 'settings-trainings',
        component: () => import('pages/settings/trainings/SettingsTrainingsPage.vue'),
        meta: {
            layout: MainLayout,
            title: 'Szkolenia',
        },
    },
    {
        path: 'printout-patterns',
        name: 'settings-printout-patterns',
        component: () => import('pages/settings/printout-patterns/SettingsPrintoutPatternsPage.vue'),
        meta: {
            layout: MainLayout,
            title: 'Wydruki',
        },
    },
    {
        path: 'automation',
        name: 'automation-list',
        component: () => import('pages/automation/AutomationPage.vue'),
        meta: {
            layout: MainLayout,
            middleware: [roleMiddleware(role.ADMIN)],
        },
    },
    {
        path: 'import',
        name: 'settings-import',
        component: () => import('../../pages/settings/import/ImportPage.vue'),
        meta: {
            layout: MainLayout,
        },
    },
];
