import { empty, isString } from '@soulab/js-helpers';
import rulesMap from 'src/rules/map';

export function customRule(value, validator, context) {
    const rulesToApply = validator.rulesToApply?.(value);
    const rules = validator.rules;

    if (empty(rulesToApply)) {
        return true;
    }

    for (const ruleName of rulesToApply) {
        const rule = rulesMap[ruleName];

        if (empty(rule)) {
            throw new Error(`Rule not found: ${rule}`);
        }

        const result = rule(value, rules[ruleName] ?? {}, context);

        if (isString(result)) {
            return context.rule.params?.message || result;
        }
    }

    return true;
}
