import { empty } from '@soulab/js-helpers';

export function numberRule(value, validator, context) {
    const zero = validator.zero;
    const positive = validator.positive;
    const integer = validator.integer;
    const isNumberWithWhiteSpaceAllowed = validator.isNumberWithWhiteSpaceAllowed;

    if (empty(value, false)) {
        return true;
    }

    if (isNumberWithWhiteSpaceAllowed) {
        value = value.toString().replace(/\s/g, '');
    }

    if (!/^-?[\d.,]+$/.test(value) || /[.,]$/.test(value) || value.toString().match(/[.,]/g)?.length > 1) {
        return context.rule.params?.message || 'Podana wartość musi być wartością liczbową';
    }

    if (zero && value === 0) {
        return context.rule.params?.zeroMessage || 'Podana wartość musi być różna od zera';
    }

    const valueAsInt = parseInt(value, 10);
    const isInt =
        !isNaN(valueAsInt) &&
        Number.isInteger(valueAsInt) &&
        value.toString() &&
        !value.toString().includes(',') &&
        !value.toString().includes('.');

    if (integer && !isInt) {
        return context.rule.params?.integerMessage || 'Podana wartość musi być liczą całkowitą';
    }

    if (positive && !/^[\d.,]+$/.test(value)) {
        return context.rule.params?.positiveMessage || 'Podana wartość nie może być wartością ujemną';
    }

    return true;
}
