<template>
    <q-header
        v-if="!hideHeader"
        class="shadow-0 bg-transparent"
        :class="{ expanded: isHeaderReduced && !isSearchVisible, search: isSearchVisible }"
    >
        <!-- For old views setting height to auto and letting header animate its height works better -->
        <div :style="{ height: route.meta?.isOldView ? 'auto' : `${EXPANDED_HEADER_HEIGHT}px` }">
            <div class="mobile-header relative-position row no-wrap">
                <div v-sticky-context.top class="absolute sticky-context-placeholder"></div>
                <div class="bg-white absolute-full" style="z-index: -1"></div>
                <mobile-navigation-header-background :is-reduced="!isHeaderReduced" />

                <div class="side-placeholder">
                    <div class="mobile-header-menu">
                        <q-btn flat dense round icon="menu" aria-label="Menu" @click="$emit('toggleDrawer')" />
                    </div>
                </div>

                <div class="mobile-header-avatar-wrapper">
                    <q-avatar class="mobile-header-avatar cursor-pointer" @click="$emit('toggleDrawer')">
                        <img v-if="userImage" :src="userImage" />
                        <span v-else>{{ userInitials }}</span>
                    </q-avatar>
                </div>

                <!--        USER DETAILS-->
                <div class="mobile-header--user-details col">
                    <div class="mobile-header--user-details--username">
                        <div class="inline-block">
                            {{ userName }}
                        </div>
                    </div>

                    <div class="mobile-header--user-details--company-wrapper">
                        <div class="mobile-header--user-details--company">
                            <span>{{ companyName }}</span>
                        </div>
                    </div>

                    <!--        SEARCH -->
                    <div class="mobile-header--search flex justify-center items-center">
                        <div class="mobile-header--search-wrapper">
                            <search-navigation-component ref="searchComponent" :is-mobile="true" @search-close="isSearchVisible = false" />
                        </div>
                    </div>
                </div>

                <!--        ICONS-->
                <div class="side-placeholder">
                    <div class="mobile-header--icons row no-wrap justify-end items-center">
                        <div class="mobile-header--icons--search">
                            <div class="mobile-header--icons--search--icon">
                                <font-awesome-icon icon="far fa-search" @click="showSearch" />
                            </div>
                        </div>
                        <div class="mobile-header--icons--notys">
                            <router-link to="/notifications" class="white-link">
                                <div class="mobile-header--icons--notys--icon column justify-center items-center content-center">
                                    <q-badge v-if="feedCount && feedCount !== 0" rounded class="mobile-header--icons--notys--icon--badge"
                                        >{{ feedCount }}
                                    </q-badge>
                                    <font-awesome-icon icon="far fa-bell" />
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </q-header>
</template>

<script setup lang="ts">
import MobileNavigationHeaderBackground from 'components/Navigation/Mobile/MobileNavigationHeaderBackground.vue';
import SearchNavigationComponent from 'components/Navigation/SearchNavigationComponent.vue';
import { computed, ref } from 'vue';
import { useViewStore } from 'stores/view';
import { useAuthStore } from 'stores/auth';
import { getNameInitials } from 'src/helpers/general';
import { useRoute } from 'vue-router';
import { useStickyContext } from '@soulab/vue-data-table';
import { EXPANDED_HEADER_HEIGHT } from 'src/helpers/ui';

const props = defineProps({
    hideHeader: {
        type: Boolean,
        default: false,
    },
});

const route = useRoute();
const viewStore = useViewStore();
const authStore = useAuthStore();

const emit = defineEmits({
    toggleDrawer: null,
    successCallback: null,
});

const vStickyContext = useStickyContext().stickyContextDirective;

//SEARCH
const isSearchVisible = ref<boolean>(false);
const searchComponent = ref(null);

const showSearch = () => {
    isSearchVisible.value = true;
    searchComponent.value?.focusInput();
};

// SIZE OF HEADER
const isHeaderReduced = computed((): boolean => {
    return isSearchVisible.value ? false : viewStore.isScrolledToTop;
});

// NOTYS BELL
const feedCount = computed((): string | number => (authStore.getFeedCount < 99 ? authStore.getFeedCount : 99 + '+'));

// USER AVATAR AND DETAILS
const userName = computed((): string => authStore.getCurrentUser.name);
const userImage = computed((): string => authStore.getCurrentUser.image);
const companyName = computed((): string => authStore.getCurrentCompany.name);
const logoMinPath = computed((): string => authStore.getBrandingData.logoMinPath);
const userInitials = computed((): string => {
    return getNameInitials(userName.value);
});
</script>

<style scoped lang="scss">
@use 'sass:string';

@function transition-for($properties...) {
    $transition: '';

    @each $property in $properties {
        $transition: $transition + ' ' + $property + ' var(--header-transition-duration) ease,';
    }

    @return string.unquote($transition);
}

.mobile-header {
    --header-transition-duration: 0.5s;
    --side-size: 32px;
    --avatar-size: 32px;
    --avatar-expanded-size: 64px;
    --header-gap: 16px;
    --header-padding: 16px;
    --username-font-size: 14px;
    --company-font-size: 16px;
    padding: var(--header-padding);
    gap: var(--header-gap);
}

.side-placeholder {
    width: var(--side-size);
    height: var(--side-size);
    position: relative;
}

.mobile-header-avatar-wrapper {
    position: absolute;
    left: var(--header-padding);
    right: var(--header-padding);
    transform: translateX(0);
    transition: transition-for(transform);
}

.mobile-header-avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
    font-size: 32px;
    background-color: var(--brand-color);
    transform: translateX(0) scale(1);
    transform-origin: 50% 0;
    transition: transition-for(left, transform, height, width, font-size);
}

.mobile-header-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(40%) translate(var(--side-size), var(--side-size));
    background: white;
    color: var(--brand-color);
    border-radius: 50%;
    z-index: 1;
    transition: transition-for(top, left, transform, background, color);

    & .q-btn {
        width: 100%;
        height: 100%;
    }
}

.mobile-header {
    height: 64px;
    transition: height var(--header-transition-duration) ease;

    & .sticky-context-placeholder {
        height: 64px;
        width: 100%;
    }

    &--user-details {
        position: relative;

        &--username {
            --base-padding: calc((var(--avatar-size) - var(--username-font-size) * 1.5) / 2);
            position: relative;
            transform: translateX(0) translateY(var(--base-padding));
            transform-origin: 0 0;
            font-size: var(--username-font-size);
            font-family: OpenSans-Regular, serif;
            transition: transition-for(transform, opacity);

            & > div {
                transform: translateX(0);
                transition: transition-for(transform);
            }
        }

        &--company {
            &-wrapper {
                position: absolute;
                left: var(--header-padding);
                right: var(--header-padding);
                height: 100%;
                transform: translateX(50%) translateY(50%);
            }

            transition: transition-for(opacity);
            font-size: var(--company-font-size);
            width: max-content;
            max-width: 100%;
            transform: translateX(-50%) translateY(var(--company-font-size));
            opacity: 0;
            font-family: OpenSans-Light, serif;
            text-wrap: nowrap;
            position: relative;

            & > span {
                position: absolute;
                left: 0;
                transform: translateX(-50%);
            }
        }
    }

    &--search {
        position: absolute;
        left: 0;
        top: -4px;
        height: 40px;
        width: calc(100% + var(--header-gap) + var(--side-size));

        &-wrapper {
            width: 0;
            height: 100%;
            transition: transition-for(width, opacity);
            position: relative;
            opacity: 0;
            z-index: 1;
        }
    }

    &--icons {
        display: flex;
        align-items: center;
        position: absolute;
        height: 100%;
        right: 0;
        transition: transition-for(opacity);

        & > div {
            font-size: 24px;
            flex-shrink: 0;

            &:first-child {
                transform: translateX(-12px);
                transition: transition-for(transform);
            }
        }

        & svg {
            transform-origin: 100% 0;
            transition: transition-for(transform);
        }

        &--notys--icon--badge {
            background-color: #f46a6a;
            text-align: center;
            margin-top: -15px;
        }
    }
}

.expanded {
    .mobile-header {
        height: v-bind('`${EXPANDED_HEADER_HEIGHT}px`');
    }

    .mobile-header--user-details--username {
        transform: translateX(50%) translateY(calc(var(--avatar-expanded-size) + var(--base-padding))) scale(1.25);

        & > div {
            transform: translateX(-50%);
        }
    }

    .mobile-header-avatar-wrapper {
        transform: translateX(50%);
    }

    .mobile-header-avatar {
        transform: translateX(-50%) scale(2);
    }

    .mobile-header--user-details--company {
        opacity: 1;
    }

    .mobile-header-menu {
        transform: scale(140%);
        background: transparent;
        color: white;
    }

    .mobile-header--icons {
        & > div:first-child {
            transform: translateX(-16px);
        }

        & svg {
            transform: scale(calc(28 / 24));
        }
    }
}

.search {
    .mobile-header--search-wrapper {
        width: 100%;
        opacity: 1;
    }

    .mobile-header--user-details--username {
        opacity: 0;
    }

    .mobile-header--icons {
        opacity: 0;
    }
}
</style>
