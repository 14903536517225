import MainLayout from 'layouts/MainLayout.vue';
import { modalSizes } from 'src/enums/modal';

export default [
    {
        path: ':contractorId',
        children: [
            {
                path: 'projects',
                children: [
                    {
                        path: 'list',
                        name: 'contractors-projects-list',
                        component: () => import('pages/contractors/projects/ContractorsProjectsList.vue'),
                        meta: {
                            layout: MainLayout,
                            title: 'Projekty',
                        },
                    },
                ],
            },
            {
                path: 'settings',
                children: [
                    {
                        path: 'client-costs',
                        name: 'contractors-settings-client-costs',
                        component: () => import('pages/contractors/settings/client-costs/ClientCostsForm.vue'),
                        meta: {
                            openInModal: true,
                            size: modalSizes.xl,
                            title: 'Rozliczenie Klienta i partnera rekr.',
                        },
                    },
                ],
            },
        ],
    },
    {
        path: 'work-demand/:id',
        name: 'work-demand-contractor',
        component: () => import('pages/contractors/WorkDemandPage.vue'),
        meta: {
            layout: MainLayout,
        },
    },
];
