import { empty } from '@soulab/js-helpers';

export function idNumberRule(value, validator, context) {
    if (empty(value, false)) {
        return true;
    }

    let valid = false;

    if (value.length === 9) {
        const values = {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            A: 10,
            B: 11,
            C: 12,
            D: 13,
            E: 14,
            F: 15,
            G: 16,
            H: 17,
            I: 18,
            J: 19,
            K: 20,
            L: 21,
            M: 22,
            N: 23,
            O: 24,
            P: 25,
            Q: 26,
            R: 27,
            S: 28,
            T: 29,
            U: 30,
            V: 31,
            W: 32,
            X: 33,
            Y: 34,
            Z: 35,
        };

        const weight = [7, 3, 1, 0, 7, 3, 1, 7, 3];
        const digits = Array.from(value);

        const controlNumber = parseInt(digits[3]);
        let sum = 0;

        for (const i in weight) {
            sum += values[digits[i]] * weight[i];
        }

        if (sum % 10 === controlNumber) {
            valid = true;
        }
    }

    if (!valid) {
        return context.rule.params?.message || 'Wprowadzono nieprawidłową serię i numer dowodu';
    }

    return true;
}
