import { empty } from '@soulab/js-helpers';

export function regonRule(value, validator, context) {
    if (empty(value, false)) {
        return true;
    }

    let valid = false;
    let length = value.length;

    if (length === 7 || length === 9 || length === 14) {
        if (length === 14 && value.substring(9, 5) === '00000') {
            length = 9;
        }

        const weightsForLength = {
            7: [2, 3, 4, 5, 6, 7],
            9: [8, 9, 2, 3, 4, 5, 6, 7],
            14: [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8],
        };

        const weight = weightsForLength[length];
        const digits = Array.from(value);

        const controlNumber = parseInt(digits[length - 1]);
        let sum = 0;

        for (const i in weight) {
            const multiply = i <= weight.length ? weight[i] : weight[i - 1];
            sum += parseInt(value.charAt(i)) * multiply;
        }

        if ((sum % 11 === 10 ? 0 : sum % 11) === controlNumber) {
            valid = true;
        }
    }

    if (!valid) {
        return context.rule.params?.message || 'Wprowadzono nieprawidłowy numer REGON';
    }

    return true;
}
