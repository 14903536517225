import { computed, inject, reactive, watch, ref } from 'vue';
import { useField as useFieldVeeValidate, useIsFormTouched } from 'vee-validate';
import { parseAttributes } from '@soulab/form-builder';
import { useFieldAdditional } from 'components/FormBuilder/hooks/useFieldAdditional';

export function useField(path, field, options = {}) {
    /**
     * This is required because sometimes we only have field object, and can't easily extract path from it
     * e.g. decorator pattern with field.callbacks.decorate
     */
    field.__path__ = path;

    const $t = inject('trans');
    const updateValidationSchema = inject('updateValidationSchema');
    const fieldRules = reactive(field.rules ?? {});

    /**
     * VeeValidates lags browser when there are any numeric values in path
     * Field names should never be numbers, except FieldDynamicForm indexed names
     */
    const {
        value: fieldValue,
        setValue: setFieldValue,
        setErrors: setFieldErrors,
        errorMessage: fieldErrorMessage,
        resetField: resetField,
        validate: validateField,
        meta: fieldMeta,
        setTouched,
    } = useFieldVeeValidate(
        path,
        field.standalone ? computed(() => field.rules) : null,
        { keepValueOnUnmount: true } // We don't want vee-validate to remove values of invisible dynamic form rows.
    );

    if (typeof field.value !== 'undefined' && field.value !== null && field.value !== '') {
        setFieldValue(field.value);
    } else {
        setFieldValue(undefined);
    }

    const isFormTouched = useIsFormTouched();

    if (isFormTouched.value) {
        // Required when scrolling to a virtual scroll descendant with an error.
        setTouched(true);
    }

    const nativeElement = ref(null);

    const fieldBackendError = computed(() => {
        return field.backendError ? field.backendError : '';
    });

    const fieldError = computed(() => {
        if (!fieldMeta.touched) {
            return null;
        }

        const error = fieldErrorMessage.value && fieldErrorMessage.value.length > 0;
        const backendError = fieldBackendError.value && fieldBackendError.value.length > 0;

        if (!error && !backendError) {
            return null;
        }

        return error || backendError;
    });

    const fieldLabel = computed(() => {
        if (!field.label) {
            return '';
        }

        /**
         * We can pass false star parameter to required rule to always hide star next to the field label
         */
        if (field?.rules?.required && (field.rules.required.star ?? true)) {
            return `${$t(field.label)} *`;
        }

        return $t(field.label);
    });

    const fieldPlaceholder = computed(() => {
        return field.placeholder ? $t(field.placeholder) : '';
    });

    const fieldAttributes = computed(() => {
        return field.attributes ? parseAttributes(field, path) : {};
    });

    watch(
        () => field.backendError,
        (value) => (fieldBackendError.value = value)
    );

    watch(
        () => field.value,
        () => (field.backendError = ''),
        { deep: true }
    );

    watch(
        () => field.rules,
        () => {
            updateValidationSchema?.();
        },
        { deep: true }
    );

    watch(
        () => field?.additional?.disabled,
        () => {
            validateField();
        }
    );

    const nativeElementWatchStop = watch(nativeElement, () => {
        if (options.nativeElementSetter) {
            options.nativeElementSetter(nativeElement, field);
        } else {
            field.__nativeElement__ = nativeElement.value;
        }
        nativeElementWatchStop();
    });

    return {
        fieldValue,
        fieldError,
        fieldErrorMessage,
        fieldBackendError,
        fieldLabel,
        fieldPlaceholder,
        fieldAttributes,
        resetField,
        validateField,
        nativeElement,
        ...useFieldAdditional(path, field),
    };
}
