<template>
    <template v-if="props.data.length > 0">
        <div v-for="(element, key) in props.data" :key="key" class="search-results-row entity-element">
            <div class="result-wrapper flex items-center">
                <div class="result-personal-data flex items-center" style="width: 350px">
                    <font-awesome-icon class="q-pr-xs" :icon="getIcon(element.entity)" style="width: 50px" />

                    <div class="personal-data">
                        <span class="head-line entity-link">
                            <router-link class="link" :to="element.url" @click="hideSearchResult">
                                {{ element.name }}
                            </router-link>
                        </span>
                        <span v-if="element.state" class="line" v-html="element.state"></span>
                    </div>
                </div>
                <div v-if="element.first_column" class="result-additional">
                    <span class="line status-line" v-html="element.first_column"></span>
                </div>
                <div v-if="element.second_column" class="result-additional">
                    <span class="line status-line" v-html="element.second_column"></span>
                </div>
                <div v-if="element.accountants" class="result-accountant">
                    <span class="line status-line" v-html="element.accountants"></span>
                </div>
                <div class="result-additional"></div>
            </div>
        </div>
    </template>
</template>

<script setup>
import { getEntityIcon } from 'src/enums/entity';

const emit = defineEmits(['closeSearchResult']);

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const getIcon = (entity) => {
    return getEntityIcon(entity);
};

const hideSearchResult = () => {
    emit('closeSearchResult');
};
</script>

<style scoped>
.entity-element:not(:last-child) {
    border-bottom: 1px solid #d3d3d3;
}

.search-results-row {
    padding: 20px 30px;
}

.result-personal-data i {
    font-size: 1.25rem;
}

.personal-data,
.result-info-data,
.settings-data {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.head-line {
    font-weight: 700;
    font-size: 0.875rem;
}

.line {
    padding: 3px 0;
    font-size: 0.75rem;
}

.thin-line {
    font-size: 0.625rem;
    margin-top: 4px;
}

.result-additional {
    width: 250px;
}

@media (max-width: 1160px) {
    .result-additional {
        display: none;
    }

    .line {
        font-size: 0.75rem;
    }
}

@media (max-width: 900px) {
    .result-personal-data i {
        font-size: 1rem;
    }

    .head-line {
        font-size: 0.75rem;
    }
}

@media (max-width: 700px) {
    .result-accountant {
        display: none;
    }
}

@media (max-width: 500px) {
    .result-info-data {
        display: none;
    }
}
</style>
