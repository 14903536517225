const enums = Object.freeze({
    FIELD_MODE_BASE: 'field-mode-base',
    FIELD_MODE_PLAINTEXT: 'field-mode-plaintext',
    FIELD_MODE_PLAINTEXT_HTML: 'field-mode-plaintext-html',

    FIELD_TYPE_SECTION: 'field-section',
    FIELD_TYPE_BOX_INFO: 'field-box-info',
    FIELD_TYPE_ERROR_HANDLER: 'field-error-handler',
    FIELD_TYPE_ACCORDION: 'field-accordion',
    FIELD_TYPE_DYNAMIC_FORM: 'field-dynamic-form',
    FIELD_TYPE_TABS: 'field-tabs',
    FIELD_TYPE_TEXT: 'field-text',
    FIELD_TYPE_TEXTAREA: 'field-textarea',
    FIELD_TYPE_NUMBER: 'field-number',
    FIELD_TYPE_AMOUNT: 'field-amount',
    FIELD_TYPE_PASSWORD: 'field-password',
    FIELD_TYPE_EMAIL: 'field-email',
    FIELD_TYPE_CHECKBOX: 'field-checkbox',
    FIELD_TYPE_CONSENT: 'field-consent',
    FIELD_TYPE_SELECT: 'field-select',
    FIELD_TYPE_TOGGLE: 'field-toggle',
    FIELD_TYPE_RADIO: 'field-radio',
    FIELD_TYPE_FILE: 'field-file',
    FIELD_TYPE_SPLIT: 'field-split',
    FIELD_TYPE_DATE: 'field-date',
    FIELD_TYPE_DATE_MULTIPLE: 'field-date-multiple',
    FIELD_TYPE_DATE_PICKER: 'field-date-picker',
    FIELD_TYPE_CALENDAR: 'field-calendar',
    FIELD_TYPE_DATE_RANGE: 'field-date-range',
    FIELD_TYPE_TIME: 'field-time',
    FIELD_TYPE_TIME_MULTIPLE: 'field-time-multiple',
    FIELD_TYPE_TIME_RANGE: 'field-time-range',
    FIELD_TYPE_CUSTOM_COMPONENT: 'field-custom-component',
    FIELD_TYPE_DAY_OF_WEEK: 'field-day-of-week',
    FIELD_TYPE_FILTER_BUILDER: 'field-filter-builder',

    FIELD_TYPE_REGISTER_PACKAGE_SELECT: 'field-register-package-select',
    FIELD_TYPE_ATTRIBUTES: 'field-attributes',
    FIELD_TYPE_GOOGLE_PLACES: 'field-google-places',
    FIELD_TYPE_TINYMCE: 'field-tiny-mce',
    FIELD_TYPE_COLOR_PICKER: 'field-color-picker',
    FIELD_TYPE_FILL_SUGGESTION: 'field-type-fill-suggestion',

    FIELD_TYPE_SELECT_AJAX_EMPLOYEE: 'field-select-ajax-employee',
    FIELD_TYPE_SELECT_AJAX_EMPLOYEE_AND_USER_GROUP_AND_DEPARTMENT: 'field-select-ajax-employee-and-user-group-and-department',
    FIELD_TYPE_SELECT_AJAX_CONTRACTORS: 'field-select-ajax-contractors',
    FIELD_TYPE_SELECT_AJAX_CONTRACTOR_AND_DEPARTMENT: 'field-select-ajax-contractor-and-department',
    FIELD_TYPE_SELECT_AJAX_PROFESSION: 'field-select-ajax-profession',
    FIELD_TYPE_SELECT_AJAX_TEMPLATE: 'field-select-ajax-template',
    FIELD_TYPE_SELECT_AJAX_POSITION: 'field-select-ajax-position',
    FIELD_TYPE_SELECT_AJAX_TASK: 'field-select-ajax-task',
    FIELD_TYPE_SELECT_AJAX_PROJECT: 'field-select-ajax-project',
    FIELD_TYPE_SELECT_AJAX_APARTMENT: 'field-select-ajax-apartment',
    FIELD_TYPE_SELECT_AJAX_APARTMENT_ROOM: 'field-select-ajax-apartment-room',
    FIELD_TYPE_SELECT_AJAX_CAR: 'field-select-ajax-car',
    FIELD_TYPE_SELECT_AJAX_CONTRACTORS_AND_DEPARTMENTS_AND_COMPANY_USER_GROUPS:
        'field-select-ajax-contractors-and-departments-company-user-groups',
    FIELD_TYPE_SELECT_AJAX_PRINTOUT_PATTERN: 'field-select-ajax-printout-pattern',
    FIELD_TYPE_SELECT_AJAX_PARTICIPANTS: 'field-select-ajax-participants',
    FIELD_TYPE_SELECT_AJAX_QUESTIONNAIRES: 'field-select-ajax-questionnaires',
    FIELD_TYPE_SELECT_AJAX_EMAIL: 'field-select-ajax-email',
    FIELD_TYPE_SELECT_AJAX_RATE_DEFINITION: 'field-select-ajax-rate-definition',
    FIELD_TYPE_SELECT_AJAX_RATE_NAME: 'field-select-ajax-company-rate-definition',
    FIELD_TYPE_SELECT_AJAX_ACCOUNTANT: 'field-select-ajax-accountant',
    FIELD_TYPE_SELECT_AJAX_COMPANY: 'field-select-ajax-company',
    FIELD_TYPE_SELECT_AJAX_COMPANY_PRICE_GROUP: 'field-select-ajax-company-price-group',
    FIELD_TYPE_SELECT_AJAX_RECRUITMENT_PARTNER_PRICE_GROUP: 'field-select-ajax-recruitment-partner-price-group',
    FIELD_TYPE_SELECT_AJAX_USER_CONTRACT: 'field-select-ajax-user-contract',
    FIELD_TYPE_SELECT_AJAX_USER_SEIZURE: 'field-select-ajax-user-seizure',
    FIELD_TYPE_SELECT_AJAX_ROLE_AND_FAKE_ROLE_AND_USER_AND_COMPANY_USER_GROUP:
        'field-select-ajax-role-and-fake-role-and-user-and-company-user-group',
    FIELD_TYPE_SELECT_AJAX_ITEM: 'field-select-ajax-item',
    FIELD_TYPE_SELECT_AJAX_ALL_RATE_TYPES: 'field-select-ajax-all-rate-types',
    FIELD_TYPE_SELECT_AJAX_TAG: 'field-select-ajax-tag',
    FIELD_TYPE_SELECT_AJAX_TAX_OFFICE: 'field-select-ajax-tax-office',
    FIELD_TYPE_SELECT_AJAX_WORK_CALENDAR: 'field-select-ajax-work-calendar',

    FIELD_ATTRIBUTE_TYPE_TEXT: 'field-attribute-text',
    FIELD_ATTRIBUTE_TYPE_TEXTAREA: 'field-attribute-textarea',
    FIELD_ATTRIBUTE_TYPE_NUMBER: 'field-attribute-number',
    FIELD_ATTRIBUTE_TYPE_DATE: 'field-attribute-date',
    FIELD_ATTRIBUTE_TYPE_SELECT: 'field-attribute-select',
    FIELD_ATTRIBUTE_TYPE_FILE: 'field-attribute-file',
    FIELD_ATTRIBUTE_TYPE_TABLE: 'field-attribute-table',
    FIELD_ATTRIBUTE_TYPE_SECTION: 'field-attribute-section',
    FIELD_ATTRIBUTE_TYPE_TREE: 'field-attribute-tree',
    FIELD_ATTRIBUTE_TYPE_RADIO: 'field-attribute-radio',
    FIELD_ATTRIBUTE_TYPE_CHECKBOX: 'field-attribute-checkbox',

    /**
     * Filters fields
     */
    FIELD_TYPE_FILTER_TABLE_CONFIG: 'field-filter-table-config',
    FIELD_TYPE_FILTER_TABLE_REFRESH: 'field-filter-table-refresh',
    FIELD_TYPE_FILTER_SEARCH: 'field-filter-search',
    FIELD_TYPE_FILTER_TEXT: 'field-filter-text',
    FIELD_TYPE_FILTER_JSON: 'field-filter-json',
    FIELD_TYPE_FILTER_NUMBER: 'field-filter-number',
    FIELD_TYPE_FILTER_BOOLEAN: 'field-filter-boolean',
    FIELD_TYPE_FILTER_DATE_RANGE: 'field-filter-date-range',
    FIELD_TYPE_FILTER_SELECT: 'field-filter-select',
    FIELD_TYPE_FILTER_EMPLOYEE: 'field-filter-employee',
    FIELD_TYPE_FILTER_EMPLOYEE_AND_USER_GROUP: 'field-filter-employee-and-user-group',
    FIELD_TYPE_FILTER_EMPLOYEE_AND_USER_GROUP_AND_DEPARTMENT: 'field-filter-employee-and-user-group-and-department',
    FIELD_TYPE_FILTER_EMPLOYEE_AND_USER_GROUP_AND_CONTRACTOR_AND_DEPARTMENT:
        'field-filter-employee-and-user-group-and-contractor-department',
    FIELD_TYPE_FILTER_COMPANY: 'field-filter-company',
    FIELD_TYPE_FILTER_CONTRACTOR: 'field-filter-contractor',
    FIELD_TYPE_FILTER_CONTRACTOR_AND_DEPARTMENT: 'field-filter-contractor-and-department',
    FIELD_TYPE_FILTER_POSITION: 'field-filter-position',
    FIELD_TYPE_FILTER_PROJECT: 'field-filter-project',
    FIELD_TYPE_FILTER_TASK: 'field-filter-task',
    FIELD_TYPE_FILTER_WORK_HOURS: 'field-filter-work-hours',
    FIELD_TYPE_FILTER_APARTMENT_ROOM: 'field-filter-apartment-room',
    FIELD_TYPE_FILTER_WORK_DEMAND: 'field-filter-work-demand',
    FIELD_TYPE_FILTER_ACCOUNTANT: 'field-filter-accountant',
    FIELD_TYPE_FILTER_USER_CONTRACT: 'field-filter-user-contract',
    FIELD_TYPE_FILTER_TAG: 'field-filter-tag',
    FIELD_TYPE_FILTER_WORK_CALENDAR: 'field-filter-work-calendar',

    /**
     * Custom matchers
     */
    TO_CONTAIN_ARRAY: 'toContainArray',
    TO_LET_AUTOCOMPLETE_HAVE_SOME_OPTION_MATCHER: 'toLetAutocompleteHaveSomeOptions',

    FIELD_ADDITIONAL_CONTRACTOR_WORK_HOUR_TEMPLATE: 'CONTRACTOR_WORK_HOUR_TEMPLATE',
});

/**
 * TODO: Change this to named export and replace all usages
 */
export default enums;
