<template>
    <mobile-navigation-header :hide-header="props.hideHeader" @toggleDrawer="toggleLeftDrawer()" />
    <q-drawer v-if="!hideLeftMenu" v-model="leftSideOpen" :width="leftMenuWidth" show-if-above bordered class="mobile-navigation">
        <q-scroll-area class="mobile-navigation--scroll-area" @scroll="handleMenuScroll">
            <div class="mobile-navigation--scroll-area--content column no-wrap justify-between">
                <div class="mobile-navigation--scroll-area--content--logo-wrapper" :class="{ 'has-shadow': isHeaderShadowVisible }">
                    <router-link to="/">
                        <img :src="logoPath" :style="{ 'max-width': leftMenuWidth * 0.7 + 'px' }" class="logo" alt="logo" />
                    </router-link>
                </div>
                <div class="mobile-navigation--scroll-area--content--wrapper">
                    <div
                        v-if="isRoleSelectionVisible || isCompanySelectionVisible"
                        class="mobile-navigation--scroll-area--content--first-section"
                    >
                        <span class="section-header">Konto</span>
                        <div class="selects-wrapper">
                            <v-form-builder v-model="fields" :submit-button="false" />
                        </div>
                        <div v-if="isSuperUser" class="mobile-navigation--scroll-area--content--first-section--buttons">
                            <q-btn
                                type="submit"
                                align="left"
                                flat
                                class="full-width secondary-button input-like-button"
                                to="/support/change-company"
                            >
                                <font-awesome-icon icon="fa-regular fa-gear" class="fs-18" />
                                {{ $t('Support') }}
                            </q-btn>
                            <q-btn
                                type="submit"
                                align="left"
                                flat
                                class="full-width q-mt-md secondary-button input-like-button"
                                to="/subscriptions"
                            >
                                <font-awesome-icon icon="fa-regular fa-money-bill" />
                                {{ $t('Subskrypcje') }}
                            </q-btn>
                        </div>
                    </div>
                    <div>
                        <span class="section-header">Menu</span>
                        <q-list class="menu-list">
                            <navigation-menu-item-component :items="menuSections" :is-navigation-header-visible="false" :is-mobile="true" />
                        </q-list>
                    </div>
                </div>

                <div class="bottom-items column justify-end">
                    <div>
                        <q-btn type="submit" color="brand" class="full-width q-mt-md secondary-button" to="/settings/user-settings">
                            {{ $t('Ustawienia użytkownika') }}
                        </q-btn>
                        <q-btn
                            v-if="authStore.isNativeMobile"
                            type="submit"
                            color="brand"
                            to="/mobile-settings"
                            class="full-width q-mt-md secondary-button"
                        >
                            {{ $t('Ustawienia aplikacji mobilnej') }}
                        </q-btn>
                        <q-btn type="submit" color="brand" class="full-width q-mt-md" to="/auth/logout">
                            {{ $t('Wyloguj się') }}
                        </q-btn>
                    </div>
                    <div class="soft-version text-default" style="height: 20px">
                        <div v-if="systemVersion">ID {{ companyId }} | Ver. {{ systemVersion }}</div>
                    </div>
                </div>
            </div>
        </q-scroll-area>
    </q-drawer>
</template>

<script setup lang="ts">
import { Screen } from 'quasar';
import { computed, inject, ref } from 'vue';
import MobileNavigationHeader from 'components/Navigation/Mobile/MobileNavigationHeader.vue';
import NavigationMenuItemComponent from 'components/Navigation/NavigationMenuItemComponent.vue';
import { useAuthStore } from 'stores/auth';
import mainLogo from 'assets/images/logo.png';
import minLogo from 'assets/images/logo-min.png';
import VFormBuilder from 'components/FormBuilder/VFormBuilder.vue';
import enums from 'components/FormBuilder/enums';
import { useRoute, useRouter } from 'vue-router/dist/vue-router';
import CompanyWithLogoSelectOption from 'components/FormBuilder/Fields/Select/descriptions/CompanyWithImageSelectOption.vue';
import { FieldCollection } from '@soulab/form-builder';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
const systemVersion = computed(() => authStore.getSystemVersion);
const companyId = computed(() => authStore.getCompanyIdWithRoleShortcut);

const props = defineProps({
    hideHeader: {
        type: Boolean,
        default: false,
    },
    hideLeftMenu: {
        type: Boolean,
        default: false,
    },
});

const $t = inject('trans');

//SCROLL
const isHeaderShadowVisible = ref<boolean>(false);
const handleMenuScroll = (e: Event): void => {
    isHeaderShadowVisible.value = +e.verticalPosition > 20;
};

// DRAWER SHOW
const leftMenuWidth = ref<number>(400);
const leftSideOpen = ref<boolean>(false);
const toggleLeftDrawer = (): void => {
    if (Screen.lt.md) {
        leftMenuWidth.value = window.innerWidth < 530 ? window.innerWidth - 80 : 450;
        leftSideOpen.value = !leftSideOpen.value;
    }

    window.dispatchEvent(new Event('resize'));
};

// LOGO
const logoPath = computed(() => {
    const logo = authStore.getCompanyLogoPath;
    return logo.length > 0 ? logo : mainLogo;
});

// MENU ITEMS
const menuSections = computed(() => {
    return authStore.getLeftNavigation;
});

// TOP SELECTS
const companies = computed(() => {
    if (authStore.isWorker) {
        const subscriptions = authStore.getSubscriptions.map((subscription) => ({
            label: subscription.name,
            logoMinPath: subscription.faviconPath || minLogo,
            value: subscription.id,
        }));

        const currentCompanyId = authStore.getCurrentCompanyId;
        const currentCompanyAsSubscription = subscriptions.find((subscription) => subscription.value === currentCompanyId);

        // Checks if current company is not a subscription and adds it to subscriptions list
        if (!currentCompanyAsSubscription) {
            const currentSubscriptionId = authStore.getSubscription.company.id;
            const filteredSubscriptions = subscriptions.filter((subscription) => subscription.value !== currentSubscriptionId);

            filteredSubscriptions.push({
                label: authStore.getCompanies[currentCompanyId].name,
                logoMinPath: authStore.getCompanies[currentCompanyId].branding?.logoMinPath || minLogo,
                value: currentCompanyId,
            });

            return filteredSubscriptions;
        }

        return subscriptions;
    } else {
        return Object.keys(authStore.getCompanies).map((key) => {
            return {
                label: authStore.getCompanies[key].name,
                logoMinPath: authStore.getCompanies[key].branding?.logoMinPath || minLogo,
                value: parseInt(key),
            };
        });
    }
});

const changeCompany = (cmpId: number): void => {
    if (authStore.getCurrentCompanyId !== cmpId) {
        router.push({
            name: 'change-company',
            params: {
                cmpId,
            },
            query: {
                redirectQueryParams: JSON.stringify(route.query),
            },
        });
    }
};

const changeRole = (userRole: string): void => {
    const param = userRole === 'worker' ? 'worker' : null;

    router.push({
        name: 'change-user-role',
        params: {
            userRole: param,
            companyId: getCompanyIdToSetInUserPortal.value
        },
        query: {
            redirectQueryParams: JSON.stringify(route.query),
        },
    });
};

const getCompanyIdToSetInUserPortal = computed(() => {
    return authStore.getCompanyIdToSetInUserPortal;
});

const isRoleSelectionVisible = computed(() => {
    return authStore.canChangeRole;
});

const isCompanySelectionVisible = computed(() => {
    return companies.value.length > 1;
});

const isSuperUser = computed(() => {
    return authStore.isSuperuser;
});

const fields = ref<FieldCollection>({
    company: {
        type: enums.FIELD_TYPE_SELECT,
        name: 'companyId',
        label: '',
        value: authStore.getCurrentCompanyId,
        options: companies,
        visible: isCompanySelectionVisible,
        attributes: {
            onchange: [
                {
                    action: 'runFieldStaticFunctionAction',
                    params: {
                        callback(form) {
                            changeCompany(form.company.value);
                        },
                    },
                },
            ],
        },
        additional: {
            optionComponent: CompanyWithLogoSelectOption,
        },
    },
    portalSelect: {
        type: enums.FIELD_TYPE_SELECT,
        name: 'appType',
        label: '',
        value: authStore.isAdmin || authStore.isUser ? 'admin' : 'worker',
        visible: isRoleSelectionVisible,
        options: [
            { label: $t('Portal pracowniczy'), value: 'worker', iconName: 'fa-regular fa-user-helmet-safety' },
            { label: $t('Portal użytkownika'), value: 'admin', iconName: 'fa-regular fa-user' },
        ],
        attributes: {
            onchange: [
                {
                    action: 'runFieldStaticFunctionAction',
                    params: {
                        callback(form) {
                            changeRole(form.portalSelect.value);
                        },
                    },
                },
            ],
        },
        additional: {
            optionComponent: CompanyWithLogoSelectOption,
        },
    },
});
</script>

<style lang="scss">
.mobile-navigation {
    &--scroll-area {
        height: 100%;

        &--content {
            height: var(--app-height);

            &--logo-wrapper {
                position: fixed;
                top: 0;
                margin: auto;
                width: 100%;
                background-color: var(--white-color);
                z-index: 10;

                &.has-shadow {
                    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.4);
                }

                .logo {
                    object-fit: contain;
                    margin: auto;
                    margin-top: 12px;
                    display: block;
                    max-height: 60px;
                }
            }

            &--wrapper {
                margin-top: 65px;
            }

            .selects-wrapper {
                display: block;
                margin: auto;
                padding: 14px;
            }

            .section-header {
                color: #7b8190;
                padding-left: 18px;
                text-transform: uppercase;
                font-size: 0.75rem;
                font-weight: 600;
                letter-spacing: 0.05em;

                .menu-list {
                    margin-top: 11px;
                }
            }

            &--first-section--buttons {
                padding: 0 14px 14px 14px;
            }
        }
    }

    .bottom-items {
        padding: 0 14px;

        .soft-version {
            text-align: center;
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 0.65rem;
            font-weight: 100;
        }
    }
}

.navigation-content {
    height: var(--app-height);
}
</style>
