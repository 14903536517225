import { useAuthStore } from 'stores/auth';
import { useModalStore } from 'stores/modal';
import { showAlert } from 'src/hooks/showAlert';
import { AlertType } from 'src/enums/alert';
import { i18n } from 'boot/i18n';
import { isArray } from '@soulab/js-helpers';

/**
 * @param {string|string[]} roles
 * @param {string} failRedirectPath
 * @param {string} failMessage
 * @returns {import('vue-router').NavigationGuardWithThis<undefined>}
 */
export function roleMiddleware(roles, failRedirectPath = '/', failMessage = 'Brak dostępu') {
    return (to, from, next) => {
        if (!isArray(roles)) {
            roles = [roles];
        }

        if (!roles.includes(useAuthStore().getCurrentRole.role)) {
            showAlert(AlertType.DANGER, i18n.global.t(failMessage));

            if (to.meta.openInModal) {
                useModalStore().closeNextModal();
            }

            return failRedirectPath;
        }

        return next();
    };
}
