<template>
    <div class="navigation-full">
        <div v-if="parent && parent.length > 0" class="text-center fs-12 text-bold relative-position">
            <div class="bg-brand absolute full-height full-width q-pa-xs" style="opacity: 0.3"></div>
            <div class="full-height full-width q-pa-xs">
                {{ parent }}
            </div>
        </div>

        <q-expansion-item
            v-for="(section, key) in elements"
            :default-opened="props.isMobile && props.level !== 1"
            :key="key"
            v-model="section.open"
            :to="section.path"
            :href="section.url"
            :target="section.url ? '_blank' : ''"
            :expand-icon="section.children ? '' : 'none'"
            :header-style="!section.add ? 'padding-left: ' + levelMargin : 'padding-left: ' + levelMarginAddElement"
            :dense="section.onlyLabel"
            :style="section.onlyLabel ? 'background-color: rgba(0, 0, 0, 0.2);' : ''"
            active-class="text-brand q-manual-focusable--focused"
            :expand-icon-class="
                (!shortMenu && level === 0 ? 'navigation-main-section-arrow' : 'navigation-section-arrow') +
                ' ' +
                (level !== 0 && section.open ? 'text-brand' : '') +
                (!section.children ? 'hidden' : '')
            "
            :header-class="{
                'text-brand': (section.isActive && (level !== 0 || shortMenu)) || (level !== 0 && section.open),
                'navigation-header-class': true,
                'navigation-main-section-header': !shortMenu && level === 0,
                'navigation-base-section-header': level !== 0,
                'q-manual-focusable--focused': level !== 0 && section.open,
                hidden: !props.isNavigationHeaderVisible && key === 0,
            }"
            @show="
                () => {
                    showNavElement(section);
                    section.children ? '' : (section.open = false);
                }
            "
        >
            <template #header>
                <div
                    class="navigation-item-row full-width"
                    :class="{
                        'navigation-item-main': !shortMenu && level === 0,
                    }"
                >
                    <div v-if="section.icon && (!shortMenu || section.add) && (level !== 0 || shortMenu)" class="navigation-item-element">
                        <div class="navigation-item-element--icon">
                            <font-awesome-icon :icon="section.icon" />
                        </div>
                    </div>

                    <div :class="{ 'navigation-item-element relative-position': true, badge: section?.badge }">
                            <span class="navigation-item-label">{{ $t(section.label) }}</span>

                            <div
                                v-if="section?.badge"
                                style="
                                    border-radius: 15px;
                                    margin-left: 5px;
                                    padding: 5px;
                                    font-size: 0.575rem;
                                    color: #fff;
                                "
                                :style="(section.badge?.style ?? []).join('; ')"
                            >
                                {{ $t(section.badge.label) }}
                            </div>
                        </div>

                    <div v-if="section.iconAfterTitle" class="navigation-item-element">
                        <router-link v-if="section.iconAfterTitle.link" :to="section.iconAfterTitle.link">
                            <font-awesome-icon
                                v-if="section.iconAfterTitle.icon"
                                :icon="section.iconAfterTitle.icon"
                                class="link q-ml-xs"
                            />
                        </router-link>
                        <font-awesome-icon
                            v-else-if="section.iconAfterTitle.icon"
                            :icon="section.iconAfterTitle.icon"
                            class="link q-ml-xs"
                        />
                    </div>
                </div>
            </template>

            <template v-if="section.children" #default>
                <navigation-menu-item-component
                    :items="section.children"
                    :level="level + 1"
                    :short-menu="shortMenu"
                    :is-mobile="props.isMobile"
                />
            </template>
        </q-expansion-item>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
    parent: {
        type: String,
        default: '',
    },
    level: {
        type: Number,
        default: 0,
    },
    shortMenu: {
        type: Boolean,
        default: false,
    },
    isNavigationHeaderVisible: {
        type: Boolean,
        default: true,
    },
    isMobile: {
        type: Boolean,
        default: false,
    },
});

const baseItemMargin = props.isMobile ? -8 : 16;
const oneLevelItemMargin = props.isMobile ? 38 : 15;

const elements = ref(props.items);

watch(props, (newValue) => {
    elements.value = newValue.items;
});

const showNavElement = (section) => {
    const activeGroup = section.group || '';

    if (activeGroup !== '') {
        for (let i in elements.value) {
            if (elements.value[i].group && elements.value[i].group !== activeGroup) {
                elements.value[i].open = false;
            }
        }
    }
};

const levelMargin = computed(() => {
    return baseItemMargin + props.level * oneLevelItemMargin + 'px';
});

const levelMarginAddElement = computed(() => {
    if (props.shortMenu) {
        return baseItemMargin;
    }

    return baseItemMargin + (props.level + 1) * oneLevelItemMargin + 'px';
});
</script>

<style scoped lang="scss">
@media (max-width: $breakpoint-sm) {
    .navigation-header-class {
        padding-left: 30px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}
.navigation-item-row {
    display: flex;
    flex-direction: row;

    @media (max-width: $breakpoint-sm) {
        font-size: 15px;
    }
}

.navigation-item-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navigation-item-element.badge {
    flex-direction: row;
    flex-grow: 1;
}

.navigation-item-element.badge .navigation-item-label {
    flex-grow: 1;
}

.navigation-item-main {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.05em;
}
</style>
