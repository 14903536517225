<template>
    <q-header v-if="!props.hideHeader" v-sticky-context.top class="bg-white" elevated>
        <navigation-component-banner v-if="isTestEnvironment" class="bg-red">
            {{ $t('UWAGA! Pracujesz na środowisku testowym.') }}
            <a href="https://app.hrappka.pl" target="_blank" class="link">{{ $t('Kliknij tutaj') }}</a
            >,
            {{ $t('aby przejść do środowiska produkcyjnego.') }}
        </navigation-component-banner>

        <navigation-component-banner v-if="isSubscriptionInfoActive" class="bg-brand">
            {{ subscriptionInfo }}
        </navigation-component-banner>

        <q-toolbar class="navigation-header">
            <q-btn flat dense round icon="menu" aria-label="Menu" class="text-default q-mr-md" @click="toggleLeftDrawer" />

            <search-navigation-component v-if="isAdminOrUser" class="display-flex" />
            <div v-else class="display-flex full-width" :style="{ height: '56px' }"></div>

            <router-link v-if="isAdminOrUser" to="/notifications" class="q-mr-md q-ml-md">
                <div class="display-flex fs-16 text-default relative-position">
                    <div v-if="feedCount && feedCount > 0" class="absolute notification-badge-parent">
                        <div class="notification-badge">
                            {{ feedCount }}
                        </div>
                    </div>
                    <font-awesome-icon icon="far fa-bell" />
                </div>
            </router-link>

            <user-panel-navigation-component v-if="!mobileUserPanel" class="display-flex" />
        </q-toolbar>
    </q-header>

    <q-drawer
        v-if="reloadNavigation && !props.hideLeftMenu"
        v-model="leftSideOpen"
        :width="leftMenuWidth"
        show-if-above
        bordered
        :top="subscriptionDivHeight"
        :style="'top:' + subscriptionDivHeight"
        @on-layout="recalculateLayout(true)"
    >
        <div v-sticky-context.left :style="!mobileUserPanel ? { height: '1px', marginTop: '-1px' } : {}"></div>

        <div :style="{ height: defaultNavigationHeight }">
            <router-link to="/">
                <img
                    :src="leftMenuWidth <= 60 ? logoMinPath : logoPath"
                    :style="{ 'max-width': leftMenuWidth - 1 + 'px', 'object-fit': 'contain' }"
                    class="logo"
                    alt="logo"
                />
            </router-link>
        </div>

        <q-scroll-area
            :class="{
                'height-small': leftMenuWidth <= 60,
                'height-full': leftMenuWidth > 60,
            }"
            :visible="!(leftMenuWidth <= 60)"
        >
            <q-list>
                <navigation-menu-short-item-component v-if="leftMenuWidth <= 60" :items="sections" />
                <navigation-menu-item-component v-else-if="mobileUserPanel" :items="sectionsWithTopPanel" />
                <navigation-menu-item-component v-else :items="sections" />
            </q-list>
        </q-scroll-area>

        <div
            class="soft-version text-default"
            :class="{ hidden: !mobileUserPanel && leftMenuWidth <= 60 }"
            style="height: 20px; max-height: 20px"
        >
            <div v-if="systemVersion">ID {{ companyId }} | Ver. {{ systemVersion }}</div>
        </div>
    </q-drawer>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import { Screen, extend } from 'quasar';
import { empty } from '@soulab/js-helpers';
import { useStickyContext } from '@soulab/vue-data-table';
import NavigationComponentBanner from 'components/Navigation/NavigationComponentBanner.vue';
import SearchNavigationComponent from 'components/Navigation/SearchNavigationComponent.vue';
import UserPanelNavigationComponent from 'components/Navigation/UserPanelNavigationComponent.vue';
import NavigationMenuItemComponent from 'components/Navigation/NavigationMenuItemComponent.vue';
import NavigationMenuShortItemComponent from 'components/Navigation/NavigationMenuShortComponent.vue';
import { useAuthStore } from 'stores/auth';
import { useRoute } from 'vue-router/dist/vue-router';
import { menuMode } from 'src/enums/navigation';
import mainLogo from 'assets/images/logo.png';
import minLogo from 'assets/images/logo-min.png';
import { oldHrappka } from 'boot/axios';
import { useStorage } from 'src/hooks/useStorage';
import { StorageService } from 'src/services/storage-service';
import { useViewStore } from 'stores/view';

const props = defineProps({
    hideHeader: {
        type: Boolean,
        default: false,
    },
    hideLeftMenu: {
        type: Boolean,
        default: false,
    },
    navigationContentClass: {
        type: Array,
        default: () => [],
    },
    layoutClass: {
        type: Array,
        default: () => [],
    },
});

const vStickyContext = useStickyContext().stickyContextDirective;

const route = useRoute();
const reloadNavigation = ref(true);
const leftSideOpen = ref(false);
const leftMenuWidth = ref(Screen.lt.md ? 250 : 270);
const defaultNavigationHeight = ref('66px');
const firstInit = ref(true);

const authStore = useAuthStore();
const viewStore = useViewStore();
const subscriptionInfo = computed(() => authStore.getSubscriptionInfo);
const systemVersion = computed(() => authStore.getSystemVersion);
const companyId = computed(() => authStore.getCompanyIdWithRoleShortcut);
const topNavigation = computed(() => authStore.getTopNavigation);
const feedCount = computed(() => authStore.getFeedCount);
const minMenu = authStore.minMenuIsActive;

if (minMenu) {
    if (Screen.lt.md) {
        leftMenuWidth.value = 250;
    } else {
        leftMenuWidth.value = 60;
    }
}

const logoPath = computed(() => {
    const logo = authStore.getCompanyLogoPath;
    return logo.length > 0 ? logo : mainLogo;
});

const logoMinPath = computed(() => {
    const logo = authStore.getCompanyMinLogoPath;
    return logo.length > 0 ? logo : minLogo;
});

const isAdminOrUser = computed(() => {
    return authStore.isAdmin || authStore.isUser;
});

const isTestEnvironment = computed(() => {
    return viewStore.isTestEnvironment;
});

const isSubscriptionInfoActive = computed(() => {
    const subscriptionInfo = authStore.getSubscriptionInfo;
    return Boolean(subscriptionInfo && subscriptionInfo.length > 0);
});

const mobileUserPanel = computed(() => {
    return !Screen.gt.sm;
});

/**
 * Metoda rekurencyjna otwierająca aktuwne elementy nawigacji po routingu
 *
 * @param elements
 * @param firstUsage
 * @returns {boolean}
 */
const parentIsActive = (elements, firstUsage = true) => {
    const currentRoute = route.path;

    let anyActive = false;
    for (let i in elements) {
        if (elements[i].children && elements[i].children.length > 0) {
            const result = parentIsActive(elements[i].children, false);

            if (result) {
                elements[i].isActive = true;
                elements[i].open = true;
            } else if (!firstUsage) {
                /**
                 * Elementy zwijamy tylko dla short menu
                 */
                if (leftMenuWidth.value <= 60) {
                    elements[i].open = false;
                }

                elements[i].isActive = false;
            }
        } else {
            if (elements[i].path === currentRoute) {
                anyActive = true;
            }
        }
    }

    return anyActive;
};

/**
 * Metoda wyliczająca na nowo odstępy gdy zmienia nam się rozmiar nawigacji
 */
const recalculateLayout = (delay = false) => {
    /**
     * Czekamy gdy skończą się animacje menu i wyliczamy sticky context na nowo
     */
    setTimeout(
        () => {
            useStickyContext().stickyContextRecalculate();
        },
        delay ? 100 : 0
    );
};

/**
 * Główna metoda rozwijająca sekcje nawigacji na start
 */
const toggleNavigationElements = (elements) => {
    const currentRoute = route.path;

    /**
     * Tablica routingów z ustawień
     */
    const settingsRoutes = ['/chat', '/translate', '/settings', '/checklist/settings'];

    /**
     * Sprawdzenie czy aktuanly routing to ustawienia
     */
    let settingsNavigationActive = false;
    settingsRoutes.forEach((value) => {
        const active = currentRoute.indexOf(value) !== -1;

        if (active) {
            settingsNavigationActive = true;
            return false;
        }
    });

    if (currentRoute === '/') {
        settingsNavigationActive = false;
    }

    /**
     * Rozwijanie ustawień jeżeli aktywny routing lub pozostałych w przeciwnym wypadku
     */
    for (let i in elements) {
        if (settingsNavigationActive) {
            if (elements[i].id && elements[i].id === 'settings') {
                elements[i].open = true;
            }
        } else {
            if (elements[i].id && elements[i].id !== 'settings') {
                elements[i].open = true;
            }
        }
    }

    firstInit.value = false;

    /**
     * Rozwijanie aktualnie aktywnych komponentów po rountingu
     */
    parentIsActive(elements);
};

const toggleLeftDrawer = () => {
    if (Screen.lt.md) {
        leftMenuWidth.value = 250;

        leftSideOpen.value = !leftSideOpen.value;
    } else {
        if (leftMenuWidth.value <= 60) {
            leftMenuWidth.value = 270;
            saveNavigationMode();
        } else {
            leftMenuWidth.value = 60;
            saveNavigationMode(true);
        }
    }

    window.dispatchEvent(new Event('resize'));

    recalculateLayout();
};

const sections = ref([]);
const sectionsWithTopPanel = computed(() => [...topNavigation.value, ...sections.value]);

const saveNavigationMode = (minMode = false) => {
    const storage = useStorage(StorageService.KEY_AUTH_DATA);
    storage.setItem('minMenu', minMode);

    oldHrappka.post('/index/save-layout-mode-ajax', {
        menuSize: minMode ? menuMode.short : menuMode.default,
    });
};

watch(
    () => authStore.getLeftNavigation,
    (newValue, oldValue) => {
        /**
         * Aktualizujemy menu jedynie jeśli zapis ustawień spowodował zmiany w elementach nawigacji
         */
        if (empty(sections.value) || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            sections.value = extend(true, [], newValue);
        }

        /**
         * Czasami użytkownicy mają takie błędy, że znika im nawigacja itp.
         * problem jest taki że mamy wybrakowane dane w IndexedDB, więc próbujemy je pobrać ponownie
         *
         * @see https://hrappka.atlassian.net/browse/HR-6973
         */
        if (empty(sections.value)) {
            authStore.loadPostData();
            return;
        }

        if (firstInit.value) {
            toggleNavigationElements(sections.value);
        }
    },
    { deep: true, immediate: true }
);

watch(
    () => route,
    () => parentIsActive(sections.value)
);
</script>

<style lang="scss">
.body--wcag .q-drawer {
    background: $main-background-wcag-light;
}
</style>

<style scoped>
.logo {
    height: v-bind(defaultNavigationHeight);
    padding: 12px;
    margin-left: auto;
    margin-right: auto;
    display: table;
}

.height-small {
    height: calc(100% - v-bind(defaultNavigationHeight));
}

.height-full {
    height: calc(100% - v-bind(defaultNavigationHeight) - 30px);
}

.navigation-header {
    padding-top: 5px;
    padding-bottom: 5px;
}

.navigation-content {
    height: var(--app-height);
}

.soft-version {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 0.65rem;
    font-weight: 100;
}

.display-flex {
    display: flex;
    align-items: center;
}

.notification-badge-parent {
    top: -15px;
    left: -11px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 36px;
}

.notification-badge {
    font-size: 0.625rem;
    background: #f46a6a !important;
    padding: 3px 7px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
}
</style>
