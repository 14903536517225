import { empty, findInObject } from "@soulab/js-helpers";
import { date } from "quasar";
import { i18n } from "boot/i18n";
import { dateFormats } from 'src/enums/date';

export const dateFormatFull = 'Y-m-d H:i';
export const dateFormatDate = 'Y-m-d';
export const dateFormatTime = 'H:i';
export const dateFormatYearMonth = 'Y-m';

export const dateFormatMonthYear = 'm/Y';
export const dateFormatYear = 'Y';

const dateFormatTimeList = [dateFormatTime];

export const dateFormatRegexMap = {
    [dateFormatFull]: /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    [dateFormatDate]: /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
    [dateFormatTime]: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    [dateFormatYearMonth]: /^[0-9]{4}-(0[1-9]|1[012])$/,
    [dateFormatMonthYear]: /^(0[1-9]|1[012])\/[0-9]{4}$/,
    [dateFormatYear]: /^[0-9]{4}$/,
};

export function dateMessage(format) {
    if (dateFormatTimeList.includes(format)) {
        return 'Wprowadzono nieprawidłowy czas';
    }

    return 'Wprowadzono nieprawidłową date';
}

/**
 * Validator to check if date with passed format is valid for given field value
 */
export function dateRule(value, validator, context) {
    const from = validator.from;
    const to = validator.to;
    const multiple = validator.multiple;
    const allowEmpty = validator.allowEmpty;
    const allowAnyTime = validator.allowAnyTime;
    const customDateMessage = validator.customDateMessage || null;
    const format = validator.format ?? dateFormatFull;

    if (empty(value, false)) {
        return true;
    }

    if (!empty(multiple)) {
        for (let i in value) {
            if (allowEmpty && empty(value[i])) {
                continue;
            }

            if (allowAnyTime && !/^[0-9]+:[0-5][0-9]$/.test(value[i])) {
                return context.rule.params?.message || dateMessage(dateFormatTime);
            } else if (!allowAnyTime && !dateFormatRegexMap[validator.format].test(value[i])) {
                return context.rule.params?.message || dateMessage(validator.format);
            }

            if (!dateFormatTimeList.includes(validator.format) && !date.isValid(value[i])) {
                return context.rule.params?.message || dateMessage(validator.format);
            }
        }
    } else {
        if (allowAnyTime && !/^[0-9]+:[0-5][0-9]$/.test(value)) {
            return context.rule.params?.message || dateMessage(dateFormatTime);
        } else if (!allowAnyTime && !dateFormatRegexMap[validator.format].test(value)) {
            return context.rule.params?.message || dateMessage(validator.format);
        }

        if (!dateFormatTimeList.includes(validator.format) && !date.isValid(value)) {
            return context.rule.params?.message || dateMessage(validator.format);
        }
    }

    if ((from && date.getDateDiff(from, value) > 0) || (to && date.getDateDiff(value, to) > 0)) {
        const fromFormatted = date.formatDate(from, dateFormats.DATE_FORMAT_BASE);
        const toFormatted = date.formatDate(to, dateFormats.DATE_FORMAT_BASE);

        return context.rule.params?.rangeMessage || `${i18n.global.t('Data musi zawierać się we wskazanym okresie')} ${fromFormatted} - ${toFormatted}`;
    }

    return true;
}

export function dateRangeRule(value, validator, context) {
    const from = findInObject(context.form, validator.from ?? '');
    const to = findInObject(context.form, validator.to ?? '');
    const allowAnyRange = validator.allowAnyRange;
    const format = validator.format ?? dateFormatFull;

    if (!empty(from, false) && !dateFormatRegexMap[format].test(from)) {
        return context.rule.params?.message || dateMessage(format);
    }

    if (!empty(to, false) && !dateFormatRegexMap[format].test(to)) {
        return context.rule.params?.message || dateMessage(format);
    }

    if (empty(from, false) || empty(to, false)) {
        return true;
    }

    if (!allowAnyRange && from && to) {
        if (dateFormatTimeList.includes(format)) {
            const [fromHours, fromMinutes] = from.split(':');
            const [toHours, toMinutes] = to.split(':');

            if (fromHours > toHours || (fromHours === toHours && fromMinutes > toMinutes)) {
                return context.rule.params?.fromToHoursMessage || 'Czas od nie może być późniejszy od czasu do';
            }
        } else {
            if (date.getDateDiff(from, to) > 0) {
                return context.rule.params?.fromToMessage || 'Data od nie może być późniejsza od daty do';
            }
        }
    }
    return true;
}
