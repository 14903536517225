import { empty } from '@soulab/js-helpers';

export function postCodeRule(value, validator, context) {
    if (empty(value, false)) {
        return true;
    }

    if (!/\d+$/.test(value)) {
        return context.rule.params?.message || 'Wprowadzono nieprawidłowy kod pocztowy';
    }

    return true;
}
