export const countryCode = Object.freeze({
    PL: 'PL',
    BY: 'BY',
    MD: 'MD',
    UA: 'UA',
    RU: 'RU',
    ES: 'ES',
    CH: 'CH',
    UZ: 'UZ',
    GE: 'GE',
    DE: 'DE',
    GB: 'GB',
    NL: 'NL',
    FR: 'FR',
    IE: 'IE',
    CZ: 'CZ',
    SK: 'SK',
    BE: 'BE',
    RO: 'RO',
    AM: 'AM',
    BG: 'BG',
    SC: 'SC',
    LK: 'LK',
    KZ: 'KZ',
    AT: 'AT',
    US: 'US',
    AZ: 'AZ',
    KG: 'KG',
    AF: 'AF',
    AL: 'AL',
    DZ: 'DZ',
    AD: 'AD',
    AO: 'AO',
    AI: 'AI',
    AQ: 'AQ',
    AG: 'AG',
    SA: 'SA',
    AR: 'AR',
    AW: 'AW',
    AU: 'AU',
    BS: 'BS',
    BH: 'BH',
    BD: 'BD',
    BB: 'BB',
    BZ: 'BZ',
    BJ: 'BJ',
    BM: 'BM',
    BT: 'BT',
    BO: 'BO',
    BQ: 'BQ',
    BA: 'BA',
    BW: 'BW',
    BR: 'BR',
    BN: 'BN',
    IO: 'IO',
    BF: 'BF',
    BI: 'BI',
    XC: 'XC',
    CL: 'CL',
    CN: 'CN',
    CW: 'CW',
    HR: 'HR',
    CY: 'CY',
    TD: 'TD',
    ME: 'ME',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DJ: 'DJ',
    EG: 'EG',
    EC: 'EC',
    ER: 'ER',
    EE: 'EE',
    ET: 'ET',
    FK: 'FK',
    FJ: 'FJ',
    PH: 'PH',
    FI: 'FI',
    TF: 'TF',
    GA: 'GA',
    GM: 'GM',
    GH: 'GH',
    GI: 'GI',
    GR: 'GR',
    GD: 'GD',
    GL: 'GL',
    GU: 'GU',
    GY: 'GY',
    GT: 'GT',
    GN: 'GN',
    HT: 'HT',
    HN: 'HN',
    HK: 'HK',
    IN: 'IN',
    ID: 'ID',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IL: 'IL',
    JM: 'JM',
    JP: 'JP',
    YE: 'YE',
    JO: 'JO',
    KY: 'KY',
    KH: 'KH',
    CM: 'CM',
    CA: 'CA',
    QA: 'QA',
    KE: 'KE',
    KI: 'KI',
    CO: 'CO',
    KM: 'KM',
    CG: 'CG',
    KP: 'KP',
    XK: 'XK',
    CR: 'CR',
    CU: 'CU',
    KW: 'KW',
    LA: 'LA',
    LS: 'LS',
    LB: 'LB',
    LR: 'LR',
    LY: 'LY',
    LI: 'LI',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    MK: 'MK',
    MG: 'MG',
    YT: 'YT',
    MO: 'MO',
    MW: 'MW',
    MV: 'MV',
    MY: 'MY',
    ML: 'ML',
    MT: 'MT',
    MP: 'MP',
    MA: 'MA',
    MR: 'MR',
    MU: 'MU',
    MX: 'MX',
    XL: 'XL',
    FM: 'FM',
    UM: 'UM',
    MN: 'MN',
    MS: 'MS',
    MZ: 'MZ',
    MM: 'MM',
    NA: 'NA',
    NR: 'NR',
    NP: 'NP',
    NE: 'NE',
    NG: 'NG',
    NI: 'NI',
    NU: 'NU',
    NF: 'NF',
    NO: 'NO',
    NC: 'NC',
    NZ: 'NZ',
    PS: 'PS',
    OM: 'OM',
    PK: 'PK',
    PW: 'PW',
    PA: 'PA',
    PG: 'PG',
    PY: 'PY',
    PE: 'PE',
    PN: 'PN',
    PF: 'PF',
    GS: 'GS',
    PT: 'PT',
    KR: 'KR',
    ZA: 'ZA',
    RS: 'RS',
    CF: 'CF',
    RW: 'RW',
    EH: 'EH',
    BL: 'BL',
    SV: 'SV',
    WS: 'WS',
    AS: 'AS',
    SM: 'SM',
    SN: 'SN',
    SL: 'SL',
    SG: 'SG',
    SZ: 'SZ',
    SI: 'SI',
    SO: 'SO',
    PM: 'PM',
    KN: 'KN',
    LC: 'LC',
    VC: 'VC',
    SD: 'SD',
    SS: 'SS',
    SR: 'SR',
    SY: 'SY',
    SE: 'SE',
    SH: 'SH',
    TJ: 'TJ',
    TH: 'TH',
    TW: 'TW',
    TZ: 'TZ',
    TG: 'TG',
    TK: 'TK',
    TO: 'TO',
    TT: 'TT',
    TN: 'TN',
    TR: 'TR',
    TM: 'TM',
    TC: 'TC',
    TV: 'TV',
    UG: 'UG',
    UY: 'UY',
    VU: 'VU',
    WF: 'WF',
    VA: 'VA',
    VE: 'VE',
    HU: 'HU',
    VN: 'VN',
    IT: 'IT',
    TL: 'TL',
    CI: 'CI',
    BV: 'BV',
    CX: 'CX',
    CK: 'CK',
    VI: 'VI',
    VG: 'VG',
    HM: 'HM',
    CC: 'CC',
    FO: 'FO',
    MH: 'MH',
    SB: 'SB',
    SX: 'SX',
    ST: 'ST',
    ZM: 'ZM',
    CV: 'CV',
    ZW: 'ZW',
    AE: 'AE',
});

export const countryMap = Object.freeze({
    [countryCode.PL]: 'Polska',
    [countryCode.BY]: 'Białoruś',
    [countryCode.MD]: 'Mołdawia',
    [countryCode.UA]: 'Ukraina',
    [countryCode.RU]: 'Rosja',
    [countryCode.ES]: 'Hiszpania',
    [countryCode.CH]: 'Szwajcaria',
    [countryCode.UZ]: 'Uzbekistan',
    [countryCode.GE]: 'Gruzja',
    [countryCode.DE]: 'Niemcy',
    [countryCode.GB]: 'Anglia',
    [countryCode.NL]: 'Niderlandy',
    [countryCode.FR]: 'Francja',
    [countryCode.IE]: 'Irlandia',
    [countryCode.CZ]: 'Czechy',
    [countryCode.SK]: 'Słowacja',
    [countryCode.BE]: 'Belgia',
    [countryCode.RO]: 'Rumunia',
    [countryCode.AM]: 'Armenia',
    [countryCode.BG]: 'Bułgaria',
    [countryCode.SC]: 'Serbia',
    [countryCode.LK]: 'Sri Lanka',
    [countryCode.KZ]: 'Kazakhstan',
    [countryCode.AT]: 'Austria',
    [countryCode.US]: 'Stany Zjednoczone Ameryki',
    [countryCode.AZ]: 'Azerbejdżan',
    [countryCode.KG]: 'Kirgistan',
    [countryCode.AF]: 'Afganistan',
    [countryCode.AL]: 'Albania',
    [countryCode.DZ]: 'Algieria',
    [countryCode.AD]: 'Andora',
    [countryCode.AO]: 'Angola',
    [countryCode.AI]: 'Anguilla',
    [countryCode.AQ]: 'Antarktyda',
    [countryCode.AG]: 'Antigua i Barbuda',
    [countryCode.SA]: 'Arabia Saudyjska',
    [countryCode.AR]: 'Argentyna',
    [countryCode.AW]: 'Aruba',
    [countryCode.AU]: 'Australia',
    [countryCode.BS]: 'Bahamy',
    [countryCode.BH]: 'Bahrajn',
    [countryCode.BD]: 'Bangladesz',
    [countryCode.BB]: 'Barbados',
    [countryCode.BZ]: 'Belize',
    [countryCode.BJ]: 'Benin',
    [countryCode.BM]: 'Bermudy',
    [countryCode.BT]: 'Bhutan',
    [countryCode.BO]: 'Boliwia',
    [countryCode.BQ]: 'Bonaire sint eustatius i saba',
    [countryCode.BA]: 'Bośnia i Hercegowina',
    [countryCode.BW]: 'Botswana',
    [countryCode.BR]: 'Brazylia',
    [countryCode.BN]: 'Brunei Darussalam',
    [countryCode.IO]: 'Brytyjskie Terytorium Oceanu Indyjskiego',
    [countryCode.BF]: 'Burkina Faso',
    [countryCode.BI]: 'Burundi',
    [countryCode.XC]: 'Ceuta',
    [countryCode.CL]: 'Chile',
    [countryCode.CN]: 'Chiny',
    [countryCode.CW]: 'Curaçao',
    [countryCode.HR]: 'Chorwacja',
    [countryCode.CY]: 'Cypr',
    [countryCode.TD]: 'Czad',
    [countryCode.ME]: 'Czarnogóra',
    [countryCode.DK]: 'Dania',
    [countryCode.DM]: 'Dominika',
    [countryCode.DO]: 'Dominikana',
    [countryCode.DJ]: 'Dżibuti',
    [countryCode.EG]: 'Egipt',
    [countryCode.EC]: 'Ekwador',
    [countryCode.ER]: 'Erytrea',
    [countryCode.EE]: 'Estonia',
    [countryCode.ET]: 'Etiopia',
    [countryCode.FK]: 'Falklandy',
    [countryCode.FJ]: 'Fidżi Republika',
    [countryCode.PH]: 'Filipiny',
    [countryCode.FI]: 'Finlandia',
    [countryCode.TF]: 'Francuskie Terytorium Południowe',
    [countryCode.GA]: 'Gabon',
    [countryCode.GM]: 'Gambia',
    [countryCode.GH]: 'Ghana',
    [countryCode.GI]: 'Gibraltar',
    [countryCode.GR]: 'Grecja',
    [countryCode.GD]: 'Grenada',
    [countryCode.GL]: 'Grenlandia',
    [countryCode.GU]: 'Guam',
    [countryCode.GY]: 'Gujana',
    [countryCode.GT]: 'Gwatemala',
    [countryCode.GN]: 'Gwinea',
    [countryCode.HT]: 'Haiti',
    [countryCode.HN]: 'Honduras',
    [countryCode.HK]: 'Hongkong',
    [countryCode.IN]: 'Indie',
    [countryCode.ID]: 'Indonezja',
    [countryCode.IQ]: 'Irak',
    [countryCode.IR]: 'Iran',
    [countryCode.IS]: 'Islandia',
    [countryCode.IL]: 'Izrael',
    [countryCode.JM]: 'Jamajka',
    [countryCode.JP]: 'Japonia',
    [countryCode.YE]: 'Jemen',
    [countryCode.JO]: 'Jordania',
    [countryCode.KY]: 'Kajmany',
    [countryCode.KH]: 'Kambodża',
    [countryCode.CM]: 'Kamerun',
    [countryCode.CA]: 'Kanada',
    [countryCode.QA]: 'Katar',
    [countryCode.KE]: 'Kenia',
    [countryCode.KI]: 'Kiribati',
    [countryCode.CO]: 'Kolumbia',
    [countryCode.KM]: 'Komory',
    [countryCode.CG]: 'Kongo',
    [countryCode.KP]: 'Koreańska Republika Ludowo-Demokratyczna',
    [countryCode.XK]: 'Kosowo',
    [countryCode.CR]: 'Kostaryka',
    [countryCode.CU]: 'Kuba',
    [countryCode.KW]: 'Kuwejt',
    [countryCode.LA]: 'Laos',
    [countryCode.LS]: 'Lesotho',
    [countryCode.LB]: 'Liban',
    [countryCode.LR]: 'Liberia',
    [countryCode.LY]: 'Libia',
    [countryCode.LI]: 'Liechtenstein',
    [countryCode.LT]: 'Litwa',
    [countryCode.LU]: 'Luksemburg',
    [countryCode.LV]: 'Łotwa',
    [countryCode.MK]: 'Macedonia',
    [countryCode.MG]: 'Madagaskar',
    [countryCode.YT]: 'Majotta',
    [countryCode.MO]: 'Makau',
    [countryCode.MW]: 'Malawi',
    [countryCode.MV]: 'Malediwy',
    [countryCode.MY]: 'Malezja',
    [countryCode.ML]: 'Mali',
    [countryCode.MT]: 'Malta',
    [countryCode.MP]: 'Mariany Północne',
    [countryCode.MA]: 'Maroko',
    [countryCode.MR]: 'Mauretania',
    [countryCode.MU]: 'Mauritius',
    [countryCode.MX]: 'Meksyk',
    [countryCode.XL]: 'Melilla',
    [countryCode.FM]: 'Mikronezja',
    [countryCode.UM]: 'Minor (powiernicze wyspy pacyfiku stanów zjednoczonych)',
    [countryCode.MN]: 'Mongolia',
    [countryCode.MS]: 'Montserrat',
    [countryCode.MZ]: 'Mozambik',
    [countryCode.MM]: 'Myanmar (burma)',
    [countryCode.NA]: 'Namibia',
    [countryCode.NR]: 'Nauru',
    [countryCode.NP]: 'Nepal',
    [countryCode.NE]: 'Niger',
    [countryCode.NG]: 'Nigeria',
    [countryCode.NI]: 'Nikaragua',
    [countryCode.NU]: 'Niue',
    [countryCode.NF]: 'Norfolk',
    [countryCode.NO]: 'Norwegia',
    [countryCode.NC]: 'Nowa Kaledonia',
    [countryCode.NZ]: 'Nowa Zelandia',
    [countryCode.PS]: 'Okupowane Terytorium Palestyny',
    [countryCode.OM]: 'Oman',
    [countryCode.PK]: 'Pakistan',
    [countryCode.PW]: 'Palau',
    [countryCode.PA]: 'Panama',
    [countryCode.PG]: 'Papua Nowa Gwinea',
    [countryCode.PY]: 'Paragwaj',
    [countryCode.PE]: 'Peru',
    [countryCode.PN]: 'Pitcairn',
    [countryCode.PF]: 'Polinezja Francuska',
    [countryCode.GS]: 'Południowa Georgia i Południowe Wyspy Sandwich',
    [countryCode.PT]: 'Portugalia',
    [countryCode.KR]: 'Republika Korei',
    [countryCode.ZA]: 'Republika Południowej Afryki',
    [countryCode.RS]: 'Republika Serbii',
    [countryCode.CF]: 'Republika Środkowoafrykańska',
    [countryCode.RW]: 'Rwanda',
    [countryCode.EH]: 'Sahara Zachodnia',
    [countryCode.BL]: 'Saint Barthelemy',
    [countryCode.SV]: 'Salwador',
    [countryCode.WS]: 'Samoa',
    [countryCode.AS]: 'Samoa Amerykańskie',
    [countryCode.SM]: 'San Marino',
    [countryCode.SN]: 'Senegal',
    [countryCode.SL]: 'Sierra Leone',
    [countryCode.SG]: 'Singapur',
    [countryCode.SZ]: 'Suazi',
    [countryCode.SI]: 'Słowenia',
    [countryCode.SO]: 'Somalia',
    [countryCode.PM]: 'St. Pierre i MiQuelon',
    [countryCode.KN]: 'St. Kitts i Nevis',
    [countryCode.LC]: 'St. Lucia',
    [countryCode.VC]: 'St. Vincent i Grenadyny',
    [countryCode.SD]: 'Sudan',
    [countryCode.SS]: 'Sudan Południowy',
    [countryCode.SR]: 'Surinam',
    [countryCode.SY]: 'Syria',
    [countryCode.SE]: 'Szwecja',
    [countryCode.SH]: 'Święta Helena',
    [countryCode.TJ]: 'Tadżykistan',
    [countryCode.TH]: 'Tajlandia',
    [countryCode.TW]: 'Tajwan',
    [countryCode.TZ]: 'Tanzania',
    [countryCode.TG]: 'Togo',
    [countryCode.TK]: 'Tokelau',
    [countryCode.TO]: 'Tonga',
    [countryCode.TT]: 'Trynidad i Tobago',
    [countryCode.TN]: 'Tunezja',
    [countryCode.TR]: 'Turcja',
    [countryCode.TM]: 'Turkmenistan',
    [countryCode.TC]: 'Wyspy Turks i Caicos',
    [countryCode.TV]: 'Tuvalu',
    [countryCode.UG]: 'Uganda',
    [countryCode.UY]: 'Urugwaj',
    [countryCode.VU]: 'Vanuatu',
    [countryCode.WF]: 'Wallis i Futuna',
    [countryCode.VA]: 'Watykan',
    [countryCode.VE]: 'Wenezuela',
    [countryCode.HU]: 'Węgry',
    [countryCode.VN]: 'Wietnam',
    [countryCode.IT]: 'Włochy',
    [countryCode.TL]: 'Wschodni Timor',
    [countryCode.CI]: 'Wyb.Kości Słoniowej',
    [countryCode.BV]: 'Wyspa Bouveta',
    [countryCode.CX]: 'Wyspa Bożego Narodzenia',
    [countryCode.CK]: 'Wyspy Cooka',
    [countryCode.VI]: 'Wyspy Dziewicze-USA',
    [countryCode.VG]: 'Wyspy Dziewicze-w.b',
    [countryCode.HM]: 'Wyspy Heard i Mcdonald',
    [countryCode.CC]: 'Wyspy Kokosowe (keelinga)',
    [countryCode.FO]: 'Wyspy Owcze',
    [countryCode.MH]: 'Wyspy Marshalla',
    [countryCode.SB]: 'Wyspy Salomona',
    [countryCode.SX]: 'Wyspa Sint Maarten (część holenderska wyspy)',
    [countryCode.ST]: 'Wyspy Św.Tomasza i Książęca',
    [countryCode.ZM]: 'Zambia',
    [countryCode.CV]: 'Zielony Przylądek',
    [countryCode.ZW]: 'Zimbabwe',
    [countryCode.AE]: 'Zjednoczone Emiraty Arabskie',
});
