export const language = Object.freeze({
    PL: 'pl',
    EN: 'en',
    DE: 'de',
    RU: 'ru',
    FR: 'fr',
    UK: 'uk',
    ES: 'es',
    RO: 'ro',
    NB: 'nb',
});

export const languageMap = Object.freeze({
    [language.PL]: 'Polski',
    [language.EN]: 'English',
    [language.DE]: 'Deutsch',
    [language.RU]: 'Русский',
    [language.UK]: 'Українська',
    [language.FR]: 'Français',
    [language.ES]: 'Español',
    [language.RO]: 'Română',
    [language.NB]: 'Norsk',
});

export const languageTranslatedMap = Object.freeze({
    [language.PL]: 'polski',
    [language.EN]: 'angielski',
    [language.DE]: 'niemiecki',
    [language.RU]: 'rosyjski',
    [language.UK]: 'ukraiński',
    [language.FR]: 'francuski',
    [language.ES]: 'hiszpański',
    [language.RO]: 'rumuński',
    [language.NB]: 'norweski',
});
