import MainLayout from 'layouts/MainLayout.vue';
import { entityNew } from 'src/enums/entity';
import { permissionAttribute } from 'src/enums/permissions';
import { permissionsMiddleware } from 'src/router/middlewares/permissions-middleware';

export default [
    {
        path: 'employee-per-status-and-accountant',
        name: 'analysis-employee-per-status-and-accountant',
        component: () => import('pages/analysis/EmployeePerStatusAndAccountantPage.vue'),
        meta: {
            title: 'Liczba kandydatów i pracowników w statusie w podziale na opiekunów',
            layout: MainLayout,
            middleware: [
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.ANALYSIS),
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.USER),
            ],
        },
    },
    {
        path: 'employees-absence',
        name: 'analysis-employees-absence',
        component: () => import('pages/analysis/EmployeesAbsencePage.vue'),
        meta: {
            title: 'Ewidencja nieobecności',
            layout: MainLayout,
            middleware: [
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.ANALYSIS),
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.CALENDAR_EVENT),
            ],
        },
    },
    {
        path: 'vacation-limits-and-equivalent',
        name: 'analysis-vacation-limits-and-equivalent',
        component: () => import('pages/analysis/VacationLimitsAndEquivalent.vue'),
        meta: {
            title: 'Raport limitów i wykorzystania nieobecności oraz wypłaconych ekwiwalentów',
            layout: MainLayout,
            middleware: [
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.ANALYSIS),
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.CALENDAR_EVENT),
            ],
        },
    },
    {
        path: 'other-schema-rate-info-report',
        name: 'analysis-other-schema-rate-info-report',
        component: () => import('pages/analysis/OtherSchemaRateInfoReport.vue'),
        meta: {
            title: 'Raport elementów stawki niestandardowej',
            layout: MainLayout,
            middleware: [
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.ANALYSIS),
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.CALENDAR_EVENT),
            ],
        },
    },
    {
        path: 'working-hours-per-country/:id?',
        name: 'analysis-working-hours-per-country',
        component: () => import('pages/analysis/WorkingHoursPerCountryPage.vue'),
        meta: {
            title: 'Liczba przepracowanych dni i godzin w podziale na kraje',
            layout: MainLayout,
            middleware: [
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.ANALYSIS),
            ],
        },
    },
];
