import { empty, findInObject, isObject } from '@soulab/js-helpers';
import enums from 'components/FormBuilder/enums';

export function requiredMessage(fieldType) {
    if (fieldType === enums.FIELD_TYPE_CONSENT) {
        return 'Zgoda jest wymagana';
    }

    return 'To pole jest wymagane';
}

/**
 * Validator to check for non-emptiness for given field value
 */
export function requiredRule(value, validator, context) {
    if (empty(value, false)) {
        return context.rule.params?.message || requiredMessage(context.rule.params?.type);
    }

    /**
     * Not empty fields in object
     */
    if (validator && isObject(validator) && validator.structure) {
        for (let field in validator.structure) {
            if (empty(findInObject(value, field), false)) {
                return context.rule.params?.message || requiredMessage(context.rule.params?.type);
            }
        }
    }

    return true;
}
