<template>
    <slot></slot>
</template>

<script setup>
import { API_KEY, TRANS_KEY } from 'src/providers/symbols';
import { provide } from 'vue';
import { useQuasar } from 'quasar';
import { i18n, transEnum } from 'boot/i18n';
import { api, apiWithoutAuth, oldHrappka } from 'boot/axios';
import { quasarIconOverride } from 'src/helpers/icon-map';

const quasar = useQuasar();

/**
 * Globally available dependencies with inject(key)
 */
provide(TRANS_KEY, i18n.global.t);
provide(API_KEY, api);

/**
 * String injections should be replaced by symbol injections, because they are not type-safe
 * @deprecated
 */
provide('trans', i18n.global.t);
provide('transEnum', transEnum);
provide('api', api);
provide('apiWithoutAuth', apiWithoutAuth);
provide('oldHrappka', oldHrappka);

/**
 * Here we can map Quasar default icons to any icon we want
 */
quasar.iconMapFn = (iconName) => {
    if (Object.prototype.hasOwnProperty.call(quasarIconOverride, iconName)) {
        return quasarIconOverride[iconName];
    }
};
</script>
