import { entityTableId } from 'src/enums/entity';

export const dangerFactorPhysical = Object.freeze({
    MOVING_MACHINES_AND_MECHANISMS: 'DANGER_PHYSICAL_FACTOR_MOVING_MACHINES_AND_MECHANISMS',
    MOVING_PARTS_OF_TECHNICAL_DEVICES: 'DANGER_PHYSICAL_FACTOR_MOVING_PARTS_OF_TECHNICAL_DEVICES',
    MOVING_PRODUCTS_SEMI_FINISHED_PRODUCTS_AND_MATERIALS: 'DANGER_PHYSICAL_FACTOR_MOVING_PRODUCTS_SEMI_FINISHED_PRODUCTS_AND_MATERIALS',
    CONSTRUCTION_VIOLATIONS: 'DANGER_PHYSICAL_FACTOR_CONSTRUCTION_VIOLATIONS',
    TEARING_OFF_ROCKS: 'DANGER_PHYSICAL_FACTOR_TEARING_OFF_ROCKS',
    SURFACES_WHERE_WORKER_CAN_FALL_DOWN: 'DANGER_PHYSICAL_FACTOR_SURFACES_WHERE_WORKER_CAN_FALL_DOWN',
    BLADES: 'DANGER_PHYSICAL_FACTOR_BLADES',
    SURFACE_TEMPERATURE_OF_EQUIPMENT_AND_MATERIALS: 'DANGER_PHYSICAL_FACTOR_SURFACE_TEMPERATURE_OF_EQUIPMENT_AND_MATERIALS',
    WORK_IN_RELATION_TO_GROUND_OR_FLOOR: 'DANGER_PHYSICAL_FACTOR_WORK_IN_RELATION_TO_GROUND_OR_FLOOR',
    WEIGHTLESSNESS: 'DANGER_PHYSICAL_FACTOR_WEIGHTLESSNESS',
    PRESSURE: 'DANGER_PHYSICAL_FACTOR_PRESSURE',
    NOISE: 'DANGER_PHYSICAL_FACTOR_NOISE',
    VIBRATION: 'DANGER_PHYSICAL_FACTOR_VIBRATION',
    INFRASOUNDS: 'DANGER_PHYSICAL_FACTOR_INFRASOUNDS',
    ULTRASOUNDS: 'DANGER_PHYSICAL_FACTOR_ULTRASOUNDS',
    MICROCLIMATE_HOT: 'DANGER_PHYSICAL_FACTOR_MICROCLIMATE_HOT',
    MICROCLIMATE_COLD: 'DANGER_PHYSICAL_FACTOR_MICROCLIMATE_COLD',
    AIR_IONIZATION: 'DANGER_PHYSICAL_FACTOR_AIR_IONIZATION',
    RADIATION_IONIZING: 'DANGER_PHYSICAL_FACTOR_RADIATION_IONIZING',
    RADIATION_LASER: 'DANGER_PHYSICAL_FACTOR_RADIATION_LASER',
    RADIATION_ULTRAVIOLET: 'DANGER_PHYSICAL_FACTOR_RADIATION_ULTRAVIOLET',
    RADIATION_INFRARED: 'DANGER_PHYSICAL_FACTOR_RADIATION_INFRARED',
    RADIATION_VISIBLE: 'DANGER_PHYSICAL_FACTOR_RADIATION_VISIBLE',
    ELECTROMAGNETIC_FIELD: 'DANGER_PHYSICAL_FACTOR_ELECTROMAGNETIC_FIELD',
    ELECTROSTATIC_FIELD: 'DANGER_PHYSICAL_FACTOR_ELECTROSTATIC_FIELD',
    STATIC_ELECTRICITY: 'DANGER_PHYSICAL_FACTOR_STATIC_ELECTRICITY',
    ELECTRIC_CURRENT: 'DANGER_PHYSICAL_FACTOR_ELECTRIC_CURRENT',
});
export const dangerFactorPhysicalMap = Object.freeze({
    [dangerFactorPhysical.MOVING_MACHINES_AND_MECHANISMS]: 'Poruszające się maszyny i mechanizmy',
    [dangerFactorPhysical.MOVING_PARTS_OF_TECHNICAL_DEVICES]: 'Ruchome elementy urządzeń technicznych',
    [dangerFactorPhysical.MOVING_PRODUCTS_SEMI_FINISHED_PRODUCTS_AND_MATERIALS]: 'Przemieszczające się wyroby, półwyroby i materiały',
    [dangerFactorPhysical.CONSTRUCTION_VIOLATIONS]: 'Naruszanie konstrukcji',
    [dangerFactorPhysical.TEARING_OFF_ROCKS]: 'Obrywanie się mas i brył skalnych ze stropu lub ociosu, tąpnięcia',
    [dangerFactorPhysical.SURFACES_WHERE_WORKER_CAN_FALL_DOWN]: 'Powierzchnie, na których jest możliwy upadek pracujących',
    [dangerFactorPhysical.BLADES]: 'Ostrza, ostre krawędzie, wystające elementy, chropowatość i szerokość wyrobów, urządzeń i narzędzi',
    [dangerFactorPhysical.SURFACE_TEMPERATURE_OF_EQUIPMENT_AND_MATERIALS]: 'Temperatura powierzchni wyposażenia technicznego i materiałów',
    [dangerFactorPhysical.WORK_IN_RELATION_TO_GROUND_OR_FLOOR]:
        'Położenie stanowisk pracy w stosunku do powierzchni ziemi lub podłogi pomieszczenia',
    [dangerFactorPhysical.WEIGHTLESSNESS]: 'Nieważkość',
    [dangerFactorPhysical.PRESSURE]: 'Ciśnienie',
    [dangerFactorPhysical.NOISE]: 'Hałas',
    [dangerFactorPhysical.VIBRATION]: 'Wibracja',
    [dangerFactorPhysical.INFRASOUNDS]: 'Infradźwięki',
    [dangerFactorPhysical.ULTRASOUNDS]: 'Ultradźwięki',
    [dangerFactorPhysical.MICROCLIMATE_HOT]: 'Mikroklimat gorący',
    [dangerFactorPhysical.MICROCLIMATE_COLD]: 'Mikroklimat zimny',
    [dangerFactorPhysical.AIR_IONIZATION]: 'Jonizacja powietrza',
    [dangerFactorPhysical.RADIATION_IONIZING]: 'Promieniowanie jonizujące',
    [dangerFactorPhysical.RADIATION_LASER]: 'Promieniowanie laserowe',
    [dangerFactorPhysical.RADIATION_ULTRAVIOLET]: 'Promieniowanie nadfioletowe',
    [dangerFactorPhysical.RADIATION_INFRARED]: 'Promieniowanie podczerwone',
    [dangerFactorPhysical.RADIATION_VISIBLE]: 'Promieniowanie widzialne',
    [dangerFactorPhysical.ELECTROMAGNETIC_FIELD]: 'Pole elektromagnetyczne',
    [dangerFactorPhysical.ELECTROSTATIC_FIELD]: 'Pole elektrostatyczne',
    [dangerFactorPhysical.STATIC_ELECTRICITY]: 'Elektryczność statyczna',
    [dangerFactorPhysical.ELECTRIC_CURRENT]: 'Prąd elektryczny',
    [dangerFactorPhysical.LARGE_INFLOW_OF_INFORMATION]:
        'Niekorzystne czynniki psychospołeczne: zagrożenia wynikające ze stałego dużego dopływu informacji i gotowości do odpowiedzi',
    [dangerFactorPhysical.RESPONSIBLE_DECISIONS]:
        'Niekorzystne czynniki psychospołeczne: zagrożenia wynikające z pracy na stanowiskach decyzyjnych i związanych z odpowiedzialnością',
    [dangerFactorPhysical.WORK_MONITOR_MORE_4_HOURS]: 'Praca przy monitorze ekranowym powyżej 4 godzin',
    [dangerFactorPhysical.WORK_MONITOR_LESS_4_HOURS]: 'Praca przy monitorze ekranowym poniżej 4 godzin',
    [dangerFactorPhysical.DRIVE_COMPANY_CAR_CAT_B]: 'Kierowanie pojazdem służbowym kat. B',
    [dangerFactorPhysical.MANUAL_TRANSPORT_WORK]: 'Ręczne prace transportowe',
    [dangerFactorPhysical.PHYSICAL_WORK_WITH_ENERGY_EXPENDITURE]:
        'Praca fizyczna z wydatkiem energetycznym na pracę powyżej 1500 kcal (8 godzin lub 3kcal/min) dla mężczyzn i powyżej 1000 kcal (8 godzin lub ponad 2 kcal/min) dla kobiet',
    [dangerFactorPhysical.DRIVE_TRUCK]: 'Obsługa wózka jezdniowego',
    [dangerFactorPhysical.DRIVE_TROLLEY_TRUCK]: 'Obsługa wózka jezdniowego unoszącego',
    [dangerFactorPhysical.SCISSOR_LIFT]: 'Obsługa podnośnika nożycowego',
    [dangerFactorPhysical.DRIVE_FORKLIFT_TRUCK]: 'Obsługa wózka widłowego',
    [dangerFactorPhysical.WORK_AT_HEIGHT]: 'Praca na wysokości',
    [dangerFactorPhysical.WORK_AT_NIGHT]: 'Praca zmianowa/nocna',
});

export const dangerFactorIndustrial = Object.freeze({
    FIBROSIS_DUST: 'DANGER_FACTOR_INDUSTRIAL_FIBROSIS_DUST',
    CARCINOGENIC_DUST: 'DANGER_FACTOR_INDUSTRIAL_CARCINOGENIC_DUST',
    OTHER_DUST: 'DANGER_FACTOR_INDUSTRIAL_OTHER_DUST',
});
export const dangerFactorIndustrialMap = Object.freeze({
    [dangerFactorIndustrial.FIBROSIS_DUST]: 'Przemysłowe pyły zwłókniające',
    [dangerFactorIndustrial.CARCINOGENIC_DUST]: 'Przemysłowe pyły rakotwórcze',
    [dangerFactorIndustrial.OTHER_DUST]: 'Inne pyły przemysłowe',
});

export const dangerFactorChemical = Object.freeze({
    TOXIC: 'DANGER_FACTOR_CHEMICAL_TOXIC',
    VERY_TOXIC: 'DANGER_FACTOR_CHEMICAL_VERY_TOXIC',
    IRRITANTS: 'DANGER_FACTOR_CHEMICAL_IRRITANTS',
    SENSITIZING: 'DANGER_FACTOR_CHEMICAL_SENSITIZING',
    CARCINOGENIC: 'DANGER_FACTOR_CHEMICAL_CARCINOGENIC',
    MUTAGENIC: 'DANGER_FACTOR_CHEMICAL_MUTAGENIC',
    IMPAIRING_REPRODUCTIVE_FUNCTIONS: 'DANGER_FACTOR_CHEMICAL_IMPAIRING_REPRODUCTIVE_FUNCTIONS',
    HARMFUL: 'DANGER_FACTOR_CHEMICAL_HARMFUL',
    EXPLOSIVE: 'DANGER_FACTOR_CHEMICAL_EXPLOSIVE',
    FLAMMABLE: 'DANGER_FACTOR_CHEMICAL_FLAMMABLE',
    CORROSIVE: 'DANGER_FACTOR_CHEMICAL_CORROSIVE',
});
export const dangerFactorChemicalMap = Object.freeze({
    [dangerFactorChemical.TOXIC]: 'Toksyczne',
    [dangerFactorChemical.VERY_TOXIC]: 'Bardzo toksyczne',
    [dangerFactorChemical.IRRITANTS]: 'Drażniące',
    [dangerFactorChemical.SENSITIZING]: 'Uczulające',
    [dangerFactorChemical.CARCINOGENIC]: 'Rakotwórcze',
    [dangerFactorChemical.MUTAGENIC]: 'Mutagenne',
    [dangerFactorChemical.IMPAIRING_REPRODUCTIVE_FUNCTIONS]: 'Upośledzające funkcje rozrodcze',
    [dangerFactorChemical.HARMFUL]: 'Szkodliwe',
    [dangerFactorChemical.EXPLOSIVE]: 'Wybuchowe',
    [dangerFactorChemical.FLAMMABLE]: 'Łatwopalne',
    [dangerFactorChemical.CORROSIVE]: 'Żrące',
});

export const dangerFactorBiological = Object.freeze({
    HBV: 'DANGER_FACTOR_BIOLOGICAL_HBV',
    HCV: 'DANGER_FACTOR_BIOLOGICAL_HCV',
    HIV: 'DANGER_FACTOR_BIOLOGICAL_HIV',
    BRUCELLA: 'DANGER_FACTOR_BIOLOGICAL_BRUCELLA',
    MILDEW: 'DANGER_FACTOR_BIOLOGICAL_MILDEW',
    TUBERCULOSIS: 'DANGER_FACTOR_BIOLOGICAL_TUBERCULOSIS',
    BRAIN_TICK_VIRUS: 'DANGER_FACTOR_BIOLOGICAL_BRAIN_TICK_VIRUS',
    BORRELIA_BURGDORFER: 'DANGER_FACTOR_BIOLOGICAL_BORRELIA_BURGDORFER',
});
export const dangerFactorBiologicalMap = Object.freeze({
    [dangerFactorBiological.HBV]: 'Wirus zapalenia wątroby typ B (HBV)',
    [dangerFactorBiological.HCV]: 'Wirus zapalenia wątroby typ C (HCV)',
    [dangerFactorBiological.HIV]: 'Ludzki wirus niedoboru odporności nabytej (HIV)',
    [dangerFactorBiological.BRUCELLA]: 'Pałeczki Brucella abortus bovis',
    [dangerFactorBiological.MILDEW]: 'Promieniowce termofilne, grzyby pleśniowe i inne o działaniu uczulającym',
    [dangerFactorBiological.TUBERCULOSIS]: 'Prątek gruźlicy',
    [dangerFactorBiological.BRAIN_TICK_VIRUS]: 'Wirus kleszczowego zapalenia mózgu',
    [dangerFactorBiological.BORRELIA_BURGDORFER]: 'Borrelia burgdorfe',
});

export const dangerFactorOther = Object.freeze({
    FORCED_BODY_POSITION: 'DANGER_FACTOR_OTHER_FORCED_BODY_POSITION',
    HEAVY_PHYSICAL_EFFORT: 'DANGER_FACTOR_OTHER_HEAVY_PHYSICAL_EFFORT',
    REPETITIVE_WORK: 'DANGER_FACTOR_OTHER_REPETITIVE_WORK',
    LIGHTING: 'DANGER_PHYSICAL_FACTOR_LIGHTING', // moved from dangerFactorPhysical
    LARGE_INFLOW_OF_INFORMATION: 'DANGER_PHYSICAL_FACTOR_LARGE_INFLOW_OF_INFORMATION',
    RESPONSIBLE_DECISIONS: 'DANGER_PHYSICAL_FACTOR_RESPONSIBLE_DECISIONS',
    WORK_MONITOR_MORE_4_HOURS: 'DANGER_PHYSICAL_FACTOR_WORK_MONITOR_MORE_4_HOURS',
    WORK_MONITOR_LESS_4_HOURS: 'DANGER_PHYSICAL_FACTOR_WORK_MONITOR_LESS_4_HOURS',
    DRIVE_COMPANY_CAR_CAT_B: 'DANGER_PHYSICAL_FACTOR_DRIVE_COMPANY_CAR_CAT_B',
    MANUAL_TRANSPORT_WORK: 'DANGER_PHYSICAL_FACTOR_MANUAL_TRANSPORT_WORK',
    PHYSICAL_WORK_WITH_ENERGY_EXPENDITURE: 'DANGER_PHYSICAL_FACTOR_PHYSICAL_WORK_WITH_ENERGY_EXPENDITURE',
    DRIVE_TRUCK: 'DANGER_PHYSICAL_FACTOR_DRIVE_TRUCK',
    DRIVE_TROLLEY_TRUCK: 'DANGER_PHYSICAL_FACTOR_DRIVE_TROLLEY_TRUCK',
    SCISSOR_LIFT: 'DANGER_PHYSICAL_FACTOR_SCISSOR_LIFT',
    DRIVE_FORKLIFT_TRUCK: 'DANGER_PHYSICAL_FACTOR_DRIVE_FORKLIFT_TRUCK',
    WORK_AT_HEIGHT: 'DANGER_PHYSICAL_FACTOR_WORK_AT_HEIGHT',
    WORK_AT_NIGHT: 'DANGER_PHYSICAL_FACTOR_WORK_AT_NIGHT',
});
export const dangerFactorOtherMap = Object.freeze({
    [dangerFactorOther.FORCED_BODY_POSITION]: 'Wymuszona pozycja ciała',
    [dangerFactorOther.HEAVY_PHYSICAL_EFFORT]: 'Ciężki wysiłek fizyczny',
    [dangerFactorOther.REPETITIVE_WORK]: 'Monotypia',
    [dangerFactorOther.LIGHTING]: 'Oświetlenie (natężenie, luminacja, ciśnienie, kontrast, tętnienie strumienia)',
    [dangerFactorOther.LARGE_INFLOW_OF_INFORMATION]:
        'Niekorzystne czynniki psychospołeczne: zagrożenia wynikające ze stałego dużego dopływu informacji i gotowości do odpowiedzi',
    [dangerFactorOther.RESPONSIBLE_DECISIONS]:
        'Niekorzystne czynniki psychospołeczne: zagrożenia wynikające z pracy na stanowiskach decyzyjnych i związanych z odpowiedzialnością',
    [dangerFactorOther.WORK_MONITOR_MORE_4_HOURS]: 'Praca przy monitorze ekranowym powyżej 4 godzin',
    [dangerFactorOther.WORK_MONITOR_LESS_4_HOURS]: 'Praca przy monitorze ekranowym poniżej 4 godzin',
    [dangerFactorOther.DRIVE_COMPANY_CAR_CAT_B]: 'Kierowanie pojazdem służbowym kat. B',
    [dangerFactorOther.MANUAL_TRANSPORT_WORK]: 'Ręczne prace transportowe',
    [dangerFactorOther.PHYSICAL_WORK_WITH_ENERGY_EXPENDITURE]:
        'Praca fizyczna z wydatkiem energetycznym na pracę powyżej 1500 kcal (8 godzin lub 3kcal/min) dla mężczyzn i powyżej 1000 kcal (8 godzin lub ponad 2 kcal/min) dla kobiet',
    [dangerFactorOther.DRIVE_TRUCK]: 'Obsługa wózka jezdniowego',
    [dangerFactorOther.DRIVE_TROLLEY_TRUCK]: 'Obsługa wózka jezdniowego unoszącego',
    [dangerFactorOther.SCISSOR_LIFT]: 'Obsługa podnośnika nożycowego',
    [dangerFactorOther.DRIVE_FORKLIFT_TRUCK]: 'Obsługa wózka widłowego',
    [dangerFactorOther.WORK_AT_HEIGHT]: 'Praca na wysokości',
    [dangerFactorOther.WORK_AT_NIGHT]: 'Praca zmianowa/nocna',
});

export const userPermitType = Object.freeze({
    MEDICAL_EXAMINATION: 'MEDICAL_EXAMINATION',
    WIZA: 'WIZA',
    STATEMENT: 'STATEMENT',
    EMPLOYEER_STATEMENT: 'EMPLOYEER_STATEMENT',
    RESIDENCE_CARD: 'RESIDENCE_CARD',
    POLISH_RELATIONS_CARD: 'POLISH_RELATIONS_CARD',
    PERMIT_APPLICATION: 'PERMIT_APPLICATION',
    BACK_STATEMENT: 'BACK_STATEMENT',
    END_STATEMENT: 'END_STATEMENT',
    CONTINUATION_WORK_APPLICATION: 'CONTINUATION_WORK_APPLICATION',
    SEASONAL_WORK_APPLICATION: 'SEASONAL_WORK_APPLICATION',
    PERMISSION_PERMANENT_RESIDENCE: 'PERMISSION_PERMANENT_RESIDENCE',
    PERMISSION_TEMPORARY_RESIDENCE: 'PERMISSION_TEMPORARY_RESIDENCE',
    PERMIT_PERMANENT_RESIDENCE_APPLICATION: 'PERMIT_PERMANENT_RESIDENCE_APPLICATION',
    PERMIT_TEMPORARY_RESIDENCE_APPLICATION: 'PERMIT_TEMPORARY_RESIDENCE_APPLICATION',
    PERMIT_TEMPORARY_RESIDENCE_APPLICATION_ATTACHMENT: 'PERMIT_TEMPORARY_RESIDENCE_APPLICATION_ATTACHMENT',
    PERMISSION_PERMANENT_RESIDENCE_EU: 'PERMISSION_PERMANENT_RESIDENCE_EU',
    PERMIT_BACK_STATEMENT: 'PERMIT_BACK_STATEMENT',
    PERMISSION_TYPE_A: 'PERMISSION_TYPE_A',
    PERMISSION_TYPE_B: 'PERMISSION_TYPE_B',
    PERMISSION_TYPE_C: 'PERMISSION_TYPE_C',
    PERMISSION_TYPE_D: 'PERMISSION_TYPE_D',
    PERMISSION_TYPE_E: 'PERMISSION_TYPE_E',
    PERMISSION_TYPE_S: 'PERMISSION_TYPE_S',
    PERMISSION_CONTINUATION_TYPE_A: 'DOCUMENT_TYPE_PERMISSION_CONTINUATION_TYPE_A',
    PERMISSION_CONTINUATION_TYPE_B: 'DOCUMENT_TYPE_PERMISSION_CONTINUATION_TYPE_B',
    PERMISSION_CONTINUATION_TYPE_C: 'DOCUMENT_TYPE_PERMISSION_CONTINUATION_TYPE_C',
    PERMISSION_CONTINUATION_TYPE_D: 'DOCUMENT_TYPE_PERMISSION_CONTINUATION_TYPE_D',
    PERMISSION_CONTINUATION_TYPE_E: 'DOCUMENT_TYPE_PERMISSION_CONTINUATION_TYPE_E',
    OTHER_LIVING_TITLE: 'OTHER_LIVING_TITLE',
    OTHER_WORK_TITLE: 'OTHER_WORK_TITLE',
    SAFETY_TRAINING: 'SAFETY_TRAINING',
    SANEPID: 'SANEPID',
    REGISTRATION: 'REGISTRATION',
    PARENTAL_CONSENT: 'PARENTAL_CONSENT',
    OTHER: 'OTHER',
    A1: 'A1',
    CPL: 'CPL',
    EKUZ: 'EKUZ',
    RESIDENCE_STATEMENT: 'RESIDENCE_STATEMENT',
    BIOMETRIC_PASSPORT: 'BIOMETRIC_PASSPORT',
    PESEL: 'PESEL',
    UE_CITIZEN_REGISTRATION: 'UE_CITIZEN_REGISTRATION',
    PERMIT_UKRAINIAN_SPECIAL_RULES: 'PERMIT_UKRAINIAN_SPECIAL_RULES',
    PERMIT_UKRAINIAN_SPECIAL_RULES_INFORMATION: 'PERMIT_UKRAINIAN_SPECIAL_RULES_INFORMATION',
    W_2E: 'W-2E',
});

export const userPermitTypeMap = Object.freeze({
    [userPermitType.MEDICAL_EXAMINATION]: 'Badania lekarskie',
    [userPermitType.WIZA]: 'Wiza',
    [userPermitType.STATEMENT]: 'Oświadczenie o powierzeniu wykonywania pracy cudzoziemcowi',
    [userPermitType.EMPLOYEER_STATEMENT]:
        'Oświadczenie podmiotu powierzającego wykonywanie pracy cudzoziemcowi o zgłoszeniu się cudzoziemca w celu wykonywania pracy sezonowej',
    [userPermitType.RESIDENCE_CARD]: 'Karta pobytu',
    [userPermitType.POLISH_RELATIONS_CARD]: 'Karta Polaka',
    [userPermitType.PERMIT_APPLICATION]: 'Wniosek o wydanie zezwolenia na pracę',
    [userPermitType.BACK_STATEMENT]:
        'Powiadomienie o podjęciu / niepodjęciu pracy przez cudzoziemca na podstawie oświadczenia wpisanego do ewidencji oświadczeń',
    [userPermitType.END_STATEMENT]:
        'Powiadomienie o zakończeniu pracy cudzoziemca na podstawie oświadczenia wpisanego do ewidencji oświadczeń',
    [userPermitType.CONTINUATION_WORK_APPLICATION]: 'Wniosek o przedłużenie zezwolenia na pracę',
    [userPermitType.SEASONAL_WORK_APPLICATION]: 'Wniosek o wydanie zezwolenia na pracę sezonową cudzoziemca',
    [userPermitType.PERMISSION_PERMANENT_RESIDENCE]: 'Zezwolenie na pobyt stały',
    [userPermitType.PERMISSION_TEMPORARY_RESIDENCE]: 'Zezwolenie na pobyt czasowy',
    [userPermitType.PERMIT_PERMANENT_RESIDENCE_APPLICATION]: 'Wniosek o udzielenie zezwolenia na pobyt stały',
    [userPermitType.PERMIT_TEMPORARY_RESIDENCE_APPLICATION]: 'Wniosek o udzielenie zezwolenia na pobyt czasowy',
    [userPermitType.PERMIT_TEMPORARY_RESIDENCE_APPLICATION_ATTACHMENT]:
        'Załącznik nr 1 do wniosku o udzielenie zezwolenia na pobyt czasowy',
    [userPermitType.PERMISSION_PERMANENT_RESIDENCE_EU]: 'Zezwolenie na pobyt rezydenta długoterminowego UE',
    [userPermitType.PERMIT_BACK_STATEMENT]:
        'Informacja podmiotu na temat zmian w zakresie wykonywania pracy na podstawie zezwolenia na pracę',
    [userPermitType.PERMISSION_TYPE_A]: 'Zezwolenie na pracę typu A',
    [userPermitType.PERMISSION_TYPE_B]: 'Zezwolenie na pracę typu B',
    [userPermitType.PERMISSION_TYPE_C]: 'Zezwolenie na pracę typu C',
    [userPermitType.PERMISSION_TYPE_D]: 'Zezwolenie na pracę typu D',
    [userPermitType.PERMISSION_TYPE_E]: 'Zezwolenie na pracę typu E',
    [userPermitType.PERMISSION_TYPE_S]: 'Zezwolenie na pracę typu S',
    [userPermitType.PERMISSION_CONTINUATION_TYPE_A]: 'Przedłużenie zezwolenia na pracę typu A',
    [userPermitType.PERMISSION_CONTINUATION_TYPE_B]: 'Przedłużenie zezwolenia na pracę typu B',
    [userPermitType.PERMISSION_CONTINUATION_TYPE_C]: 'Przedłużenie zezwolenia na pracę typu C',
    [userPermitType.PERMISSION_CONTINUATION_TYPE_D]: 'Przedłużenie zezwolenia na pracę typu D',
    [userPermitType.PERMISSION_CONTINUATION_TYPE_E]: 'Przedłużenie zezwolenia na pracę typu E',
    [userPermitType.OTHER_LIVING_TITLE]: 'Inny tytuł pobytowy',
    [userPermitType.OTHER_WORK_TITLE]: 'Inny tytuł do pracy',
    [userPermitType.SAFETY_TRAINING]: 'Szkolenie BHP',
    [userPermitType.SANEPID]: 'Książeczka SANEPID',
    [userPermitType.REGISTRATION]: 'Meldunek',
    [userPermitType.PARENTAL_CONSENT]: 'Zgoda rodziców',
    [userPermitType.OTHER]: 'Inne',
    [userPermitType.A1]: 'A1',
    [userPermitType.CPL]: 'Zaświadczenia o ustawodawstwie właściwym',
    [userPermitType.EKUZ]: 'Europejska karta ubezpieczenia zdrowotnego',
    [userPermitType.RESIDENCE_STATEMENT]: 'Oświadczenie o miejscu zamieszkania',
    [userPermitType.BIOMETRIC_PASSPORT]: 'Paszport biometryczny',
    [userPermitType.PESEL]: 'Wniosek o nadanie numeru PESEL',
    [userPermitType.UE_CITIZEN_REGISTRATION]: 'Rejestracja pobytu obywatela Unii Europejskiej',
    [userPermitType.PERMIT_UKRAINIAN_SPECIAL_RULES]: 'Specustawa o pomocy Ukraińcom z dnia 12.03.2022',
    [userPermitType.PERMIT_UKRAINIAN_SPECIAL_RULES_INFORMATION]: 'Powiadomienie o powierzeniu pracy obywatelowi Ukrainy',
    [userPermitType.W_2E]: 'Wniosek o przyznanie prawa wykonywania zawodu lekarza/lekarza dentysty (W-2e)',
});

export const userPermitCategory = Object.freeze({
    GENERAL: 'GENERAL',
    FOREIGNERS: 'FOREIGNERS',
    DELEGATION: 'DELEGATION',
    SPECIALIZED: 'SPECIALIZED',
});

export const userPermitCategoryMap = Object.freeze({
    [userPermitCategory.GENERAL]: 'Podstawowe',
    [userPermitCategory.FOREIGNERS]: 'Legalizacja',
    [userPermitCategory.DELEGATION]: 'Delegowanie',
    [userPermitCategory.SPECIALIZED]: 'Specjalistyczne',
});

export const userPermitCategoryTableIdMap = Object.freeze({
    [userPermitCategory.GENERAL]: entityTableId.USER_PERMIT_GENERAL,
    [userPermitCategory.FOREIGNERS]: entityTableId.USER_PERMIT_FOREIGNERS,
    [userPermitCategory.DELEGATION]: entityTableId.USER_PERMIT_DELEGATION,
    [userPermitCategory.SPECIALIZED]: entityTableId.USER_PERMIT_SPECIALIZED,
});

export const userPermitCategoryTypeMap = Object.freeze({
    [userPermitCategory.GENERAL]: [
        userPermitType.MEDICAL_EXAMINATION,
        userPermitType.SAFETY_TRAINING,
        userPermitType.SANEPID,
        userPermitType.REGISTRATION,
        userPermitType.PARENTAL_CONSENT,
        userPermitType.PESEL,
        userPermitType.OTHER,
    ],
    [userPermitCategory.FOREIGNERS]: [
        userPermitType.BIOMETRIC_PASSPORT,
        userPermitType.BACK_STATEMENT,
        userPermitType.END_STATEMENT,
        userPermitType.PERMIT_BACK_STATEMENT,
        userPermitType.STATEMENT,
        userPermitType.UE_CITIZEN_REGISTRATION,
        userPermitType.EMPLOYEER_STATEMENT,
        userPermitType.CONTINUATION_WORK_APPLICATION,
        userPermitType.RESIDENCE_CARD,
        userPermitType.POLISH_RELATIONS_CARD,
        userPermitType.PERMIT_APPLICATION,
        userPermitType.SEASONAL_WORK_APPLICATION,
        userPermitType.PERMIT_PERMANENT_RESIDENCE_APPLICATION,
        userPermitType.PERMISSION_PERMANENT_RESIDENCE,
        userPermitType.PERMIT_TEMPORARY_RESIDENCE_APPLICATION,
        userPermitType.PERMIT_TEMPORARY_RESIDENCE_APPLICATION_ATTACHMENT,
        userPermitType.PERMISSION_TEMPORARY_RESIDENCE,
        userPermitType.PERMISSION_PERMANENT_RESIDENCE_EU,
        userPermitType.PERMISSION_TYPE_A,
        userPermitType.PERMISSION_TYPE_B,
        userPermitType.PERMISSION_TYPE_C,
        userPermitType.PERMISSION_TYPE_D,
        userPermitType.PERMISSION_TYPE_E,
        userPermitType.PERMISSION_TYPE_S,
        userPermitType.PERMISSION_CONTINUATION_TYPE_A,
        userPermitType.PERMISSION_CONTINUATION_TYPE_B,
        userPermitType.PERMISSION_CONTINUATION_TYPE_C,
        userPermitType.PERMISSION_CONTINUATION_TYPE_D,
        userPermitType.PERMISSION_CONTINUATION_TYPE_E,
        userPermitType.WIZA,
        userPermitType.PERMIT_UKRAINIAN_SPECIAL_RULES,
        userPermitType.PERMIT_UKRAINIAN_SPECIAL_RULES_INFORMATION,
        userPermitType.OTHER_LIVING_TITLE,
        userPermitType.OTHER_WORK_TITLE,
    ],
    [userPermitCategory.DELEGATION]: [userPermitType.A1, userPermitType.CPL, userPermitType.EKUZ, userPermitType.RESIDENCE_STATEMENT],
    [userPermitCategory.SPECIALIZED]: [userPermitType.W_2E],
});

export const userPermitForeignersCategory = Object.freeze({
    BOTH: 'BOTH',
    WORK: 'WORK',
    LIVE: 'LIVE',
});

export const userPermitForeignersCategoryMap = Object.freeze({
    [userPermitForeignersCategory.BOTH]: 'Łączne',
    [userPermitForeignersCategory.WORK]: 'Praca',
    [userPermitForeignersCategory.LIVE]: 'Pobyt',
});

export const userPermitForeignersCategoryTypeMap = Object.freeze({
    [userPermitForeignersCategory.LIVE]: [
        userPermitType.BIOMETRIC_PASSPORT,
        userPermitType.WIZA,
        userPermitType.UE_CITIZEN_REGISTRATION,
        userPermitType.OTHER_LIVING_TITLE,
    ],
    [userPermitForeignersCategory.WORK]: [
        userPermitType.POLISH_RELATIONS_CARD,
        userPermitType.STATEMENT,
        userPermitType.EMPLOYEER_STATEMENT,
        userPermitType.PERMISSION_TYPE_A,
        userPermitType.PERMISSION_TYPE_B,
        userPermitType.PERMISSION_TYPE_C,
        userPermitType.PERMISSION_TYPE_D,
        userPermitType.PERMISSION_TYPE_E,
        userPermitType.PERMISSION_TYPE_S,
        userPermitType.PERMISSION_CONTINUATION_TYPE_A,
        userPermitType.PERMISSION_CONTINUATION_TYPE_B,
        userPermitType.PERMISSION_CONTINUATION_TYPE_C,
        userPermitType.PERMISSION_CONTINUATION_TYPE_D,
        userPermitType.PERMISSION_CONTINUATION_TYPE_E,
        userPermitType.OTHER_WORK_TITLE,
        userPermitType.PERMIT_TEMPORARY_RESIDENCE_APPLICATION_ATTACHMENT,
        userPermitType.PERMIT_UKRAINIAN_SPECIAL_RULES_INFORMATION,
    ],
    [userPermitForeignersCategory.BOTH]: [
        userPermitType.RESIDENCE_CARD,
        userPermitType.PERMISSION_TEMPORARY_RESIDENCE,
        userPermitType.PERMISSION_PERMANENT_RESIDENCE,
        userPermitType.PERMISSION_PERMANENT_RESIDENCE_EU,
        userPermitType.PERMIT_UKRAINIAN_SPECIAL_RULES,
    ],
});

export const userPermitPermissionTypes = Object.freeze([
    userPermitType.PERMISSION_TYPE_A,
    userPermitType.PERMISSION_TYPE_B,
    userPermitType.PERMISSION_TYPE_C,
    userPermitType.PERMISSION_TYPE_D,
    userPermitType.PERMISSION_TYPE_E,
    userPermitType.PERMISSION_TYPE_S,
    userPermitType.PERMISSION_PERMANENT_RESIDENCE,
    userPermitType.PERMISSION_TEMPORARY_RESIDENCE,
    userPermitType.PERMISSION_CONTINUATION_TYPE_A,
    userPermitType.PERMISSION_CONTINUATION_TYPE_B,
    userPermitType.PERMISSION_CONTINUATION_TYPE_C,
    userPermitType.PERMISSION_CONTINUATION_TYPE_D,
    userPermitType.PERMISSION_CONTINUATION_TYPE_E,
]);
