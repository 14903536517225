import { CompanyState } from 'src/types/entities/companyState';
import { empty, isArray } from '@soulab/js-helpers';
import { useAuthStore } from 'stores/auth';
import { PermissionEntities, PermissionStates } from 'src/enums/state';
import { usePermissions } from '@soulab/vue-permissions';
import { permissionAttribute } from 'src/enums/permissions';
import { entityToEntityNewMap } from 'src/enums/entity';
import { Optional } from 'src/types/general';

export function useStates(companyStates?: Record<string, CompanyState[]>) {
    const authStore = useAuthStore();
    const permissions = usePermissions();

    companyStates ??= authStore.getCompanyStates();

    const getStatesForEntity = (entityType: string): CompanyState[] => {
        return companyStates[entityType] ? companyStates[entityType] : [];
    };

    const getMappedStatesForEntity = (entityType: string): CompanyState[] => {
        return getStatesForEntity(entityType).filter((state) => !empty(state.systemState));
    };

    const getDefaultStateForEntity = (entityType: string): Optional<string> => {
        return (
            authStore.currentCompanyJsonSettings.cs_states_default_settings?.[entityType]?.default ??
            getMappedStatesForEntity(entityType)[0]?.name
        );
    };

    const getState = (stateName: string, entityType: string): Optional<CompanyState> => {
        const states = getStatesForEntity(entityType);

        if (isArray(states)) {
            for (const state of states) {
                if (state.name === stateName) {
                    return state;
                }
            }
        }

        return null;
    };

    const getStateBySystemState = (systemState: string, entityType: string): Optional<CompanyState> => {
        const states = getStatesForEntity(entityType);

        if (isArray(states)) {
            for (const state of states) {
                if (state.systemState === systemState) {
                    return state;
                }
            }
        }

        return null;
    };

    const getStateColor = (stateName: string, entityType: string): Optional<CompanyState> => {
        return getState(stateName, entityType)?.color ?? null;
    };

    const getSystemState = (stateName: string, entityType: string): Optional<CompanyState> => {
        return getState(stateName, entityType)?.systemState ?? null;
    };

    const getAllowedStates = (entityType: string, stateName?: string): CompanyState[] => {
        // TODO: HR-8758
        if (!permissions.can(permissionAttribute.EDIT, entityToEntityNewMap[entityType]) || true) {
            return [];
        }

        const entityStates = getStatesForEntity(entityType);

        const allowedStates = [];

        for (const state of entityStates) {
            if (state.name === stateName) {
                continue;
            }

            if (
                (state.permissionStates === PermissionStates.ALL || state.allowedStates.includes(stateName)) &&
                (state.permissionEntity === PermissionEntities.ALL ||
                    state.companyStateGroupMaps.some((groupMap) => groupMap.group.id in authStore.user.groups))
            ) {
                allowedStates.push(state);
            }
        }

        return allowedStates;
    };

    return {
        getStatesForEntity,
        getMappedStatesForEntity,
        getDefaultStateForEntity,
        getState,
        getStateBySystemState,
        getStateColor,
        getSystemState,
        getAllowedStates,
    };
}
