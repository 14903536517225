export const itemType = Object.freeze({
    MEDICAL_EXAMINATION: 'MEDICAL_EXAMINATION',
    BONUS: 'BONUS',
    PENALTIES: 'PENALTIES',
    DEPOSIT: 'DEPOSIT',
    ACCOMMODATION: 'ACCOMMODATION',
    TOOLS: 'TOOLS',
    CLOTHES: 'CLOTHES',
    MEDICAL_CARE: 'MEDICAL_CARE',
    CHARGES: 'CHARGES',
    GRANT: 'GRANT',
    EMPLOYEE_RECOMMENDATION: 'EMPLOYEE_RECOMMENDATION',
    TRAININGS: 'TRAININGS',
    TRANSPORT: 'TRANSPORT',
    INSURANCE: 'INSURANCE',
    DAMAGE: 'DAMAGE',
    ADVANCE: 'ADVANCE', // Zaliczka
    SOCIAL_TAX_FOREIGN: 'SOCIAL_TAX_FOREIGN',
    OTHER: 'OTHER',
});

export const itemTypeMap = Object.freeze({
    [itemType.MEDICAL_EXAMINATION]: 'Badania lekarskie i laboratoryjne',
    [itemType.BONUS]: 'Dodatki motywacyjne, prezenty',
    [itemType.PENALTIES]: 'Kary umowne',
    [itemType.DEPOSIT]: 'Kaucje',
    [itemType.ACCOMMODATION]: 'Mieszkanie i nocleg',
    [itemType.TOOLS]: 'Narzędzia',
    [itemType.CLOTHES]: 'Odzież i obuwie',
    [itemType.MEDICAL_CARE]: 'Opieka medyczna',
    [itemType.CHARGES]: 'Opłaty manipulacyjne',
    [itemType.GRANT]: 'Pożyczki i zapomogi',
    [itemType.EMPLOYEE_RECOMMENDATION]: 'Polecenie',
    [itemType.TRAININGS]: 'Szkolenia i uprawnienia',
    [itemType.TRANSPORT]: 'Transport, bilety i paliwo',
    [itemType.INSURANCE]: 'Ubezpieczenia',
    [itemType.DAMAGE]: 'Wyrządzone szkody i straty',
    [itemType.ADVANCE]: 'Zaliczki',
    [itemType.SOCIAL_TAX_FOREIGN]: 'Zagraniczne składki społeczne',
    [itemType.OTHER]: 'Inne',
});

export const itemAdvancedType = Object.freeze({
    REMUNERATION: 'REMUNERATION',
    REFUND: 'REFUND',
});

export const itemAdvancedTypeMap = Object.freeze({
    [itemAdvancedType.REMUNERATION]: 'Wynagrodzenie',
    [itemAdvancedType.REFUND]: 'Zwrot',
});

export const itemAmountType = Object.freeze({
    NET: 'NET',
    GROSS: 'GROSS',
    NET_FROM_GROSS: 'NET_FROM_GROSS',
    DELEGATION: 'DELEGATION',
});

export const itemAmountTypeMap = Object.freeze({
    [itemAmountType.NET]: 'Netto',
    [itemAmountType.GROSS]: 'Brutto',
    [itemAmountType.NET_FROM_GROSS]: 'Netto z brutto',
    [itemAmountType.DELEGATION]: 'Delegacja',
});

export const advanceType = Object.freeze({
    REMUNERATION: 'REMUNERATION',
    REFUND: 'REFUND',
});
