import { computed } from 'vue';

export function useFieldAdditional(path, field) {
    const fieldWrapper = computed(() => {
        return field.additional?.wrapper ?? null;
    });

    const fieldReadonly = computed(() => {
        return field.additional?.readonly ?? false;
    });

    const fieldDisable = computed(() => {
        return field?.additional?.disabled ?? false;
    });

    const fieldAutocomplete = computed(() => {
        return field.additional?.autocomplete ?? null;
    });

    const fieldHint = computed(() => {
        return field?.additional?.hint ?? false;
    });

    const fieldClearable = computed(() => {
        return field?.additional?.clearable ? field.additional.clearable : false;
    });

    const fieldMultiple = computed(() => {
        return field?.additional?.multiple ? field.additional.multiple : false;
    });

    const wrapperComponent = computed(() => {
        return fieldWrapper.value ?? 'form-builder-field-wrapper';
    });

    const wrapperAttributes = computed(() => {
        return field?.additional?.wrapperAttributes ?? null;
    });

    return {
        fieldWrapper,
        fieldReadonly,
        fieldDisable,
        fieldAutocomplete,
        fieldHint,
        fieldClearable,
        fieldMultiple,
        wrapperComponent,
        wrapperAttributes,
    };
}
