/**
 * Converts a hexadecimal color code to an RGBA color with a specified opacity.
 *
 * @param {string} hex - The hexadecimal color code to convert.
 * @param {number} opacity - The opacity value for the RGBA color.
 * @returns {string} The RGBA color value with the specified opacity.
 */
export const hexToRgba = (hex: string, opacity: number = 1): string => {
    hex = hex.replace('#', '');

    let r,
        g,
        b: number = 1;

    if (hex.length === 3) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6 || hex.length === 8) {
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    } else {
        throw new Error('Invalid hex color format');
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
