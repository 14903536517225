import { defineStore } from 'pinia';
import { api } from 'boot/axios';
import { StorageService } from 'src/services/storage-service';
import { LocalStorageAdapter } from 'src/adapters/storage/localstorage-adapter';
import { setBrandingData } from 'src/hooks/setBrandingData';
import { useCustomTranslate } from 'src/hooks/useCustomTranslate';
import { useUserData } from 'src/hooks/useUserData';
import { useStorage } from 'src/hooks/useStorage';
import { role } from 'src/enums/role';
import { i18n } from 'boot/i18n';
import { usePageTheme } from 'src/hooks/usePageTheme';
import { useSubscription } from 'src/hooks/useSubscription';
import { subscriptionLimit, subscriptionType } from 'src/enums/subscription';
import { getApplicationName } from 'src/helpers/general';
import { menuMode } from 'src/enums/navigation';
import { addPermissionsFromStorage, initPermissions } from 'boot/permissions';
import { showFeedNotifications, showMeetingsNotifications } from 'boot/notifications';
import { useStates } from 'src/hooks/useStates';
import { initIntercom, shutdownIntercom } from 'boot/intercom';
import { usePermissionsStore } from '@soulab/vue-permissions';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: null,
        refreshToken: null,
        isAuthenticated: false,
        isLoggedByMobile: false,
        user: {},
        companies: {},
        subscriptions: [],
        subscription: {},
        roles: {},
        permissions: {},
        currentCompanyId: null,
        systemVersion: null,
        translations: {},
        branding: {},
        topNavigation: [],
        leftNavigation: [],
        canAddNewCompany: false,
        workerPortal: false,
        feedCount: 0,
        unreadChatMessages: 0,
        minMenu: false,
        openShortMenuItems: null,
        itemTypes: {},
        modules: {},
    }),

    getters: {
        getCurrentUser: (state) => state.user,
        getToken: (state) => state.token,
        getRefreshToken: (state) => state.refreshToken,
        isLoggedIn: (state) => state.isAuthenticated,
        isNativeMobile: (state) => state.isLoggedByMobile,
        getSystemVersion: (state) => state.systemVersion,
        getLeftNavigation: (state) => state.leftNavigation,
        getTopNavigation: (state) => state.topNavigation,
        getPermissions: (state) => state.permissions,
        getCurrentCompanyId: (state) => state.currentCompanyId,
        getCompanies: (state) => state.companies,
        getSubscription: (state) => state.subscription,
        getSubscriptions: (state) => state.subscriptions,
        getCanAddNewCompany: (state) => state.canAddNewCompany,
        getFeedCount: (state) => state.feedCount,
        minMenuIsActive: (state) => state.minMenu,
        getBrandingData: (state) => state.branding,
        getTranslations: (state) => state.translations,
        getOpenShortMenuItem: (state) => state.openShortMenuItem,
        getUserCalendarSettings: (state) => state.user.calendarEventSettings,
        getUserCopyApartmentAddress: (state) => state.user.copyApartmentAddress,
        isSupervisor: (state) => state.user.isSupervisor,
        isSuperuser: (state) => state.user.isSuperuser,
        isModuleActive: (state) => (module) => state.modules[module] === true,
        getCompanyLogoPath: (state) => {
            if (state.branding && state.branding.logoPath && state.branding.logoPath.length > 0) {
                return state.branding.logoPath;
            }

            return '';
        },
        getCompanyMinLogoPath: (state) => {
            if (state.branding && state.branding.logoMinPath && state.branding.logoMinPath.length > 0) {
                return state.branding.logoMinPath;
            }

            return '';
        },

        getSubscriptionInfo: (state) => {
            const subState = state.subscription.state || '';
            const subscriptionLastPayableNotPaid = state.subscription.subscriptionLastPayableNotPaid || [];

            if (subState === subscriptionType.TRIAL) {
                const today = new Date();
                const date = new Date(state.subscription.trialEnd);
                const days = parseInt((date - today) / (1000 * 60 * 60 * 24), 10);

                return i18n.global.t('Twój okres testowy systemu HRappka.pl upływa za') + ' ' + days + ' ' + i18n.global.t('dni') + '.';
            } else if (
                subState === subscriptionType.GRACE &&
                subscriptionLastPayableNotPaid.length > 0 &&
                subscriptionLastPayableNotPaid[0]
            ) {
                const dateFrom = subscriptionLastPayableNotPaid[0].from.substring(0, 10);
                const dateTo = subscriptionLastPayableNotPaid[0].to.substring(0, 10);
                const amount = subscriptionLastPayableNotPaid[0].amount;
                const currency = subscriptionLastPayableNotPaid[0].currency;
                const blockDate = state.subscription.graceEnd.substring(0, 10);

                return (
                    i18n.global.t('Nie odnotowaliśmy płatności za okres') +
                    ' ' +
                    dateFrom +
                    ' - ' +
                    dateTo +
                    ' (' +
                    amount +
                    ' ' +
                    currency +
                    ').' +
                    ' ' +
                    i18n.global.t('Dostęp do systemu zostanie zablokowany w dniu') +
                    ' ' +
                    blockDate +
                    '.'
                );
            } else if (subState === subscriptionType.BLOCK) {
                return i18n.global.t(
                    'Konto zablokowane, ureguluj należności lub skontakuj się z opiekunem, aby odblokować. Jeżeli już opłaciłeś abonament i dostałeś potwierdzenie przyjęcia płatności lub konto zostało odblokowane przez infolinię wsparcia / opiekuna - wyloguj się i zaloguj ponownie.'
                );
            }

            return '';
        },

        getCompanyIdWithRoleShortcut: (state) => {
            if (state.currentCompanyId && state.roles && state.roles[state.currentCompanyId] && state.roles[state.currentCompanyId].role) {
                let roleShortcute = '';
                switch (state.roles[state.currentCompanyId].role) {
                    case role.ADMIN:
                        roleShortcute = 'A';
                        break;

                    case role.USER:
                        roleShortcute = 'U';
                        break;

                    case role.RECRUITMENT_PARTNER:
                        roleShortcute = 'PR';
                        break;

                    case role.CLIENT:
                        roleShortcute = 'K';
                        break;

                    case role.WORKER:
                        roleShortcute = 'P';
                        break;

                    case role.CANDIDATE:
                        roleShortcute = 'KA';
                        break;

                    case role.BUSINESS_PARTNER:
                        roleShortcute = 'PH';
                        break;
                }

                return `${state.currentCompanyId}${roleShortcute}`;
            }

            return null;
        },

        getCurrentCompany: (state) => {
            if (state.currentCompanyId && state.companies && state.companies[state.currentCompanyId]) {
                return state.companies[state.currentCompanyId];
            }

            return null;
        },

        getCurrentRole: (state) => {
            if (state.workerPortal) {
                return { role: role.WORKER };
            }

            if (state.currentCompanyId && state.roles && state.roles[state.currentCompanyId]) {
                return state.roles[state.currentCompanyId];
            }

            return null;
        },

        currentCompanyJsonSettings() {
            return this.getCurrentCompany?.settings?.jsonSettings ?? {};
        },

        subscriptionCompany() {
            return this.subscription.company;
        },

        isLoggedOnSubscriptionCompany() {
            return this.subscription.company.id === this.currentCompanyId;
        },

        hasAccessToSubscriptionCompany() {
            return this.subscription.company.id in this.companies;
        },

        isAdmin() {
            return this.getCurrentRole?.role === role.ADMIN;
        },

        isUser() {
            return this.getCurrentRole?.role === role.USER;
        },

        isWorker() {
            return this.getCurrentRole?.role === role.WORKER;
        },

        isCandidate() {
            return this.getCurrentRole?.role === role.CANDIDATE;
        },

        isWorkerGroup() {
            return this.isCandidate || this.isWorker;
        },

        isClient() {
            return this.getCurrentRole?.role === role.CLIENT;
        },

        isRecruitmentPartner() {
            return this.getCurrentRole?.role === role.RECRUITMENT_PARTNER;
        },

        canChangeRole(state) {
            if([role.ADMIN, role.USER].includes(state.roles[state.currentCompanyId].originalRole)) {
                return true;
            }

            /**
             * Sprawdzamy czy na innej firmie mamy rolę admina/usera - jeżeli tak możmy się przełączać
             */
            for(const companyId in state.roles) {
                if([role.ADMIN, role.USER].includes(state.roles[companyId].originalRole)) {
                    return true;
                }
            }

            return false;
        },

        /**
         * @param state
         *
         * @returns {null|null|*|string}
         */
        getCompanyIdToSetInUserPortal(state) {
            if([role.ADMIN, role.USER].includes(state.roles[state.currentCompanyId].originalRole)) {
                return state.currentCompanyId;
            }

            /**
             * Sprawdzamy czy na innej firmie mamy rolę admina/usera - jeżeli tak możmy się przełączać
             */
            for(const companyId in state.roles) {
                if([role.ADMIN, role.USER].includes(state.roles[companyId].originalRole)) {
                    return companyId;
                }
            }

            return null;
        }
    },

    actions: {
        /**
         * Funkcja aktualizująca tokeny
         */
        setTokens(jwtToken, refreshToken) {
            this.token = jwtToken;
            this.refreshToken = refreshToken;
        },

        setSystemVersion(version) {
            this.systemVersion = version;
        },

        setIsAuthenticated(value) {
            this.isAuthenticated = value;
        },

        setWorkerPortal(value) {
            this.workerPortal = value;
        },

        setSubscriptions(value) {
            const storage = useStorage(StorageService.KEY_AUTH_DATA);
            this.subscriptions = value;
            storage.setItem('subscriptions', this.subscriptions);
        },

        openShortMenuItemAction(item) {
            this.openShortMenuItem = item;
        },

        clearOpenShortMenuItem() {
            this.openShortMenuItem = null;
        },

        setCalendarEventSettings(value) {
            this.user.calendarEventSettings = value;
        },

        setLoggedByMobile(value) {
            const storage = useStorage(StorageService.KEY_AUTH_DATA);
            storage.setItem('isLoggedByMobile', value);
            this.isLoggedByMobile = value;
        },

        calculateCanAddNewCompany() {
            const subscription = this.getSubscription;

            if (this.isAdmin && subscription && subscription.limits) {
                for (let i in subscription.limits) {
                    if (
                        subscription.limits[i].codename &&
                        subscription.limits[i].codename === subscriptionLimit.COMPANY_AMOUNT &&
                        subscription.limits[i].counter < subscription.limits[i].limit
                    ) {
                        return true;
                    }
                }
            }

            return false;
        },

        /**
         * Metoda ustawiająca dane autoryzacyjne po prawidłowej autoryzacji
         *
         * @param token
         * @param refreshToken
         */
        setAuthUserData(token, refreshToken) {
            const storage = useStorage(StorageService.KEY_AUTH_DATA);

            /**
             * Ustawienie tokenów
             */
            this.token = token;
            this.refreshToken = refreshToken;

            /**
             * Oznaczenie flagi autoryzacji
             */
            this.isAuthenticated = true;
            storage.setItem('isAuthenticated', this.isAuthenticated);

            /**
             * Ustawienie tokenu w nagłówku żądań
             */
            api.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;

            /**
             * Zapis danych do storage
             */
            storage.setItem('token', this.token);
            storage.setItem('refreshToken', this.refreshToken);

            /**
             * Remove chat data
             */
            localStorage.removeItem('vuex-multi-tab');
        },

        /**
         * Funkcja autoryzująca usera
         *
         * @param email
         * @param password
         * @param googleAuthCode
         * @param companyId
         * @param lang
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        async login(email, password, googleAuthCode = '', companyId = null, lang = null) {
            const result = await api
                .post('/auth/login', {
                    email: email,
                    password: password,
                    googleAuthCode: googleAuthCode,
                    authenticateOldApi: true,
                    companyId: companyId,
                    lang: lang,
                })
                .then((response) => {
                    if (response.data && response.data.success) {
                        this.setAuthUserData(response.data.data.token, response.data.data.refreshToken);

                        return response;
                    } else {
                        return response;
                    }
                })
                .catch((error) => {
                    return error.response;
                });

            if (result && result.data && result.data.success) {
                await this.loadPostData();

                return result;
            }

            return result;
        },

        /**
         * Funkcja ładująca podstawowe dane o firmie, użytkowniku
         * @returns {Promise<any>}
         */
        async loadPostData() {
            return await api
                .get('/auth/post-login')
                .then((response) => {
                    if (response.data && response.data.success) {
                        const userData = useUserData();
                        const storage = useStorage(StorageService.KEY_AUTH_DATA);
                        const subscription = useSubscription();

                        /**
                         * Załadowanie danych o userze do stora
                         */
                        if (response.data.data.user) {
                            this.user = response.data.data.user;
                            storage.setItem('user', this.user);
                        }

                        /**
                         * Załadowanie danych o firmach do stora
                         */
                        if (response.data.data.companies) {
                            this.companies = response.data.data.companies;
                            storage.setItem('companies', this.companies);

                            if (response.data.data.currentCompanyId) {
                                this.currentCompanyId = response.data.data.currentCompanyId;
                                storage.setItem('currentCompanyId', this.currentCompanyId);
                            }
                        }

                        /**
                         * Załadowanie subskrypcji
                         */
                        if (response.data.data.subscription) {
                            this.subscription = response.data.data.subscription;
                            subscription.setSubscription(this.subscription);
                        }

                        /**
                         * Załadowanie uprawnień
                         */
                        if (response.data.data.permissions) {
                            this.permissions = response.data.data.permissions;
                            storage.setItem('permissions', this.permissions);
                            addPermissionsFromStorage(this.permissions);
                        }

                        /**
                         * Załadowanie tłumaczeń
                         */
                        if (response.data.data.translations) {
                            this.translations = response.data.data.translations;
                            storage.setItem('translations', this.translations);
                            useCustomTranslate(this.translations);
                        }

                        /**
                         * Ustawienie języka aplikacji zgodnie z ustawieniami użytkownika
                         */
                        if (this.user.language) {
                            userData.setGlobalLanguage(this.user.language, false);
                        }

                        /**
                         * Ustawienie wyglądu (darkMode/whiteMode)
                         */
                        if (this.user.layoutThemeSettings) {
                            usePageTheme().set(this.user.layoutThemeSettings);
                        } else {
                            usePageTheme().set(null);
                        }

                        /**
                         * Ustawienie szerokości menu
                         */
                        if (this.user.menuMode) {
                            if (this.user.menuMode === menuMode.short) {
                                this.minMenu = true;
                                storage.setItem('minMenu', this.minMenu);
                            } else {
                                this.minMenu = false;
                                storage.setItem('minMenu', this.minMenu);
                            }
                        } else {
                            this.minMenu = false;
                            storage.setItem('minMenu', this.minMenu);
                        }

                        /**
                         * Załadowanie danych o brandingu
                         */
                        if (response.data.data.branding) {
                            this.branding = response.data.data.branding;
                            setBrandingData(this.branding);
                        }

                        /**
                         * Załadowanie nawigacji górnej
                         */
                        if (response.data.data.dropdownsConfig) {
                            this.topNavigation = response.data.data.dropdownsConfig;
                            storage.setItem('topNavigation', this.topNavigation);
                        }

                        /**
                         * Załadowanie nawigacji bocznej
                         */
                        if (response.data.data.mainMenuConfig) {
                            this.leftNavigation = response.data.data.mainMenuConfig;
                            storage.setItem('leftNavigation', this.leftNavigation);
                        }

                        /**
                         * Załadowanie danych o rolach do stora
                         */
                        if (response.data.data.roles) {
                            this.roles = response.data.data.roles;
                            storage.setItem('roles', this.roles);
                        }

                        /**
                         * Załadowanie danych o wersji systemu do stora
                         */
                        if (response.data.data.systemVersion) {
                            this.systemVersion = response.data.data.systemVersion;
                            storage.setItem('systemVersion', this.systemVersion);
                        }

                        /**
                         * Sprawdzenie czy user może dodać nową firmę
                         */
                        if (response.data.data.subscription) {
                            this.canAddNewCompany = this.calculateCanAddNewCompany();
                            storage.setItem('canAddNewCompany', this.canAddNewCompany);
                        }

                        if (response.data.data.modules) {
                            this.modules = response.data.data.modules;
                            storage.setItem('modules', this.modules);
                        }

                        if (response.data.data.itemTypes) {
                            this.itemTypes = response.data.data.itemTypes;
                            storage.setItem('itemTypes', this.itemTypes);
                        }

                        /**
                         * Refresh all hrappka pages
                         */
                        localStorage.setItem('lastCompanyChangeTime', new Date().toString());

                        initIntercom();

                        return true;
                    }

                    return false;
                })
                .then(() => {
                    /**
                     * Newly assigned role is available in store ONLY after .then and setTimeout
                     */
                    setTimeout(() => {
                        /**
                         * Initialize permissions for current role
                         */
                        initPermissions({ role: this.getCurrentRole?.role });

                        /**
                         * Refresh notification intervals
                         */
                        showFeedNotifications(this.router);
                        showMeetingsNotifications();
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.clearUserData();

                    return false;
                });
        },

        /**
         * Funkcja logowania po tokenie użyta do logowania aplikacji mobilnej po linku z geta
         * @param token
         * @returns {Promise<AxiosResponse<any>>}
         */
        async callLoginByMobileToken(token) {
            const result = await api
                .post('/auth/login-by-token', {
                    token: token,
                })
                .then((response) => {
                    if (response.data && response.data.success) {
                        this.setAuthUserData(response.data.data.token, response.data.data.refreshToken);
                        this.setLoggedByMobile(true);

                        return response;
                    } else {
                        return response;
                    }
                })
                .catch((error) => {
                    return error.response;
                });

            if (result && result.data && result.data.success) {
                await this.loadPostData();

                return result;
            }

            return result;
        },

        async callRefreshToken(companyId = null, roleWorker = false) {
            delete api.defaults.headers.common['Authorization'];

            const result = await api
                .post('/auth/refresh', {
                    jwtToken: this.getToken,
                    refreshToken: this.getRefreshToken,
                    authenticateOldApi: true,
                    companyId: companyId,
                    role: roleWorker ? role.WORKER : null,
                })
                .then((response) => {
                    if (response.data && response.data.success) {
                        this.setAuthUserData(response.data.data.token, response.data.data.refreshToken);

                        return true;
                    } else {
                        this.clearUserData();

                        return false;
                    }
                })
                .catch(() => {
                    this.clearUserData();

                    return false;
                });

            if (result) {
                await this.loadPostData();
            }

            return result;
        },

        /**
         * Funkcja usuwająca dane o userze
         */
        clearUserData() {
            this.token = null;
            this.refreshToken = null;
            this.isAuthenticated = false;
            this.user = {};
            this.companies = {};
            this.subscriptions = [];
            this.roles = {};
            this.currentCompanyId = null;
            this.systemVersion = null;
            this.translations = {};
            this.branding = {};
            this.topNavigation = [];
            this.leftNavigation = [];
            this.canAddNewCompany = false;
            this.workerPortal = false;
            this.feedCount = 0;
            this.minMenu = false;

            /**
             * Usunięcie wszystkich danych z authData storage
             */
            const authDataStorage = useStorage(StorageService.KEY_AUTH_DATA);
            authDataStorage.removeItem();

            /**
             * Usunięcie wszystkich danych z filterData storage
             */
            const filterDataStorage = useStorage(StorageService.KEY_FILTER_DATA, LocalStorageAdapter);
            filterDataStorage.removeItem();

            /**
             * Usunięcie tokenu z nagłówka
             */
            delete api.defaults.headers.common['Authorization'];
        },

        /**
         * Funkcja wylogowująca usera
         */
        logout(redirectPath = null, logoutFromSupport = false) {
            let body = null;
            if (logoutFromSupport) {
                body = {
                    logoutFromSupport: true,
                };
            }

            return api
                .post('/auth/logout', body)
                .then(() => {
                    document.title = getApplicationName();

                    this.clearUserData();
                    usePageTheme().set(null);

                    if (redirectPath) {
                        this.router.push({ path: redirectPath });
                    }

                    shutdownIntercom();
                })
                .catch(() => {
                    document.title = getApplicationName();

                    this.clearUserData();
                    usePageTheme().set(null);

                    if (redirectPath) {
                        this.router.push({ path: redirectPath });
                    }
                });
        },

        getCompanyStates(cmpId = null) {
            cmpId = cmpId ? cmpId : this.getCurrentCompanyId;

            return this.companies[cmpId]?.companyStates ? this.companies[cmpId].companyStates : {};
        },

        getStatesForEntity(entityType, cmpId = null) {
            return useStates(this.getCompanyStates(cmpId)).getStatesForEntity(entityType);
        },

        getStateColor(stateName, entityType, cmpId = null) {
            return useStates(this.getCompanyStates(cmpId)).getStateColor(stateName, entityType);
        },
    },
});
