<template>
    <div class="text-default full-width">
        <div v-click-outside="() => toggleSearchResult(false)" class="full-width">
            <div class="full-width">
                <q-input
                    v-if="isMobile"
                    v-model.trim="searchText"
                    ref="input"
                    dense
                    standout
                    square
                    color="white"
                    :placeholder="$t('Wpisz aby wyszukać..')"
                    @keyup="debouncedSearch"
                    @focus="toggleSearchResult(true)"
                    @keydown.esc="[$event.target.blur(), toggleSearchResult(false)]"
                >
                    <template #append>
                        <q-icon name="close" class="cursor-pointer" color="white" @click="onSearchClose()" />
                    </template>
                </q-input>
                <q-input
                    v-else
                    v-model.trim="searchText"
                    dark
                    borderless
                    input-class="text-default"
                    class="full-width"
                    :placeholder="$t('Wpisz, aby przeszukać system i bazę wiedzy (min. 3 znaki)...')"
                    @keyup="debouncedSearch"
                    @keydown.esc="[$event.target.blur(), toggleSearchResult(false)]"
                    @focus="toggleSearchResult(true)"
                >
                    <template #prepend>
                        <q-icon name="search" class="text-default" />
                    </template>
                </q-input>
            </div>

            <div v-if="openResult" class="searchbar-results relative full-width" :class="{ 'is-mobile': isMobile }">
                <div v-if="loading" class="absolute" style="inset: 0">
                    <loader-component>
                        <q-spinner-dots color="brand" size="3em" />
                    </loader-component>
                </div>

                <template v-if="result.length > 0 || knowledgeBase.length > 0">
                    <search-result-main-component
                        :entities-data="result"
                        :knowledge-base-data="knowledgeBase"
                        @close-search-result="toggleSearchResult(false)"
                    />
                </template>

                <template v-else>
                    <search-result-main-component
                        v-if="history.length > 0"
                        :history-data="history"
                        @close-search-result="toggleSearchResult(false)"
                    />

                    <div class="searchbar-info">
                        {{
                            $t(
                                'Wpisz frazę, aby przeszukać dane dodane w systemie, elementy konfiguracji oraz bazę wiedzy. Zobaczysz tutaj również ostatnio oglądane elementy.'
                            )
                        }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { debounce } from 'quasar';
import { oldHrappka } from 'boot/axios';
import SearchResultMainComponent from 'components/Navigation/SearchResultMainComponent.vue';
import LoaderComponent from 'components/Other/LoaderComponent.vue';

defineProps({
    isMobile: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['search-close']);

const searchText = ref('');
const result = ref([]);
const knowledgeBase = ref([]);
const history = ref([]);
const openResult = ref(false);
const loading = ref(false);
const input = ref(null);

const toggleSearchResult = (open) => {
    openResult.value = open;

    if (open) {
        executeSearch();
    }
};

const onSearchClose = () => {
    searchText.value = '';
    toggleSearchResult(false);
    emit('search-close');
};

const executeSearch = () => {
    loading.value = true;

    oldHrappka
        .get('/index/global-entity-search-ajax', {
            params: {
                search: searchText.value,
            },
        })
        .then((response) => {
            result.value = response.data.data || [];
            knowledgeBase.value = response.data.knowledgeBase || [];
            history.value = response.data.history || [];

            loading.value = false;
        })
        .catch(() => {
            result.value = [];
            knowledgeBase.value = [];
            history.value = [];

            loading.value = false;
        });
};

const debouncedSearch = debounce(executeSearch, 1200);

const focusInput = () => {
    input.value?.focus();
    toggleSearchResult(true);
};

defineExpose({ focusInput });
</script>

<style scoped lang="scss">
.searchbar-results {
    position: absolute;
    left: 0;
    background-color: var(--white-color);
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
    transition: height 0.3s ease-in-out;
    z-index: 9;
    max-height: calc(var(--app-height) - 118px);
    margin-top: 5px;
    overflow-y: auto;

    &.is-mobile {
        margin-top: 0;
        position: fixed;
        left: 0;
        right: 0;
        top: calc(var(--header-padding) * 2 + var(--avatar-size));
    }
}

.searchbar-results:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    box-shadow: inset 0 2px 4px rgba(15, 34, 58, 0.12);
}

.searchbar-info {
    padding: 20px;
    text-align: center;
}
</style>

<style lang="scss">
.mobile-header--search {
    .q-field--dense.q-field--labeled .q-field__label,
    .q-field--standout .q-field__control {
        background-color: #ffffff66;
        color: white;
    }

    .q-field--highlighted .q-field__control {
        background: #ffffff66 !important;
    }
}
</style>
