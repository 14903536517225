import { customRule } from 'src/rules/custom';
import { dateRangeRule, dateRule } from 'src/rules/date';
import { dynamicFormRule } from 'src/rules/dynamicForm';
import { emailRule } from 'src/rules/email';
import { idNumberRule } from 'src/rules/idNumber';
import { lengthRule } from 'src/rules/length';
import { nipRule } from 'src/rules/nip';
import { numberRule } from 'src/rules/number';
import { passwordRule } from 'src/rules/password';
import { peselRule } from 'src/rules/pesel';
import { phoneRule } from 'src/rules/phone';
import { postCodeRule } from 'src/rules/postCode';
import { regonRule } from 'src/rules/regon';
import { requiredRule } from 'src/rules/required';
import { requiredSomeRule } from 'src/rules/requiredSome';
import { sameLikeRule } from 'src/rules/sameLike';

export default {
    custom: customRule,
    date: dateRule,
    dateRange: dateRangeRule,
    dynamicForm: dynamicFormRule,
    email: emailRule,
    idNumber: idNumberRule,
    length: lengthRule,
    nip: nipRule,
    number: numberRule,
    password: passwordRule,
    pesel: peselRule,
    phone: phoneRule,
    postCode: postCodeRule,
    regon: regonRule,
    required: requiredRule,
    requiredSome: requiredSomeRule,
    sameLike: sameLikeRule,
};
