import { modalSizes } from 'src/enums/modal';
import { showAlert } from 'src/hooks/showAlert';
import { AlertType } from 'src/enums/alert';
import { i18n } from 'boot/i18n';
import MainLayout from 'layouts/MainLayout.vue';

export default [
    {
        path: '',
        name: 'standard-calendar',
        component: () => import('../../pages/calendar/StandardCalendarPage.vue'),
        meta: {
            title: 'Kalendarz',
            layout: MainLayout,
        },
    },
    {
        path: 'employee-reported',
        name: 'calendar-employee-reported',
        component: () => import('../../pages/calendar/EmployeeReportedPage.vue'),
        meta: {
            title: 'Zgłoszenia pracowników',
            layout: MainLayout,
        },
    },
    {
        path: 'employee-work-time',
        name: 'employee-work-time',
        component: () => import('../../pages/calendar/EmployeeWorkTimePage.vue'),
        meta: {
            title: 'Ewidencja czasu pracy',
            layout: MainLayout,
        },
    },
    {
        path: 'create-work-demand-event',
        name: 'create-work-demand-event',
        component: () => import('../../pages/calendar/CreateWorkDemandEventPage.vue'),
        meta: {
            openInModal: true,
            title: 'Dodawanie zapotrzebowania',
        },
    },
    {
        path: 'edit-work-demand-event/:id',
        name: 'edit-work-demand-event',
        component: () => import('../../pages/calendar/EditWorkDemandEventPage.vue'),
        meta: {
            openInModal: true,
            title: 'Edycja zapotrzebowania',
        },
    },
    {
        path: 'create-accommodation-event',
        name: 'create-accommodation-event',
        component: () => import('../../pages/calendar/CreateAccommodationPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie dodano nocleg.'));
            },
        },
    },
    {
        path: 'edit-accommodation-event',
        name: 'edit-accommodation-event',
        component: () => import('../../pages/calendar/EditAccommodationPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie edytowano nocleg.'));
            },
        },
    },
    {
        path: 'create-absence-event',
        name: 'create-absence-event',
        component: () => import('../../pages/calendar/CreateAbsencePage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie dodano nieobecność.'));
            },
        },
    },
    {
        path: 'create-car-event',
        name: 'create-car-event',
        component: () => import('../../pages/calendar/CreateCarPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie dodano przejazd samochodem.'));
            },
        },
    },
    {
        path: 'edit-car-event',
        name: 'edit-car-event',
        component: () => import('../../pages/calendar/EditCarPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie edytowano przejazd samochodem.'));
            },
        },
    },
    {
        path: 'create-transport-event',
        name: 'create-transport-event',
        component: () => import('../../pages/calendar/CreateTransportPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie dodano transport.'));
            },
        },
    },
    {
        path: 'edit-transport-event',
        name: 'edit-transport-event',
        component: () => import('../../pages/calendar/EditTransportPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie edytowano transport.'));
            },
        },
    },
    {
        path: 'create-meeting-event',
        name: 'create-meeting-event',
        component: () => import('../../pages/calendar/CreateMeetingPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie dodano spotkanie.'));
            },
        },
    },
    {
        path: 'edit-meeting-event',
        name: 'edit-meeting-event',
        component: () => import('../../pages/calendar/EditMeetingPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie edytowano spotkanie.'));
            },
        },
    },
    {
        path: 'create-rate-event',
        name: 'create-rate-event',
        component: () => import('../../pages/calendar/CreateRateEventPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie dodano wpis grafikowy.'));
            },
        },
    },
    {
        path: 'edit-rate-event',
        name: 'edit-rate-event',
        component: () => import('../../pages/calendar/EditRateEventPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            successCallback: () => {
                showAlert(AlertType.SUCCESS, i18n.global.t('Pomyślnie edytowano wpis grafikowy.'));
            },
        },
    },
];
