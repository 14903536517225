import { countryCode } from 'src/enums/country';
import { empty } from '@soulab/js-helpers';

export function nipRule(value, validator, context) {
    const countries = [
        countryCode.PL,
        countryCode.AT,
        countryCode.BE,
        countryCode.CH,
        countryCode.CZ,
        countryCode.DE,
        countryCode.FR,
        countryCode.GB,
        countryCode.NL,
        countryCode.LT,
        countryCode.BG,
        countryCode.CY,
        countryCode.DK,
        countryCode.EE,
        countryCode.GR,
        countryCode.ES,
        countryCode.HR,
        countryCode.FI,
        countryCode.HU,
        countryCode.IE,
        countryCode.IT,
        countryCode.LU,
        countryCode.LV,
        countryCode.MT,
        countryCode.NO,
        countryCode.PT,
        countryCode.RO,
        countryCode.SE,
        countryCode.SI,
        countryCode.SK,
        countryCode.UA,
        countryCode.AZ,
        countryCode.AE,
    ];

    const regexNipEurope =
        /^((AT ?U[0-9]{8})|(BE ?0?[0-9]{9})|(CZ ?[0-9]{8,10})|(DE ?[0-9]{9,11})|(FR ?[0-9A-Z]{2} ?[0-9]{9})|(GB ?[0-9]{3} ?[0-9]{4} ?[0-9]{2}( ?[0-9]{3})?)|(LT ?([0-9]{9}|[0-9]{12}))|(NL ?[0-9]{9}B[0-9]{2}))$/;
    const regexNipEuropeAlt =
        /^((BG ?[0-9]{9,10})|(CHE ?[0-9]{9} ?(IVA|TVA|MWST)?)|(CY ?[0-9]{8}[A-Z])|(DK( ?[0-9]{2}){4})|(EE ?[0-9]{9})|(((EL)|(GR)) ?[0-9]{9})|(ES ?[0-9A-Z][0-9]{7}[0-9A-Z])|(HR ?[0-9]{11})|(FI ?([0-9]{7}-[0-9]|[0-9]{8}))|(HU ?[0-9]{8})|(IE ?[0-9][0-9A-Z][0-9]{5}[A-Z](?![0-9]))|(IT ?[0-9]{11})|(LU ?[0-9]{8})|(LV ?[0-9]{11})|(MT ?[0-9]{8})|(NO ?[0-9]{9}(MVA)?)|(PT ?[0-9]{9})|(RO ?[0-9]{2,10})|(SE ?[0-9]{10}01)|(SI ?[0-9]{8})|(SK ?[0-9]{10}))$/;
    const regexNipNonEurope = /^((UA ?([0-9]{10}|[0-9]{12}))|(AZE? ?([0-9]{10}))|(AE ?100(-?[0-9]{4}){3}))$/;

    if (empty(value, false)) {
        return true;
    }

    let valid = false;
    const country = value.match(/^[a-zA-Z]{2}/)?.[0] ?? countryCode.PL;

    if (country === countryCode.PL) {
        if (value.length === 10) {
            const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            const digits = Array.from(value);

            const controlNumber = parseInt(digits[value.length - 1]);
            let sum = 0;

            for (let i in weight) {
                sum += parseInt(value.charAt(i)) * weight[i];
            }

            if (sum % 11 === controlNumber) {
                valid = true;
            }
        }
    } else if (countries.includes(country.toUpperCase())) {
        if (regexNipEurope.test(value) || regexNipEuropeAlt.test(value) || regexNipNonEurope.test(value)) {
            valid = true;
        }
    }

    if (!valid) {
        return context.rule.params?.message || 'Wprowadzono nieprawidłowy numer NIP';
    }

    return true;
}
