<template>
    <div class="mobile-header-background" :class="{ reduced: isReduced }" :style="{ backgroundColor: brandColorWithOpacity }">
        <div class="mobile-header-background--svg-wrapper">
            <div class="mobile-header-background--svg-wrapper--left" :class="{ reduced: isReduced }">
                <svg width="156" height="198" viewBox="0 0 110 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M38.0038 110.094C22.7336 117.951 6.30965 121.892 -8.49324 113.568C-23.2841 105.251 -31.7306 89.4453 -35.0553 73.0193C-38.1795 57.5842 -33.673 42.1417 -25.7499 28.3109C-17.671 14.2079 -7.26083 -0.822038 8.93093 -3.76506C24.5172 -6.59803 27.3814 21.4361 41.4667 28.3109C57.1938 35.987 83.6088 35.6494 86.6888 52.6328C89.8111 69.8491 85.9838 71.4343 67.4087 81.2481C48.4628 91.2578 51.6142 103.091 38.0038 110.094Z"
                    />
                </svg>
            </div>
            <div class="mobile-header-background--svg-wrapper--right" :class="{ reduced: isReduced }">
                <svg width="130" height="150" viewBox="0 0 90 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M48.9629 -38.073C64.1351 -46.2958 80.5709 -50.1925 95.694 -40.6739C110.805 -31.163 119.722 -13.6008 123.498 4.50863C127.046 21.5256 122.91 38.352 115.292 53.3209C107.524 68.5844 97.4326 84.8044 81.2045 87.6117C65.5832 90.3141 61.9688 59.4801 47.6061 51.5701C31.5693 42.7381 4.97872 42.4203 1.43481 23.7057C-2.1577 4.73442 1.65503 3.09484 20.1041 -7.18915C38.9215 -17.6784 35.4399 -30.744 48.9629 -38.073Z"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { hexToRgba } from 'src/helpers/colors';
import { computed } from 'vue';
import { useAuthStore } from 'stores/auth';

const props = defineProps<{ isReduced: boolean }>();

const authStore = useAuthStore();

const brandColor = computed((): string => authStore.getBrandingData?.color?.link || '#19555c');
const brandColorWithOpacity = computed(() => hexToRgba(brandColor.value, 0.8));
</script>

<style scoped lang="scss">
.mobile-header-background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    overflow: hidden;

    svg {
        fill: var(--brand-color);
    }

    &--svg-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--left {
            position: relative;
            transform: translateX(0) translateY(-26px);
            left: 0;
            transition: transform var(--header-transition-duration) ease;

            &.reduced {
                transform: translateX(-16px) translateY(-100px);
            }
        }

        &--right {
            position: relative;
            top: 0;
        }
    }
}
</style>
