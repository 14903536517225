/**
 * Metoda dekodująca znaki specjalne w HTML
 *
 * @param html
 * @returns {string|string}
 */
export function htmlDecodeSpecialChars(html) {
    let element = document.createElement('textarea');
    element.innerHTML = html;

    return element.childNodes.length === 0 ? '' : element.childNodes[0].nodeValue;
}

/**
 * Metoda zwracająca tekst z html
 *
 * @param html
 * @returns {string|string}
 */
export function stripHtml(html)
{
    const tmp = document.createElement('div');
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText || '';
}
