<template>
    <q-layout view="lHh Lpr lFf" :class="props.layoutClass" @scroll="handlePageScroll">
        <mobile-navigation v-if="isMobile"></mobile-navigation>
        <desktop-navigation v-else></desktop-navigation>

        <q-page-container class="navigation-content" :class="props.navigationContentClass">
            <slot></slot>
        </q-page-container>
    </q-layout>
</template>

<script setup lang="ts">
import DesktopNavigation from 'components/Navigation/DesktopNavigation.vue';
import MobileNavigation from 'components/Navigation/Mobile/MobileNavigation.vue';
import { computed } from 'vue';
import { Screen } from 'quasar';
import { useViewStore } from 'stores/view';
import { EXPANDED_HEADER_HEIGHT } from 'src/helpers/ui';

const props = defineProps({
    hideHeader: {
        type: Boolean,
        default: false,
    },
    hideLeftMenu: {
        type: Boolean,
        default: false,
    },
    navigationContentClass: {
        type: Array,
        default: () => [],
    },
    layoutClass: {
        type: Array,
        default: () => [],
    },
});

const isMobile = computed(() => !Screen.gt.sm);

const viewStore = useViewStore();

const handlePageScroll = (details: { position: number }): void => {
    if (details.position <= EXPANDED_HEADER_HEIGHT / 2) {
        viewStore.setScrolledToTop(true);
    } else {
        viewStore.setScrolledToTop(false);
    }
};
</script>
