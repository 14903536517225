import { empty } from '@soulab/js-helpers';

export function requiredSomeRule(value, validator, context) {
    const children = validator.children ?? [];

    if (empty(value, false)) {
        return true;
    }

    let valid = false;

    if (children.length) {
        valid = children.some((field) => value[field]);
    } else {
        valid = Object.values(value).some(Boolean);
    }

    if (!valid) {
        return context.rule.params?.message || 'Przynajmniej jedno z pól jest wymagane';
    }

    return true;
}
