import { findField, fieldIteratorCarry, fieldIteratorCarryAllFields, getFormFromField, configureFields, makeCollection, getFormIdFromField } from '@soulab/form-builder';
import { AdditionalEnum } from '@soulab/form-builder/src/enums/additional';
import enums from 'components/FormBuilder/enums';
import { extend } from 'quasar';
import { empty, findInObject, isArray, isString } from '@soulab/js-helpers';
import { useField } from 'components/FormBuilder/hooks/useField';
import { getFormErrors } from '@soulab/form-builder';
import rulesMap from 'src/rules/map';
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import { findFieldSafe } from 'src/helpers/form';

const fieldAttributesTypes = [
    enums.FIELD_ATTRIBUTE_TYPE_TEXT,
    enums.FIELD_ATTRIBUTE_TYPE_TEXTAREA,
    enums.FIELD_ATTRIBUTE_TYPE_NUMBER,
    enums.FIELD_ATTRIBUTE_TYPE_DATE,
    enums.FIELD_ATTRIBUTE_TYPE_SELECT,
    enums.FIELD_ATTRIBUTE_TYPE_FILE,
    enums.FIELD_ATTRIBUTE_TYPE_TABLE,
    enums.FIELD_ATTRIBUTE_TYPE_SECTION,
    enums.FIELD_ATTRIBUTE_TYPE_TREE,
    enums.FIELD_ATTRIBUTE_TYPE_RADIO,
    enums.FIELD_ATTRIBUTE_TYPE_CHECKBOX,
];

export function useFormBuilder(fields) {
    const scrollToInVirtualScroll = (paths) => {
        virtualScroll: for (const path of paths) {
            const pathParts = [];
            const pathSplit = path.split('.');

            // We want to find first field with dynamic form wrapper, so stop search at parent, i.e. at pathSplit.length - 1.
            for (let i = 0; i < pathSplit.length - 1; i++) {
                pathParts.push(pathSplit[i]);
                const ancestorPath = pathParts.join('.');
                const ancestor = findFieldSafe(fields, ancestorPath);

                if (ancestor?.additional?.virtualScroll) {
                    // We will always have a name, since we iterate until penultimate path part.
                    const virtualScrollChildName = pathSplit[i + 1];
                    const virtualScrollIndex = Number(Object.keys(ancestor.children).indexOf(virtualScrollChildName));
                    ancestor.__nativeElement__.scrollTo(virtualScrollIndex, 'center-force');

                    break virtualScroll;
                }
            }
        }
    };

    const scrollToDomElement = (domElement) => {
        window.requestAnimationFrame(() => {
            if (domElement instanceof HTMLElement) {
                let element = domElement;

                while (element.parentElement) {
                    element = element.parentElement;

                    if (element.classList.contains('q-expansion-item--collapsed')) {
                        const toggle = element.querySelector('.q-expansion-item__toggle-button');

                        if (toggle instanceof HTMLButtonElement) {
                            toggle.click();
                        }
                    } else if (element.getAttribute('data-step-content')) {
                        const id = element.getAttribute('data-step-content');
                        const step = document.querySelector(`[data-step-id="${id}"]`);

                        if (step instanceof HTMLAnchorElement) {
                            step.click();
                        }
                    } else if (element.getAttribute('data-tab-content')) {
                        const id = element.getAttribute('data-tab-content');
                        const tab = document.querySelector(`[data-tab-id="${id}"]`);

                        if (tab instanceof HTMLDivElement) {
                            tab.click();
                        }
                    }
                }

                /**
                 * TODO: trzeba będzie to inaczej ogarnąc - logika powinna się wykonywać po otworzeniu wszystkich akordeonów
                 */
                setTimeout(() => {
                    domElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }, 1000);
            }
        });
    };

    const scrollToFormBuilderField = (fieldPath) => {
        scrollToInVirtualScroll([fieldPath]);

        let domElement = document.querySelector(`[name="${fieldPath}"]`);
        if (!domElement) {
            domElement = document.querySelector(`[data-path="${fieldPath}"]`);
        }

        scrollToDomElement(domElement?.parentElement);
        const field = findFieldSafe(fields, fieldPath);

        if (field) {
            if (!field.additional) {
                field.additional = {};
            }

            field.additional.isScollToField = true;
        }
    };

    const scrollToError = () => {
        const errors = getFormErrors(Object.values(fields)[0].__targetForm__);

        scrollToInVirtualScroll(Object.keys(errors).sort());
        scrollToDomElement(findErrorElement());
    };

    const findErrorElement = (context = document) => {
        return context.querySelector('.q-field--error');
    };

    const findErrorInFields = () => {
        const iterator = (fields) => {
            for (const [name, field] of Object.entries(fields)) {
                if (field.error || field.backendError) {
                    return true;
                }

                if (field.children && Object.keys(field.children).length) {
                    if (iterator(field.children)) {
                        return true;
                    }
                }
            }
        };

        return iterator(fields);
    };

    const applyBackendErrorsToFields = (errors) => {
        const iterator = (fields, errors) => {
            for (const [index, [name, field]] of Object.entries(fields).entries()) {
                /**
                 * TODO: Handle nested attribute backend errors
                 */
                if (fieldAttributesTypes.includes(field.type) && errors[index] && Object.keys(errors[index]).length) {
                    field.backendError = errors[index].children.value.errors[0];
                    continue;
                }

                /**
                 * Open accordion
                 */
                if (field.type === enums.FIELD_TYPE_ACCORDION) {
                    if (empty(field.additional)) {
                        field.additional = {};
                    }

                    field.additional.opened = true;
                }

                if (
                    field.children &&
                    Object.keys(field.children).length &&
                    field.additional &&
                    field.additional[AdditionalEnum.IGNORE_FIELD_IN_STRUCTURE]
                ) {
                    iterator(field.children, errors);
                }

                if (!errors?.[name]) {
                    continue;
                }

                if (errors[name].errors) {
                    field.backendError = errors[name].errors[0];
                }

                if (field.children && Object.keys(field.children).length) {
                    iterator(field.children, errors[name].children);
                }
            }
        };

        iterator(fields, errors.children);
    };

    /**
     * Fills form with saved values, sets dynamic labels etc
     *
     * @param {Record<FieldName, Field>} fields
     * @param {object} formData
     * @param {object} autocompleteOptions
     * @param {object} additionalData
     */
    const configureFormFields = (fields, formData, autocompleteOptions, additionalData) => {
        const setFieldValue = (field, name, formData) => {
            if (field.inactive) {
                return;
            }
            if (!empty(field?.additional?.getValue)) {
                field.value = field.additional.getValue(field, formData);
            } else if (formData[name] !== undefined) {
                field.value = formData[name];
                if (field?.additional?.setVisibleIfNotEmpty) {
                    field.visible = true;
                }
            }
        };
        const setFieldLabel = (field, name, formData, autocompleteOptions) => {
            if (empty(field?.additional?.getLabel)) {
                return;
            }
            field.label = field?.additional?.getLabel(field, formData[name], autocompleteOptions);
        };
        const setFieldOptions = (field, name, formData, additionalData) => {
            if (empty(field?.additional?.getOptions)) {
                return;
            }
            field.options = field.additional.getOptions(field, formData, additionalData);
        };
        const iterator = (fields, formData, autocompleteOptions, additionalData) => {
            for (const [name, field] of Object.entries(fields)) {
                if (field.children && Object.keys(field.children).length && formData[name]) {
                    iterator(field.children, formData[name], autocompleteOptions, additionalData);
                } else {
                    setFieldValue(field, name, formData);
                }
                setFieldLabel(field, name, formData, autocompleteOptions);
                setFieldOptions(field, name, formData, additionalData);
            }
        };
        iterator(fields, formData, autocompleteOptions, additionalData);
    };

    /**
     * Extend form structure according to saved form
     *
     * @param {Record<FieldName, Field>} fields
     * @param {object} formData
     */
    const rebuildFormDefinitionByFormData = (fields, formData) => {
        const iterator = (fields, formData) => {
            for (const [_, field] of Object.entries(fields)) {
                if (field.type === enums.FIELD_TYPE_DYNAMIC_FORM || field.type === enums.FIELD_TYPE_TABS) {
                    if (!field.inactive && (field.template || field.options)) {
                        let children = {};

                        if (field.additional?.languageContext) {
                            children = rebuildLanguageContextByFormData(field, formData);

                            if (field.additional?.min1Tab && empty(children)) {
                                continue;
                            }
                        } else {
                            for (const key in formData[field.name]) {
                                const option = field?.options?.find(
                                    (option) => !option.group && (option.value === key || option.value === formData[field.name][key].key)
                                );
                                const [child] = Object.values(extend(true, {}, option?.template ?? field.template));

                                if (option?.template) {
                                    child.additional ??= {};
                                    child.additional.name = child.name;
                                    configureFields(makeCollection(child), getFormIdFromField(field));
                                }

                                if (!field?.additional?.nameIndex) {
                                    child.name = String(key);
                                }

                                children[child.name] = child;
                            }
                        }

                        field.children = children;
                    }
                }

                if (field.children && Object.keys(field.children).length && formData[field.name]) {
                    rebuildFormDefinitionByFormData(field.children, formData[field.name]);
                }
            }
        };

        iterator(fields, formData);
    };

    /**
     * Recreate structure from values decorated by languageContext decorator
     * that will be properly intercepted by @soulab/form-builder structure
     *
     * @param {import('@soulab/form-builder').Field} field
     * @param {Record<string, unknown>} formData
     */
    function rebuildLanguageContextByFormData(field, formData) {
        const children = {};

        for (const key in formData) {
            if (empty(field.template)) {
                continue;
            }

            const [template] = Object.values(field.template);
            let foundWithinLanguageContext = false;

            for (const [field] of fieldIteratorCarry(template.children)) {
                if (field.name === key) {
                    foundWithinLanguageContext = true;
                    break;
                }
            }

            if (!foundWithinLanguageContext) {
                continue;
            }

            for (const language in formData[key]) {
                let template = children[language];

                if (!template) {
                    const option = field.options.find((option) => !option.group && option.value === language);
                    [template] = Object.values(extend(true, {}, field.template));

                    template.name = language;
                    template.label = option.label;

                    // Explanation for this is in comment below
                    if (field.__path__) {
                        template.__path__ = `${field.__path__}.${template.name}`;
                    }

                    children[template.name] = template;
                }

                /**
                 * Here we build every children field.__path__ by concatenating field.name of all children in a path.
                 *
                 * We need to do this to assign field values before actually rendering form.
                 * This is because path is only known from useField hook (mounted and rendered component).
                 *
                 * Technically it would be possible to not do it here, but this would make our form render 1 time
                 * to only get field.__path__, and then 2nd time with possibly all values filled.
                 */
                const parts = [];
                for (const [field] of fieldIteratorCarryAllFields(template.children)) {
                    if (field.name === key) {
                        parts.push(key);
                        const path = parts.join('.');

                        field.value = formData[key][language];

                        if (template.__path__) {
                            field.__path__ = `${template.__path__}.${path}`;
                        }
                        break;
                    }

                    if (field.children && Object.keys(field.children).length) {
                        parts.push(field.name);
                    }
                }
            }
        }

        return children;
    }

    /**
     * Extends form structure and sets fields values, dynamic labels etc
     *
     * @param {Record<string, Field>} fields
     * @param {object} formData
     * @param {object} autocompleteOptions
     * @param {object} additionalData
     */
    const configureFormDefinitionByFormData = (fields, formData, autocompleteOptions = {}, additionalData = {}) => {
        rebuildFormDefinitionByFormData(fields, formData);
        configureFormFields(fields, formData, autocompleteOptions, additionalData);
    };

    /**
     * Collects values from given fields
     *
     * @returns {Record<string, any>}
     */
    const getValuesFromFields = () => {
        const values = {};
        const decorators = [];

        for (const [field, carry] of fieldIteratorCarry(fields, values)) {
            if ((field.visible === false || field.inactive === true) && !field.forceSendFieldValue) {
                continue;
            }

            if (field.type === enums.FIELD_TYPE_ATTRIBUTES) {
                carry[field.name] = Object.values(field.children).map((field) => field.value);
                continue;
            }

            if (!fieldAttributesTypes.includes(field.type) && field.children && Object.keys(field.children).length) {
                continue;
            }

            if (field.type === enums.FIELD_TYPE_DYNAMIC_FORM) {
                // Only for dynamic form without children, if it has children they will be recursively traversed.
                carry[field.name] = {};
            } else if (field.type === enums.FIELD_TYPE_TINYMCE) {
                carry[field.name] = field.instance?.getContent({ format: field?.additional?.format }) ?? null;
            } else {
                carry[field.name] = field.value ?? null;
            }

            if (field.callbacks?.decorate) {
                decorators.push({ carry, field, function: field.callbacks.decorate });
            }
        }

        for (const decorator of decorators) {
            const value = decorator.function(decorator.field, { form: fields, values });
            if (value) {
                decorator.carry[decorator.field.name] = value;
            }
        }

        return values;
    };

    /**
     * Metoda sprawdzająca czy przekazane pola są poprawne
     *
     * @param paths
     *
     * @returns {Promise<boolean>}
     */
    const isValidFields = async (paths) => {
        for (let index in paths) {
            const { validateField } = useField(paths[index], findField(fields, paths[index]));

            const validResult = await validateField();

            if (!validResult.valid) {
                return false;
            }
        }

        return true;
    };

    /**
     * Collect all children paths for given path with the star "*" character
     *
     * @param {string} path
     * @returns {string[]}
     */
    const getAllChildrenPathsByStar = (path) => {
        const stars = [...path.matchAll(/\*/g)];
        const collectedFieldsPath = [];

        if (!stars.length) {
            return [path];
        }

        for (const star of stars) {
            const ancestor = path.substring(0, star.index - 1);
            const parent = findField(fields, ancestor);

            for (const index of Object.keys(parent.children)) {
                const replacement = String(parent.children[index].name);
                const replaced = path.substring(0, star.index) + replacement + path.substring(star.index + 1);
                collectedFieldsPath.push(replaced);
            }
        }

        return collectedFieldsPath;
    };

    /**
     * Traverse through form fields (heading toward the form root) until field property will match given value.
     * Tuple [path, field] with the closest ancestor is returned.
     *
     * @param {string} path
     * @param {Field} field
     * @param {string} property
     * @param {any} value
     * @returns {[string, Field]}
     */
    const findParent = (path, field, property, value) => {
        while (findInObject(field, property) !== value) {
            path = path.slice(0, path.lastIndexOf(`.${field.name}`));
            field = findFieldSafe(getFormFromField(field), path);

            if (!field) {
                break;
            }
        }

        return [path, field];
    };

    const createValidationSchema = (formValues) => {
        const objectSchema = {};

        const prepareRulesForField = (field, baseSchema = null) => {
            let fieldSchema = baseSchema ?? yup.mixed().nullable();

            fieldSchema = fieldSchema.test({
                name: field.name,
                skipAbsent: false,
                test: (_value, ctx) => {
                    for (const [ruleName, ruleOptions] of Object.entries(field.rules ?? {})) {
                        if (ruleOptions === false) {
                            continue;
                        }

                        const rule = rulesMap[ruleName];

                        if (typeof rule !== 'function' || field.visible === false || field.additional?.disabled) {
                            return true;
                        }

                        const ruleContext = {
                            field,
                            name: field.name,
                            label: field.label,
                            value: field.value,
                            form: formValues,
                            rule: {
                                name: ruleName,
                                params: ruleOptions,
                            },
                        };

                        const result = rule(_value ?? field.value, ruleOptions, ruleContext);

                        if (isString(result)) {
                            return ctx.createError({ message: result });
                        }
                    }

                    return true;
                },
            });

            return fieldSchema;
        };

        const mapSchema = (field, objectSchema) => {
            if (field.type === enums.FIELD_TYPE_DYNAMIC_FORM) {
                return prepareRulesForField(
                    field,
                    yup.object(objectSchema).transform((value) => {
                        if (isArray(value)) {
                            return { ...value };
                        }

                        return value;
                    })
                );
            } else if (field.children) {
                return yup.lazy(() => {
                    if (field.visible === false) {
                        return yup.mixed().nullable();
                    }

                    return prepareRulesForField(field, yup.object(objectSchema));
                });
            }

            return objectSchema;
        };

        for (const [field, carry] of fieldIteratorCarryAllFields(fields, objectSchema, mapSchema)) {
            if (empty(field.rules) || field.inactive || !empty(field.children)) {
                continue;
            }

            carry[field.name] = prepareRulesForField(field);
        }

        return toTypedSchema(yup.object(objectSchema));
    };

    const makeReadonly = (readonly = true) => {
        const carry = {};

        for (const [field] of fieldIteratorCarryAllFields(fields, carry)) {
            if (field.additional?.staticReadonly) {
                continue;
            }

            field.__readonly__ ??= {};
            (field.additional ??= {}).readonly = readonly;

            if (typeof field.additional.deletable !== 'undefined') {
                field.additional.deletable = !readonly;
            }
            if (typeof field.additional.draggable !== 'undefined') {
                field.additional.draggable = !readonly;
            }

            if (typeof field.rules?.required !== 'undefined') {
                if (!field.__readonly__.required || readonly) {
                    field.__readonly__.required = field.rules.required;
                }
                field.rules.required = readonly ? false : field.__readonly__.required;
            }

            if (field.type === enums.FIELD_TYPE_DYNAMIC_FORM) {
                if (typeof field.additional.extendable !== 'undefined') {
                    if (!field.__readonly__.extendable || readonly) {
                        field.__readonly__.extendable = field.additional.extendable;
                    }
                    field.additional.extendable = readonly ? false : field.__readonly__.extendable;
                } else {
                    field.additional.showGhostRow = !readonly;
                }
            }

            if (field.type === enums.FIELD_TYPE_TABS) {
                if (typeof field.additional.addNewTabs !== 'undefined') {
                    field.additional.addNewTabs = !readonly;
                }
            }
        }
    };

    return {
        scrollToError,
        findErrorElement,
        findErrorInFields,
        applyBackendErrorsToFields,
        configureFormDefinitionByFormData,
        configureFormFields,
        getValuesFromFields,
        getAllChildrenPathsByStar,
        findParent,
        isValidFields,
        createValidationSchema,
        makeReadonly,
        scrollToFormBuilderField,
    };
}
