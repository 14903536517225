import MainLayout from 'layouts/MainLayout.vue';

export default [
    {
        path: 'work-demand/:id',
        name: 'work-demand-department',
        component: () => import('pages/contractors/WorkDemandPage.vue'),
        meta: {
            layout: MainLayout,
        },
    },
    {
        path: '',
        component: () => import('../../pages/HrappkaOldView.vue'),
        props: { hideHeader: true, hideLeftMenu: true },
    },
];
