import { boot } from 'quasar/wrappers';
import { empty, isArray } from '@soulab/js-helpers';
import { usePermissionsStore } from '@soulab/vue-permissions';
import { useAuthStore } from 'stores/auth';
import { permissionAttribute, permissionParentSubject, permissionSubject } from 'src/enums/permissions';
import { role } from 'src/enums/role';

export function addPermissionsFromStorage(permissions) {
    const permissionsStore = usePermissionsStore();

    for (const [subject, attributes] of Object.entries(permissions)) {
        if (isArray(attributes)) {
            for (const attribute of attributes) {
                permissionsStore.addPermission(attribute, subject, permissionsStore.getRole);
            }
        } else {
            for (const [subSubject, subAttributes] of Object.entries(attributes)) {
                for (const attribute of subAttributes) {
                    permissionsStore.addPermission(
                        attribute,
                        `${permissionParentSubject[subject]}_${subSubject}`,
                        permissionsStore.getRole
                    );
                }
            }
        }
    }
}

/**
 * @param {{role: string|undefined, permissions: Object|undefined, companySettings: Object|undefined}} initData
 */
export function initPermissions(initData = {}) {
    const authStore = useAuthStore();
    const permissionsStore = usePermissionsStore();

    permissionsStore.$reset();

    const company = authStore.getCurrentCompany;
    const companySettings = company?.settings?.jsonSettings ?? {};

    permissionsStore.setRole(initData.role ?? authStore.getCurrentRole?.role);
    permissionsStore.mountAdditionalData('authStore', authStore);
    permissionsStore.mountAdditionalData('companySettings', initData.companySettings ?? companySettings);

    /**
     * COMPANY_USER_ROLE
     */
    permissionsStore.addPermission(permissionAttribute.CHANGE_COMPANY, permissionSubject.COMPANY_USER_ROLE, role.ADMIN);
    permissionsStore.addPermission(permissionAttribute.CHANGE_COMPANY, permissionSubject.COMPANY_USER_ROLE, role.USER);
    // TODO: Allow these roles to change company only if original role is ADMIN or USER
    permissionsStore.addPermission(permissionAttribute.CHANGE_COMPANY, permissionSubject.COMPANY_USER_ROLE, role.WORKER);
    permissionsStore.addPermission(permissionAttribute.CHANGE_COMPANY, permissionSubject.COMPANY_USER_ROLE, role.CLIENT);
    permissionsStore.addPermission(permissionAttribute.CHANGE_COMPANY, permissionSubject.COMPANY_USER_ROLE, role.RECRUITMENT_PARTNER);
    permissionsStore.addPermission(permissionAttribute.CHANGE_COMPANY, permissionSubject.COMPANY_USER_ROLE, role.BUSINESS_PARTNER);

    permissionsStore.addPermission(permissionAttribute.CHANGE_ROLE, permissionSubject.COMPANY_USER_ROLE, role.ADMIN);
    permissionsStore.addPermission(permissionAttribute.CHANGE_ROLE, permissionSubject.COMPANY_USER_ROLE, role.USER);
    // TODO: Allow these roles to change role only if original role is ADMIN or USER
    permissionsStore.addPermission(permissionAttribute.CHANGE_ROLE, permissionSubject.COMPANY_USER_ROLE, role.WORKER);
    permissionsStore.addPermission(permissionAttribute.CHANGE_ROLE, permissionSubject.COMPANY_USER_ROLE, role.CLIENT);
    permissionsStore.addPermission(permissionAttribute.CHANGE_ROLE, permissionSubject.COMPANY_USER_ROLE, role.RECRUITMENT_PARTNER);
    permissionsStore.addPermission(permissionAttribute.CHANGE_ROLE, permissionSubject.COMPANY_USER_ROLE, role.BUSINESS_PARTNER);

    /**
     * EMPLOYEE_COMPETENCE_WORK_HOURS
     */
    permissionsStore.addPermission(permissionAttribute.EDIT, permissionSubject.EMPLOYEE_COMPETENCE_WORK_HOURS, role.ADMIN);
    permissionsStore.addPermission(permissionAttribute.EDIT, permissionSubject.EMPLOYEE_COMPETENCE_WORK_HOURS, role.USER, (data) => {
        return !data.authStore.getCurrentCompany?.settings?.jsonSettings?.cs_require_employee_position_tags_for_rate_calendar_events;
    });

    /**
     * TRAINING_SUGGESTION
     */
    permissionsStore.addPermission(permissionAttribute.CREATE, permissionSubject.TRAINING_SUGGESTION, role.WORKER, (data) => {
        return (
            data.authStore.getCurrentCompany?.settings?.jsonSettings?.TRAINING_ALLOW_REPORTING &&
            (data.authStore.isSupervisor ||
                data.authStore.getCurrentCompany?.settings?.jsonSettings?.TRAINING_REPORTERS?.ROLE?.includes(role.WORKER))
        );
    });

    if (!empty(authStore.getPermissions)) {
        addPermissionsFromStorage(authStore.getPermissions);
    }

    if (!empty(initData.permissions)) {
        addPermissionsFromStorage(initData.permissions);
    }
}

export default boot(() => {
    initPermissions();
});
