import { permissionsMiddleware } from 'src/router/middlewares/permissions-middleware';
import { permissionAttribute, permissionSubject } from 'src/enums/permissions';
import { defineAsyncComponent } from 'vue';

export default [
    {
        path: 'login',
        name: 'login',
        component: () => import('../../pages/auth/LoginPage.vue'),
        meta: {
            title: 'Zaloguj się',
            layout: defineAsyncComponent(() => import('layouts/AuthLayout.vue')),
        },
    },
    {
        path: 'register',
        name: 'register',
        component: () => import('../../pages/auth/RegisterPage.vue'),
        meta: {
            title: 'Zarejestruj się',
            layout: defineAsyncComponent(() => import('layouts/AuthLayout.vue')),
        },
    },
    {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('../../pages/auth/ForgotPasswordPage.vue'),
        meta: {
            title: 'Resetuj hasło',
            layout: defineAsyncComponent(() => import('layouts/AuthLayout.vue')),
        },
    },
    {
        path: 'set-password/:token?/:rodo?',
        name: 'set-password',
        component: () => import('../../pages/auth/SetPasswordPage.vue'),
        meta: {
            title: 'Ustaw hasło',
            layout: defineAsyncComponent(() => import('layouts/AuthLayout.vue')),
        },
    },
    {
        path: 'request-presentation',
        name: 'request-presentation',
        component: () => import('../../pages/auth/RequestPresentationPage.vue'),
        meta: {
            title: 'Zamów prezentację',
            layout: defineAsyncComponent(() => import('layouts/AuthLayout.vue')),
        },
    },
    {
        path: 'unlock-account/:token?',
        name: 'unlock-account',
        component: () => import('../../pages/auth/UnlockAccountPage.vue'),
        meta: {
            title: 'Odblokuj konto',
            layout: defineAsyncComponent(() => import('layouts/AuthLayout.vue')),
        },
    },
    {
        path: 'change-company/:cmpId',
        name: 'change-company',
        component: () => import('../../pages/auth/ChangeCompanyPage.vue'),
        meta: {
            title: 'Zmień firmę',
            layout: defineAsyncComponent(() => import('layouts/AuthLayout.vue')),
            middleware: [permissionsMiddleware(permissionAttribute.CHANGE_COMPANY, permissionSubject.COMPANY_USER_ROLE)],
        },
    },
    {
        path: 'change-user-role/:userRole?/:companyId?',
        name: 'change-user-role',
        component: () => import('../../pages/auth/ChangeUserRolePage.vue'),
        meta: {
            title: 'Zmień rolę',
            layout: defineAsyncComponent(() => import('layouts/AuthLayout.vue')),
            middleware: [permissionsMiddleware(permissionAttribute.CHANGE_ROLE, permissionSubject.COMPANY_USER_ROLE)],
        },
    },
    {
        path: 'login-by-token/:token/',
        name: 'login-by-token',
        component: () => import('../../pages/auth/LoginByTokenPage.vue'),
    },
    {
        path: 'login-by-token/:token/',
        name: 'login-by-token',
        component: () => import('../../pages/auth/LoginByTokenPage.vue'),
    },
    {
        path: 'login-by-token/:token/',
        name: 'login-by-token',
        component: () => import('../../pages/auth/LoginByTokenPage.vue'),
    },
    {
        path: 'logout',
        name: 'logout',
        component: () => import('../../pages/auth/LogoutPage.vue'),
        meta: {
            title: 'Wyloguj',
            layout: defineAsyncComponent(() => import('layouts/AuthLayout.vue')),
        },
    },
];
