import { empty } from '@soulab/js-helpers';

function checkIsEmailValid(emailStr) {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(emailStr)) {
        return false;
    }
    return true;
}

/**
 * Validator to check for email regex match for given field value
 */
export function emailRule(value, validator, context) {
    if (empty(value, false)) {
        return true;
    }

    if (Array.isArray(value)) {
        for (let item of value) {
            if (typeof item === 'string') {
                let isValid = checkIsEmailValid(item);
                if (!isValid) return context.rule.params?.message || 'Wprowadzono nieprawidłowy adres email';
            }
        }
    } else {
        if (typeof value === 'string') {
            let isValid = checkIsEmailValid(value);
            if (!isValid) return context.rule.params?.message || 'Wprowadzono nieprawidłowy adres email';
        }
    }

    return true;
}
