import { useAuthStore } from 'stores/auth';
import { itemTypeMap } from 'src/enums/item';
import { i18n } from 'boot/i18n';
import { findField } from '@soulab/form-builder';
import { FieldNotFoundError } from '@soulab/form-builder/src/errors/field-not-found-error';

const $t = i18n.global.t;

/**
 * @param {Field} field
 * @param {string} idName
 * @returns {object|undefined}
 */
export function getSelectedOption(field, idName = 'id') {
    return field.options?.find((option) => option[idName] === field.value);
}

/**
 * @param {Field} field
 * @returns {any[]}
 */
export function getSelectableOptions(field) {
    return field.options.filter((option) => !option.group && option.value);
}

/**
 * @param {Field} field
 * @param {string} idName
 * @returns {boolean}
 */
export function isSelectedValueAnOption(field, idName = 'id') {
    return !!getSelectedOption(field, idName);
}

/**
 * @param {Field} field
 * @param {string} path
 * @returns {string}
 */
export function getParentPath(field, path) {
    return path.substring(0, path.lastIndexOf(`.${field.name}`));
}

/**
 * @param {Record<string, Field>} fields
 * @param {string} path
 * @returns {Field|null}
 */
export function findFieldSafe(fields, path) {
    let field = null;

    try {
        field = findField(fields, path);
    } catch (err) {
        if (!(err instanceof FieldNotFoundError)) {
            throw err;
        }
    }

    return field;
}

export function getItemTypeOptions() {
    const itemTypes = useAuthStore().itemTypes;

    return Object.keys(itemTypes).map((itemType) => {
        return {
            label: Object.prototype.hasOwnProperty.call(itemTypeMap, itemType) ? $t(itemTypeMap[itemType]) : itemType,
            value: itemType,
        };
    });
}
