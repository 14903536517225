import { extend, Notify, QNotifyAction, QNotifyCreateOptions } from 'quasar';
import { AlertType } from 'src/enums/alert';
import { isObject } from '@soulab/js-helpers';
import { i18n } from 'boot/i18n';
import { renderComponentToString } from 'src/helpers/render';
import { Component } from 'vue';

const $t = i18n.global.t;

const getAlertColorForType = (type: AlertType) => {
    switch (type) {
        case AlertType.DEFAULT:
        case AlertType.INFO:
        case AlertType.SUCCESS:
        case AlertType.DANGER:
        case AlertType.WARNING:
            return 'white';
    }
};

const getAlertIconForType = (type: AlertType) => {
    switch (type) {
        case AlertType.DEFAULT:
        case AlertType.INFO:
            return 'info';
        case AlertType.SUCCESS:
            return 'check_circle';
        case AlertType.WARNING:
        case AlertType.DANGER:
            return 'report_problem';
    }
};

export interface AlertComponent {
    component: Component;
    props: Record<string, unknown>;
}

export interface AlertOptions {
    actions: QNotifyAction[];
    badgeStyle: string;
    position: NonNullable<QNotifyCreateOptions['position']>;
    timeout: number;
    html: boolean;
    spinner: boolean;
}

const defaultAlertOptions: AlertOptions = {
    actions: [
        {
            label: $t('Zamknij'),
            color: 'white',
        },
    ],
    badgeStyle: '',
    position: 'top-right',
    timeout: 5000,
    html: false,
    spinner: false,
};

/**
 * TODO: HR-8107 refactor, replace optional positional arguments with named arguments
 */
export function showAlert(type: AlertType, componentOrMessage: AlertComponent | string, options?: Partial<AlertOptions>): Promise<void> {
    const optionsWithDefaults = extend<AlertOptions>(true, {}, defaultAlertOptions, options);

    const messagePromise = isObject(componentOrMessage)
        ? renderComponentToString(componentOrMessage.component, componentOrMessage.props)
        : Promise.resolve(componentOrMessage);

    return messagePromise.then((message) => {
        Notify.create({
            type: type,
            message: message,
            textColor: getAlertColorForType(type),
            icon: getAlertIconForType(type),
            iconColor: getAlertColorForType(type),
            position: optionsWithDefaults.position,
            multiLine: true,
            actions: optionsWithDefaults.actions,
            badgeStyle: optionsWithDefaults.badgeStyle,
            html: optionsWithDefaults.html,
            timeout: optionsWithDefaults.timeout,
            spinner: optionsWithDefaults.spinner,
        });
    });
}
