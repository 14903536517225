import { empty } from '@soulab/js-helpers';

export function passwordRule(value, validator, context) {
    if (empty(value, false)) {
        return context.rule.params?.message || 'Hasło musi zawierać przynajmniej 8 znaków';
    }

    /**
     * Min 8 characters
     */
    if (value.length < 8) {
        return context.rule.params?.lengthMessage || 'Hasło musi zawierać przynajmniej 8 znaków';
    }

    /**
     * Required any number
     */
    if (!/[0-9]/.test(value)) {
        return context.rule.params?.numberMessage || 'Hasło musi zawierać przynajmniej jedną liczbę';
    }

    /**
     * Required any small letter
     */
    if (!/[a-z._%+-]/.test(value)) {
        return context.rule.params?.smallLetterMessage || 'Hasło musi zawierać przynajmniej jedną małą literę';
    }

    /**
     * Required any capital letter
     */
    if (!/[A-Z._%+-]/.test(value)) {
        return context.rule.params?.capitalLetterMessage || 'Hasło musi zawierać przynajmniej jedną wielką literę';
    }

    return true;
}
