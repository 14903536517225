import { boot } from 'quasar/wrappers';
import axios from 'axios';
import { useAuthStore } from 'stores/auth';
import { isAccessDenied, isUnauthorized } from 'src/hooks/axiosResponseHook';
import { i18n } from 'boot/i18n';
import { redirectToLogoutPage } from 'src/helpers/url';
import { useModalStore } from 'stores/modal';
import { showAlert } from 'src/hooks/showAlert';
import { AlertType } from 'src/enums/alert';

const api = axios.create({ baseURL: '/api/v1' });
const apiWithoutAuth = axios.create({ baseURL: '/api/v1' });
const oldHrappka = axios.create({ baseURL: '/first' });

/**
 * Tablica z wykluczonymi adresami do odświeżenia tokenu przy 401
 */
export const excludedRefreshTokenRoutes = ['/auth/login', '/auth/logout', '/auth/refresh'];

/**
 * Tablica z adresami które nie są brane pod uwagę do przekierowania przy 403
 */
const excludedAccessDeniedRoutes = ['/auth/logout', '/auth/refresh', '/auth/post-login', '/auth/pre-login'];

/**
 * Object with requests, that are currently being processed
 */
const requests = {};

/**
 * @param {import('axios').AxiosRequestConfig} config
 * @return string
 */
function createRequestKey(config) {
    return config.method + config.url;
}

/**
 * Interceptor used to add request to requests object and possibly cancel duplicate request
 */
api.interceptors.request.use(
    (config) => {
        const key = createRequestKey(config);

        /**
         * Ability to parameterize if duplicate requests should be cancelled
         */
        if (config.cancelable ?? true) {
            if (requests[key]) {
                requests[key].cancel();
            }

            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();
            config.cancelToken = source.token;
            requests[key] = source;
        }

        if (!requests[key]) {
            requests[key] = {};
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * Interceptor used to successfully remove request from requests object
 */
api.interceptors.response.use(
    (response) => {
        const key = createRequestKey(response.config);

        if (requests[key]) {
            delete requests[key];
        }

        return response;
    },
    (error) => {
        if (axios.isCancel(error)) {
            return new Promise(() => {});
        }

        if (error.config) {
            const key = createRequestKey(error.config);

            if (requests[key]) {
                delete requests[key];
            }
        }

        return Promise.reject(error);
    }
);

/**
 * Interceptor used to display response errors from Zend in new application
 */
oldHrappka.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return new Promise(() => {
            /**
             * Frontowe przekierowanie na /auth/login obsłużone jest w OldHrappkaView
             */

            throw error;
        });
    }
);

export default boot(({ app, router }) => {
    app.config.globalProperties.$axios = axios;
    app.config.globalProperties.$api = api;
    app.config.globalProperties.$apiWithoutAuth = apiWithoutAuth;
    app.config.globalProperties.$oldHrappka = oldHrappka;

    /**
     * Interceptor used for authorization on response
     */
    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const allowUnauthorized = error?.config?.allowUnauthorized ?? false;
            const allowAccessDenied = error?.config?.allowAccessDenied ?? false;

            /**
             * Brak autoryzacji
             */
            if (!allowUnauthorized && error.response && isUnauthorized(error.response)) {
                const auth = useAuthStore();
                const originalConfig = error.config;

                if (excludedRefreshTokenRoutes.includes(originalConfig.url)) {
                    return Promise.reject(error);
                }

                /**
                 * Sprawdzamy czy istnieje refreshToken, jeżeli istnieje próbujemy odświezyć token
                 */
                if (auth.getRefreshToken && !originalConfig._retry) {
                    /**
                     * Oznaczenie ponownego przesłania żądania
                     */
                    originalConfig._retry = true;
                    let error = false;

                    const refreshResult = await auth
                        .callRefreshToken()
                        .then((result) => {
                            originalConfig.headers['Authorization'] = `Bearer ${auth.token}`;

                            return result;
                        })
                        .catch(() => {
                            auth.clearUserData();
                            error = true;

                            return false;
                        });

                    if (!refreshResult) {
                        await redirectToLogoutPage(router);

                        return Promise.reject(error);
                    }

                    return api(originalConfig);
                } else {
                    /**
                     * Jeżeli user jest zalogowany to go wylogowywujemy
                     */
                    if (auth.getToken) {
                        await redirectToLogoutPage(router);
                    }

                    return Promise.reject(error);
                }
            } else if (!allowAccessDenied && error.response && isAccessDenied(error.response)) {
                const originalConfig = error.config;
                if (!excludedAccessDeniedRoutes.includes(originalConfig.url)) {
                    const { getModals, closeLastModal } = useModalStore();

                    if (Object.keys(getModals).length) {
                        showAlert(AlertType.DANGER, i18n.global.t('Brak dostępu'));
                        closeLastModal();
                    } else {
                        /**
                         * TODO jak będziemy mieli historię dla breacrumbs to trzeba będzie zrobić redirect na ostatni element,
                         * a jeżeli się nie uda to wtedy na home
                         * HR-4762
                         */
                        router.push({
                            path: '/',
                            query: {
                                showDangerAlert: encodeURIComponent(i18n.global.t('Brak dostępu')),
                            },
                        });
                    }

                    return Promise.reject(error);
                } else {
                    return Promise.reject(error);
                }
            } else {
                return Promise.reject(error);
            }
        }
    );
});

export { axios, api, apiWithoutAuth, oldHrappka };
