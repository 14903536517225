const permissionAttribute = Object.freeze({
    /**
     * Atrybuty dostępne tylko na frontendzie
     */
    CHANGE_COMPANY: 'CHANGE_COMPANY', // Zmiana firmy
    CHANGE_ROLE: 'CHANGE_ROLE', // Zmiana roli

    /**
     * Atrybuty dostępne zarówno na frontendzie jak i na backendzie
     */
    ACCEPT_PLAN: 'PERM_ACCEPT_PLAN', // Zatwierdzanie planu
    ADD_ABSENCE: 'PERM_ADD_ABSENCE', // Dodawania nieobecności
    ADD_BONUS: 'PERM_ADD_BONUS', // Dodawanie premii
    ADD_DEMAND: 'PERM_ADD_DEMAND', // Dodawania zapotrzebowania
    ADD_PLAN: 'PERM_ADD_PLAN', // Dodawanie planu
    ADD_WORK_HOURS: 'PERM_ADD_WORK_HOURS', // Dodawanie godzin pracy
    ANNEX: 'PERM_ANNEX', // Aneks
    APPLICATION_CREATE: 'PERM_APPLICATION_CREATE', // Dodawanie kandydata
    APPLICATION_EDIT: 'PERM_APPLICATION_EDIT', // Edycja aplikacji
    APPLICATION_DELETE: 'PERM_APPLICATION_DELETE', // Usuwanie aplikacji
    APPLICATION_VIEW: 'PERM_APPLICATION_VIEW', // Podgląda aplikacji
    CANCEL: 'PERM_CANCEL', // Rozwiązywanie
    CHARGES_EDIT: 'PERM_CHARGES_EDIT', // Modyfikacja opłat
    CHARGES_VIEW: 'PERM_CHARGES_VIEW', // Podgląd opłat
    COMPANY_POSITION_CREATE: 'PERM_COMPANY_POSITION_CREATE', // Tworzenie firmowego stanowiska
    COMPANY_POSITION_DELETE: 'PERM_COMPANY_POSITION_DELETE', // Usuwanie firmowego stanowiska
    COMPANY_POSITION_EDIT: 'PERM_COMPANY_POSITION_EDIT', // Edycja firmowego stanowiska
    COMPANY_POSITION_VIEW: 'PERM_COMPANY_POSITION_VIEW', // Podgląd firmowego stanowiska
    CONTRACTOR_POSITION_CREATE: 'PERM_CONTRACTOR_POSITION_CREATE', // Tworzenie stanowiska klienta
    CONTRACTOR_POSITION_DELETE: 'PERM_CONTRACTOR_POSITION_DELETE', // Usuwanie stanowiska klienta
    CONTRACTOR_POSITION_EDIT: 'PERM_CONTRACTOR_POSITION_EDIT', // Edycja stanowiska klienta
    CONTRACTOR_POSITION_VIEW: 'PERM_CONTRACTOR_POSITION_VIEW', // Podgląd stanowiska klienta
    CONTRACTOR_CONTRACT_POSITION_CREATE: 'PERM_CONTRACTOR_CONTRACT_POSITION_CREATE', // Tworzenie stanowiska na umowie z klientem
    CONTRACTOR_CONTRACT_POSITION_DELETE: 'PERM_CONTRACTOR_CONTRACT_POSITION_DELETE', // Usuwanie stanowiska na umowie z klientem
    CONTRACTOR_CONTRACT_POSITION_EDIT: 'PERM_CONTRACTOR_CONTRACT_POSITION_EDIT', // Edycja stanowiska na umowie z klientem
    CONTRACTOR_CONTRACT_POSITION_VIEW: 'PERM_CONTRACTOR_CONTRACT_POSITION_VIEW', // Podgląd stanowiska na umowie z klientem
    COUNTER_CREATE: 'PERM_COUNTER_CREATE', // Dodawanie stanu licznika
    COUNTER_EDIT: 'PERM_COUNTER_EDIT', // Edycja stanu licznika
    COUNTER_VIEW: 'PERM_COUNTER_VIEW', // Podgląd stanu licznika
    CREATE_ACCOUNT: 'PERM_CREATE_ACCOUNT', // Tworzenie konta
    CREATE_PAST: 'PERM_CREATE_PAST', // Dodawanie z datą wsteczną
    CREATE: 'PERM_CREATE', // Dodawanie
    IMPORT: 'PERM_IMPORT', // Importowanie
    CREATE_RAW: 'PERM_CREATE_RAW', // Dodawanie
    CREATE_OWN_REPORT: 'PERM_CREATE_OWN_REPORT', // Dodawanie własnych zgłoszeń grafikowych
    CREATE_USER_JOIN: 'PERM_CREATE_USER_JOIN', // Powiązanie z użytkownikiem
    DELETE: 'PERM_DELETE', // Usuwanie
    DELETE_RAW: 'PERM_DELETE_RAW', // Usuwanie
    DELETE_USER_JOIN: 'PERM_DELETE_USER_JOIN', // Usuwanie powiązania z użytkownikiem
    DOWNLOAD: 'PERM_DOWNLOAD', // Pobieranie
    DOWNLOAD_MASS: 'PERM_DOWNLOAD_MASS', // Masowe pobieranie
    EDIT_ACCEPTED_STEPS: 'PERM_EDIT_ACCEPTED_STEPS', // Edycja zaakceptowanych kroków
    EDIT_PAST: 'PERM_EDIT_PAST', // Edytowanie z datą wsteczną
    EDIT: 'PERM_EDIT', // Edycja
    EDIT_RAW: 'PERM_EDIT_RAW', // Edycja
    MARK_TASK_AS_DONE: 'PERM_MARK_TASK_AS_DONE', // Zamykanie zadań
    MERGE: 'PERM_MERGE', // Scalanie
    PARTICIPANT_MANAGEMENT: 'PERM_PARTICIPANT_MANAGEMENT', // Zarządzanie uczestnikami
    PERSONAL_DATA: 'PERM_PERSONAL_DATA', // Dane osobowe
    POSITION_CREATE: 'PERM_POSITION_CREATE', // Dodawanie stanowiska
    POSITION_EDIT: 'PERM_POSITION_EDIT', // Edycja stanowiska
    POSITION_VIEW: 'PERM_POSITION_VIEW', // Podgląd stanowiska
    PROJECT_POSITION_CREATE: 'PERM_PROJECT_POSITION_CREATE', // Tworzenie stanowiska na projekcie
    PROJECT_POSITION_DELETE: 'PERM_PROJECT_POSITION_DELETE', // Usuwanie stanowiska na projekcie
    PROJECT_POSITION_EDIT: 'PERM_PROJECT_POSITION_EDIT', // Edycja stanowiska na projekcie
    PROJECT_POSITION_VIEW: 'PERM_PROJECT_POSITION_VIEW', // Podgląd stanowiska na projekcie
    RATE: 'PERM_RATE', // Stawka
    RATE_EDIT: 'PERM_RATE_EDIT', // Edycja stawki
    RATE_VIEW: 'PERM_RATE_VIEW', // Podgląd stawki
    SCHEDULE_MANAGEMENT: 'PERM_SCHEDULE_MANAGEMENT', // Zarządzanie wpisami grafikowymi
    SEND_EMAIL: 'PERM_SEND_EMAIL', // Wysyłka maili
    SEND_SMS: 'PERM_SEND_SMS', // Wysyłka SMS
    SHARE_PROFILE: 'PERM_SHARE_PROFILE', // Współdzielenie profili
    VIEW: 'PERM_VIEW', // Podgląd
    VIEW_RAW: 'PERM_VIEW_RAW', // Podgląd
    VIEW_AT_EMPLOYEES_LIST: 'PERM_VIEW_AT_EMPLOYEES_LIST', // Used when contract switch for client is on. This is the only operation allowed for Client when access is set to management.
});

const permissionSubject = Object.freeze({
    COMPANY_USER_ROLE: 'CHANGE_USER_ROLE', // Funkcjonalności związane ze zmianą firmy i roli obecnego użytkownika
    EMPLOYEE_COMPETENCE_WORK_HOURS: 'EMPLOYEE_COMPETENCE_WORK_HOURS', // Sprawdzenie kompetencji pracowników podczas dodawania godzin pracy
    TRAINING_SUGGESTION: 'TRAINING_SUGGESTION', // Zgłaszanie propozycji szkoleń
});

export const permissionParentSubject = Object.freeze({
    USER_PERMIT_BY_TYPES: 'USER_PERMIT',
});

export { permissionAttribute, permissionSubject };
