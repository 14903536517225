import { defineStore } from 'pinia';

export const useViewStore = defineStore('view', {
    state: () => ({
        testEnvironment: false,
        brandingLoaded: false,
        scrolledToTop: true,
    }),

    getters: {
        isTestEnvironment: (state) => state.testEnvironment,
        isBrandingLoaded: (state) => state.brandingLoaded,
        isScrolledToTop: (state) => state.scrolledToTop,
    },

    actions: {
        setTestEnvironment(value) {
            this.testEnvironment = value;
        },
        setBrandingLoaded(value) {
            this.brandingLoaded = value;
        },
        setScrolledToTop(value) {
            this.scrolledToTop = value;
        }
    },
});
