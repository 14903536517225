import { empty } from '@soulab/js-helpers';

export function phoneRule(value, validator, context) {
    if (empty(value, false)) {
        return true;
    }

    if (!/^(\+\d{1,3}\s?)?[0-9]{3}[- ]?[0-9]{3}[- ]?[0-9]{3}$/.test(value)) {
        return (
            context.rule.params?.message ||
            'Niepoprawny numer telefonu. Upewnij się, że wpisałeś odpowiedni format numeru telefonu wraz z numerem kierunkowym kraju, np. +48 XXX XXX XXX'
        );
    }

    return true;
}
