import { empty } from '@soulab/js-helpers';

export function lengthRule(value, validator, context) {
    if (empty(value, false)) {
        return true;
    }

    if (!empty(validator.min) && value.length < validator.min) {
        return context.rule.params?.minMessage || `Minimalna długość pola to ${validator.min} znaków`;
    }

    if (!empty(validator.max) && value.length > validator.max) {
        return context.rule.params?.maxMessage || `Maksymalna długość pola to ${validator.max} znaków`;
    }

    return true;
}
