export const contractorType = Object.freeze({
    PERSONAL: 'PERSONAL',
    CLIENT: 'CLIENT',
    BAILIFF: 'BAILIFF',
    ALIMONY: 'ALIMONY',
    OTHER: 'OTHER',
    SUPPLIER: 'SUPPLIER',
    ACCOMMODATION: 'ACCOMMODATION',
    PARTNER: 'PARTNER',
    RESELLER: 'RESELLER',
    PROSPECT: 'PROSPECT',
    TRANSPORT: 'TRANSPORT',
    TRAINING: 'CLIENT_TRAINING',
});

export const contractorTypeMap = Object.freeze({
    [contractorType.PERSONAL]: 'Dział',
    [contractorType.CLIENT]: 'Klient',
    [contractorType.SUPPLIER]: 'Dostawca',
    [contractorType.ACCOMMODATION]: 'Nocleg',
    [contractorType.BAILIFF]: 'Komornik',
    [contractorType.ALIMONY]: 'Alimenty',
    [contractorType.PARTNER]: 'Partner',
    [contractorType.RESELLER]: 'Reseller',
    [contractorType.PROSPECT]: 'Prospekt',
    [contractorType.TRANSPORT]: 'Transport',
    [contractorType.TRAINING]: 'Dostawca szkolenia',
    [contractorType.OTHER]: 'Inny',
});

export const contractorUserRoleType = Object.freeze({
    CONTRACT: 'CONTRACT',
    REQUEST: 'REQUEST',
    APPLICATION: 'APPLICATION',
    FORM: 'FORM',
    SEISURE: 'SEISURE',
    CALENDAR_EVENT: 'CALENDAR_EVENT',
});

export const nightHoursBonusType = Object.freeze({
    PERCENT_OF_MIN: 'PERCENT_OF_MIN',
    PERCENT_OF_BASE: 'PERCENT_OF_BASE',
    CONST_AMOUNT: 'CONST_AMOUNT',
});

export const clientCostName = Object.freeze({
    QUANTITY: 'cc_client_cost_quantity',
    MARGIN_BRUTTO: 'cc_client_cost_margin_brutto',
    MARGIN_BRUTTO_BRUTTO: 'cc_client_cost_margin_brutto_brutto',
    BRUTTO_CALCULATE: 'cc_standard_cost_brutto_calculate',
    TEN_CALCULATE: 'cc_standard_cost_ten_calculate',
    PFRON: 'cc_client_cost_pfron',
    PPK: 'cc_client_cost_ppk',

    OVERTIME_CALCULATE: 'cc_overtime_calculate',
    NIGHTSHIFT_CALCULATE: 'cc_nightshift_calculate',
    NIGHTTIME_CALCULATE: 'cc_nighttime_calculate',
    SICK_CALCULATE: 'cc_sick_calculate',
    VACATION_CALCULATE: 'cc_vacation_calculate',
    VACATION_EQUIVALENT_CALCULATE: 'cc_vacation_equiwalent_calculate',
    PREMIUM_CALCULATE: 'cc_premium_calculate',
    PREMIUM_DISC_CALCULATE: 'cc_premium_disc_calculate',
    PREMIUM_OTHER_CALCULATE: 'cc_premium_other_calculate',
    MIN_WAGE_COMPENSATION_CALCULATE: 'cc_min_wage_compensation_calculate',

    MARGIN_ADDITIONAL_SERVICES: 'cc_client_cost_margin_additional_services',
    BHP: 'cc_client_cost_bhp',
    CLOTH: 'cc_client_cost_cloth',
    SHOES: 'cc_client_cost_shoes',
    MEDICAL_EXAMINATION: 'MEDICAL_EXAMINATION',
    ACCOMMODATION: 'ACCOMMODATION',
    PROVIDING_FOOD: 'CODENAME_PROVIDING_FOOD',
    PROVIDING_COMMUTING: 'CODENAME_PROVIDING_COMMUTING',
    LEGALIZATION: 'CODENAME_LEGALIZATION',
    SANEPID_CARD: 'CODENAME_SANEPID_CARD',

    RECRUITMENT: 'cc_recriutment_cost',
    GUARANTY: 'cc_guaranty_cost',
    ADMINISTRATION: 'cc_administration_cost',
    MONTH: 'cc_client_month_cost',

    CUSTOM_BILLING_SCHEME: 'cc_custom_billing_scheme_code',
});
